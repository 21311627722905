import React from "react";

export default function ToplamTeskilatci({
  uretim,
  toplamTeskilatci,
  gecenHafta,
}) {
  return (
    <>
      <div className={`md:ml-32 flex-column  md:w-30 mt-10 md:mt-0`}>
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full h-20 border border-black"
            readyonly
            value="Üretim"
            disabled
          />
        </div>
        <div className="flex-1">
          {/* Üretim  */}
          <input
            type="number"
            className={
              "text-center  px-1 form-control w-full h-14 border border-black"
            }
            placeholder={uretim}
            disabled
          />
        </div>
      </div>

      <div className="flex-column w-30">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full h-20 border border-black"
            readyonly
            value="Toplam Teşkilatçı"
            disabled
          />
        </div>
        <div className="flex-1">
          {/* Toplam Teşkilatçı  */}
          <input
            type="number"
            className="text-center  px-1 form-control w-full h-14 border border-black"
            placeholder={toplamTeskilatci}
            disabled
          />
        </div>
      </div>

      <div className="flex-column w-30">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full h-20 border border-black"
            readyonly
            value="Geçen Hafta"
            disabled
          />
        </div>
        <div className="flex-1">
          {/* Geçen Hafta  */}
          <input
            type="number"
            className="text-center  px-1 form-control w-full h-14 border border-black"
            placeholder={gecenHafta}
            disabled
          />
        </div>
      </div>
    </>
  );
}
