import React, { useState, useEffect } from 'react'
import { createPdf } from "../utils/modifyPdf";
import { yaziliAyGuneCevir } from "../utils/functions"

export default function  BolgeRaporGecenGoruntule({ rapor, modal, setModal }) {
    const [base64Str, setBase64Str] = useState(null);
    const pdfSource = `data:application/pdf;base64,${base64Str}`;
    useEffect(() => {

        modify();
    }, [modal])


    const modify = async () => {
        
        await createPdf(rapor?.gecenHaftaRapor?.createdAt ?? new Date(), rapor?.kunye?.sehir, rapor?.gecenHaftaRapor, 0, rapor?.gecenHaftaOnceTeskilatci,rapor?.gecenHaftaTeskilatci)
            .then((res) => {
                setBase64Str(res)
            })
            .catch(err => console.log(err));
    };
    const modalClose = () => {
        setModal(false);
    }

    return (
        <>
            <div id="defaultModal" tabindex="-1" aria-hidden="true" class={`${modal ? "" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`}>
                <div class="relative p-4 mx-auto w-full max-w-2xl h-full md:h-auto">

                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                {rapor?.kunye?.sehir ?? rapor?.kunye?.isim} Şube Üniversite Komisyonu {yaziliAyGuneCevir(new Date(rapor?.gecenHaftaRapor?.createdAt))} Raporu
                            </h3>
                            <button type="button" onClick={modalClose} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div class="mx-auto p-10 space-y-6">
                            <embed className="mx-auto w-full"
                                src={pdfSource}
                                type="application/pdf"
                                width="300"
                                height="500"
                            />
                        </div>

                        <div class="items-center text-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                            AGD Üniversite 2022 
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
