import axios from "../api/axios";

class KunyeService{

    async getKunye(token){
        const headers = { headers: { "Content-Type": "application/json", Authorization: `Bearer ` + token } };  
        const response = await axios.get("/kunye",headers);
        return response;

    }
    async getBolgeKunye(token){
        const headers = { headers: { "Content-Type": "application/json", Authorization: `Bearer ` + token } };  
        const response = await axios.get("/kunye/bolge",headers);
        return response.data;
    }
    async getTurkiyeKunye(token){
        const headers = { headers: { "Content-Type": "application/json", Authorization: `Bearer ` + token } };  
        const response = await axios.get("/kunye/turkiye",headers);
        return response.data;
    }

}

export default KunyeService;