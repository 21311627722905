import React, { useEffect, useState } from 'react'
import ZRaporuItem from './ZRaporuItem'
import RaporService from "../services/RaporService";
import cs from "classnames";
import Header from './Header';
import TurkiyeZRaporGoruntule from "../components/TurkiyeZRaporGoruntule"
import TurkiyeZRaporOncekiGoruntule from './TurkiyeZRaporOncekiGoruntule';

export default function TurkiyeZRaporu() {

  let raporService = new RaporService();
  const token = localStorage.getItem("token");
  const [tumRaporlar, setTumRaporlar] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalOnce, setModalOnce] = useState(false);
  useEffect(() => { turkiyeRaporuGetir() }, [])
  const turkiyeRaporuGetir = () => {
    raporService.turkiyeRaporlariGetir(token,"zRapor")
      .then(res => {
        setTumRaporlar(res.data)
      })
      .catch(err => console.error(err))
  }

  return (
    <>
      <Header />
      <div className="container mx-auto">
          <div className="flex gap-5">
          <button onClick={() => setModal(!modal)} className="mt-2 inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Rapor Göster</button>
        {/* <button onClick={() => setModalOnce(!modal)} className="mt-2 inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Geçen Hafta Rapor Göster</button> */}
          </div>
       
        {modal ? <TurkiyeZRaporGoruntule rapor={tumRaporlar} modal={modal} setModal={setModal} /> : null}
        {modalOnce ? <TurkiyeZRaporOncekiGoruntule rapor={tumRaporlar} modal={modalOnce} setModal={setModalOnce} /> : null}
        {tumRaporlar?.map((rapor, index) => (
          <>
            <hr className="bg-blue-500" />
            <div className={cs('mt-2  flex gap-0 border shadow rounded-2   my-0 ', index % 2 == 0 ? 'bg-cyan-100' : 'bg-white')}>
              <ZRaporuItem rapor={rapor} index={index} />
            </div>
            <hr className="bg-blue-500" />

          </>
        ))}



        {/* </div> */}
      </div>
    </>


  )
}