import React, { useState, useEffect } from "react";
import logo from "../siniflardan.png";
import axios from "../api/axios";
import jwtDecode from "jwt-decode"
import { useNavigate, Navigate } from "react-router-dom";


export default function Login() {
  const navigate = useNavigate();
  const initialState = {
    kullanici: "",
    sifre: "",
  };
  const [user, setUser] = useState(initialState);
  const [errMsg, setErrMsg] = useState("");
  const [sifreMsg, setSifreMsg] = useState("");
  function handleChange(e) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }


  async function handleSubmit(e) {
    e.preventDefault();
    await axios
      .post("/kullanici/giris", {
        kullanici_adi: user.kullanici,
        parola: user.sifre,
      })
      .then(async (response) => {
        localStorage.clear();
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(jwtDecode(response.data.token)))
        navigate("/",{replace:true})

      })
      .catch((err) => {
        setErrMsg("Giriş başarısız!\n Kullanıcı adınızı veya şifrenizi kontrol ediniz.")
        setUser({kullanici:"",sifre:""})  
      });
  }
  return (<>
    {localStorage.getItem("token") ? <Navigate replace to="/" /> : (
        <section class="h-screen">
          <div class="px-6 h-full text-gray-800">
            <div class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
              <div class="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
                <img src={logo} class="w-full" />
              </div>
              <div class="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
                <h2 className="text-6xl font-medium text-center my-5 text-gray-500">
                  Giriş
                </h2>
                <form onSubmit={handleSubmit}>
                  <div class="mb-6">
                    <input
                      type="text"
                      class="form-control block w-full px-4 py-6 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      name="kullanici"
                      value={user.kullanici}
                      onChange={handleChange}
                      placeholder="Kullanıcı Adı"
                    />
                  </div>

                  <div class="mb-6">
                    <input
                      type="password"
                      class="form-control block w-full px-4 py-6 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      name="sifre"
                      value={user.sifre}
                      onChange={handleChange}
                      placeholder="Şifre"
                    />
                  </div>

                  <div class=" text-center justify-center items-center lg:text-left">
                    <button
                      type="submit"

                      class={`w-full px-7  py-7  justify-center bg-blue-600 text-white font-medium lg:text-lg sm:text-sm leading-snug  rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ${user.kullanici ==="" || user.sifre ==="" ? "pointer-events-none bg-slate-500" : ""}`}
                    >
                      Giriş Yap
                    </button>
                    <p className={`${errMsg ? "errmsg" : "offscreen"} text-xl rounded-lg py-5 px-6 text-red-700 mb-3`} aria-live="assertive">{errMsg}</p>
                    <div class="flex justify-end mt-6">
                      <button type="button" onClick={() => {
                        setSifreMsg("Şifrenizi öğrenmek için bölge başkanınızla iletişime geçin!")
                      }} class="text-gray-800 border border-blue-200 bg-gray-50 hover:bg-blue-700 hover:text-white hover:rounded justify-end">
                        Şifremi unuttum?
                      </button>
                      <p className={`${sifreMsg ? "errmsg" : "offscreen"} text-xl rounded-lg py-5 px-6 text-red-700 mb-3`} aria-live="assertive">{sifreMsg}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </section>
       
      )}
  </>
  );

}
