import React, { useState, useEffect, memo } from "react";
import KunyeService from "../services/KunyeService";
function Kunye({ setKunyeler, kunyeler }) {
  let kunyeService = new KunyeService();
  const token = localStorage.getItem("token");
  useEffect(() => {
    kunyeService
      .getKunye(token)
      .then((response) => {
        setKunye({
          ...kunye,
          sehir: response.data.isim,
          temsilciUniSayisi: response.data.temsilciUniSayisi,
          fakulteSayisi: response.data.fakulteSayisi,
          yuksekokulSayisi: response.data.yuksekokulSayisi,
          myoSayisi: response.data.myoSayisi,
          bolumSayisi: response.data.bolumSayisi,
          erkekOgrSayisi: response.data.erkekOgrSayisi,
          toplamOgrSayisi: response.data.toplamOgrSayisi,
        });
      })
      .catch((err) => {
        return;
      });
  }, []);
  const initialState = {
    sehir: "",
    temsilciUniSayisi: 0,
    fakulteSayisi: 0,
    yuksekokulSayisi: 0,
    myoSayisi: 0,
    bolumSayisi: 0,
    erkekOgrSayisi: 0,
    toplamOgrSayisi: 0,
  };
  const [kunye, setKunye] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKunye({ ...kunye, [name]: value });
  };

  useEffect(() => {
    setKunyeler(kunye);
  }, [kunye]);

  return (
    <>
      <div className="flex w-full">
        {/* 0.KOLON ŞEHİR */}
        <div className="flex-column w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Şehir"
              disabled
            />
          </div>
          <div className="flex-1">
            {/* Şehir  */}
            <input
              type="text"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="sehir"
              onChange={handleChange}
              value={kunye?.sehir}
              disabled
              placeholder="Şehri Giriniz"
            />
          </div>
        </div>

        <div className="flex-column w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Temsilci Üni. Sayısı"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            {/* 1.KOLON Temsilci Uni Sayisi */}
            <input
              type="number"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="temsilciUniSayisi"
              min={0}
              onChange={handleChange}
              value={kunye?.temsilciUniSayisi}
              placeholder="Sayı Gir"
            />
          </div>
        </div>
      </div>

      <div className="flex">
        {/* Fakülte Sayısı */}
        <div className="flex-column  w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Fakülte Sayısı"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            {/* Fakülte Sayısı  */}
            <input
              type="number"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="fakulteSayisi"
              min={0}
              onChange={handleChange}
              value={kunye?.fakulteSayisi}
              placeholder="Sayı Gir"
            />
          </div>
        </div>

        {/* YÜKSEKOKUL */}
        <div className="flex-column w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Yüksekokul"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            {/* YO SAYISI  */}
            <input
              type="number"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="yuksekokulSayisi"
              min={0}
              onChange={handleChange}
              value={kunye?.yuksekokulSayisi}
              placeholder=""
            />
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="flex-column w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Meslek Yüksekokulu"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            {/* MYO SAYISI  */}
            <input
              type="number"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="myoSayisi"
              onChange={handleChange}
              min={0}
              value={kunye?.myoSayisi}
              placeholder=""
            />
          </div>
        </div>

        {/* BÖLÜM */}
        <div className="flex-column w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Bölüm"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            {/* Bölüm Sayısı */}
            <input
              type="number"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="bolumSayisi"
              onChange={handleChange}
              min={0}
              value={kunye?.bolumSayisi}
              placeholder=""
            />
          </div>
        </div>
      </div>

      <div className="flex">
        {/* Erkek Öğrenci */}
        <div className="flex-column w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Erkek Öğrenci"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <div className="flex-1">
            {/* Erkek Öğr Sayısı  */}
            <input
              type="number"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="erkekOgrSayisi"
              onChange={handleChange}
              min={0}
              value={kunye?.erkekOgrSayisi}
              placeholder=""
            />
          </div>
        </div>

        {/* Toplam Öğrenci */}
        <div className="flex-column w-full md:w-30">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full h-6 border border-black"
              readyonly
              value="Toplam Öğrenci"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <div className="flex-1">
            {/* Toplam Öğrenci  */}
            <input
              type="number"
              className="text-center  px-1 form-control w-full h-8 border border-black"
              name="toplamOgrSayisi"
              onChange={handleChange}
              min={0}
              value={kunye?.toplamOgrSayisi}
              placeholder=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Kunye;
