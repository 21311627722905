import axios from "../api/axios";

class RaporService {
  async raporEkle(
    token,
    kunyeDetay,
    icmal,
    teskilatRaporu,
    egitimRaporu,
    evlerYurtlar,
    ortakRapor
  ) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };
    const data = {
      kunyeDetay,
      icmal,
      teskilatRaporu,
      egitimRaporu,
      evlerYurtlar,
      ortakRapor,
    };
    const response = await axios.post("/rapor", data, headers);
    return response.data;
  }

  async sonRaporuGetir(token) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };
    const response = await axios.post("/rapor/son-rapor", {}, headers);
    return response;
  }

  async raporGetir(token, id) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };
    const response = await axios.get(`/rapor/${id}`, headers);
    return response.data;
  }

  async ilRaporlariGetir(token, page = 1) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };
    const response = await axios.post(`/rapor/raporlar?page=${page}`, {}, headers);
    return response.data;

  }

  async yetkiliIlRaporlariGetir(token, page = 1, kunye_id) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };
    const response = await axios.post(`/rapor/yetkili/raporlar?page=${page}`, { kunye_id }, headers);
    return response.data;
  }

  async bolgeRaporlariGetir(token) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      }
    }
    const response = await axios.post(`/rapor/bolge`, {}, headers);
    return response.data;

  }

  async turkiyeRaporlariGetir(token, zRapor) {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      }
    }
    const data = {}

    if (zRapor==="zRapor") data.zRapor = "zRapor";
    const response = await axios.post(`/rapor/turkiye`, data, headers);
    return response.data;
  }
}

export default RaporService;