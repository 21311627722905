import React, { useState, useEffect } from 'react'
import BolgeItem from './BolgeItem';
import RaporService from "../services/RaporService";
import BolgeGenel from './BolgeGenel';
import Header from "./Header";
export default function Bolge() {
    const [modal, setModal] = useState(false);
    const [bolgeIller, setBolgeIller] = useState([]);
    const [date, setDate] = useState({});
    const token = localStorage.getItem("token");
    let raporService = new RaporService();
    const user = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        raporService.bolgeRaporlariGetir(token)
            .then(res => {
                setBolgeIller(res.data)
                setDate({firstDate:res.date.weekFirst,lastDate:res.date.weekLast});    
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <>
        <Header/>
       
        <div className="container mt-2 mx-auto ">
 
          <span className="p-5"> Bu haftanın rapor tarihi :</span> <b>{date.firstDate} - {date.lastDate}</b>
            <div class="flex flex-col mt-8">
           
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div class="inline-block min-w-full overflow-hidden align-middle border border-gray-200 shadow sm:rounded-lg">
                        <h2 className="md:text-center sm:text-left sm:mx-4 border text-xl justif">{user.il.substr(5,6)}.BÖLGE TAKİP</h2>
                        
                        <table class="min-w-full">
                            <thead className="border rounded">
                                <hr />
                                <tr className="border-b border-black">
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Şehir</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Rapor</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Rapor Durumu</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Rapor Tarihi</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Geçen Hafta Rapor</th>
                                    <th
                                        class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Temsilci Üni.</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Fakülte</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Yüksekokul</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        MYO</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Bölüm</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                        Erkek Öğr. Sayısı</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500  border-b border-gray-200 bg-gray-50">
                                       Toplam Öğr. Sayısı</th>
                                    
                                </tr>
                            </thead>
                            <tbody class="bg-blue-200">
                                {bolgeIller.map((il,index) => (<>
                                    <BolgeItem key={index} hafta={date} rapor={il} />
                                </>))}
                                <BolgeGenel rapor={bolgeIller} />




                                {/* <tr className="hover:bg-blue-300">
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" >
                                        <div class="flex items-center">
                                            Çorum
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <button onClick={() => setModal(!modal)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Görüntüle</button>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <span
                                            class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">Gönderdi</span>
                                    </td>
                                </tr> */}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
