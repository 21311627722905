import React, { useState, useEffect } from 'react'
import Header from '../components/Header';
import IlRaporlar from '../components/IlRaporlar';
import SelectIl from '../components/SelectIl';
import KunyeService from "../services/KunyeService";
import {useParams} from "react-router-dom";
export default function IlRaporlari() {

    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const yetki = JSON.parse(user).izinler[0].tip;
    let kunyeService = new KunyeService();
    const [kunyeler, setKunyeler] = useState([]);
    const [selectKunye, setSelectKunye] = useState("");
    const {page} = useParams();
    function kunyeGetir() {

        if (yetki === "turkiye") {
            kunyeService.getTurkiyeKunye(token).then(res => setKunyeler(res)).catch(err => console.error(err));
        }

        if (yetki.substring(2, 7) === "bolge") {
            kunyeService.getBolgeKunye(token).then(res => setKunyeler(res)).catch(err => console.error(err));
        }
    }
    useEffect(() => { kunyeGetir() }, [])

    return (
        <>
            <Header />
            <SelectIl kunyeler={kunyeler} setSelectKunye={setSelectKunye} selectKunye={selectKunye} />
            <IlRaporlar kunye_id={selectKunye} pageN={page} />
        </>
    )
}
