import React from "react";
import BirimBolgeTablo from "./BirimBolgeTablo";

export default function BirimBolge() {
  return (
    <> <div className="px-4 md:px-10 container mx-auto w-full">
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <BirimBolgeTablo />
        </div>
        
      </div>
      </div>
    </>
  );
}
