import React, { useState, useEffect } from "react";
import ToplamTeskilatci from "../components/ToplamTeskilatci";
import Header from "../components/Header";
import RaporService from "../services/RaporService";
import Kunye from "../components/Kunye";
import TeskilatRaporu from "../components/TeskilatRaporu";
import OrtakRapor from "../components/OrtakRapor";
import Icmal from "../components/Icmal";
import RaporBaslik from "../components/RaporBaslik";
import EvlerYurtlar from "../components/EvlerYurtlar";
import EgitimRaporu from "../components/EgitimRaporu";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { FaTrash } from 'react-icons/fa';
function Rapor() {
  const [kunyeler, setKunyeler] = useState({});
  const [icmaller, setIcmaller] = useState({});
  const [teskilatRaporlari, setTeskilatRaporlari] = useState({});
  const [egitimRaporlari, setEgitimRaporlari] = useState({});
  const [evlerVeYurtlar, setEvlerVeYurtlar] = useState({});
  const [ortakRaporlar, setOrtakRaporlar] = useState({});
  const [digerCalismalar, setDigerCalismalar] = useState([]);
  const [yeniDigerCalisma, setYeniDigerCalisma] = useState({
    calismaAdi: "",
    tarih: "",
    toplamKatilimci: "",
    teskilatciSayisi: "",
    misafirSayisi: "",
    not: "",
  });
  const [errors, setErrors] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const icmalHesapla = (icmaller, evlerVeYurtlar) => {
    const bolum = parseInt(icmaller.bolumBsk) + parseInt(icmaller.bolumKom);
    const fakulte =
      parseInt(icmaller.fakulteBsk) + parseInt(icmaller.fakulteKom);
    const myo = parseInt(icmaller.myoBsk) + parseInt(icmaller.myoKom);
    const sinifTemsilcisi = parseInt(icmaller.sinifTemsilcisi);
    const sube = parseInt(icmaller.subeUniBsk) + parseInt(icmaller.subeUniKom);
    const temsilci =
      parseInt(icmaller.temsilciUniBsk) + parseInt(icmaller.temsilciUniKom);
    const yuksekokul =
      parseInt(icmaller.yuksekokulBsk) + parseInt(icmaller.yuksekokulKom);

    const evlerYurtlar =
      parseInt(evlerVeYurtlar?.kykBaskan ?? 0) +
      parseInt(evlerVeYurtlar?.ozelYurtBaskan ?? 0);

    return (
      bolum +
      fakulte +
      myo +
      sinifTemsilcisi +
      sube +
      temsilci +
      yuksekokul +
      evlerYurtlar ?? 0
    );
  };

  let raporService = new RaporService();
  const handleSubmit = (e) => {
    e.preventDefault();
    raporService
      .raporEkle(
        token,
        kunyeler,
        icmaller,
        teskilatRaporlari,
        egitimRaporlari,
        evlerVeYurtlar,
        ortakRaporlar,
        digerCalismalar
      )
      .then((response) => {
        navigate(`/raporlar/1`, { replace: true });
      })
      .catch((err) => console.log(err));
  };
  const [sonRaporTarihi, setSonRaporTarihi] = useState(false);
  useEffect(() => {
    raporService
      .sonRaporuGetir(token)
      .then((res) => {
        const icmal = res.data.icmal;
        const teskilat = res.data.teskilatRaporu;
        const egitim = res.data.egitimRaporu;
        const evler = res.data.evlerYurtlar;
        const ortak = res.data.ortakRapor;
        const sonRaporTarih = res.data.createdAt;
        setSonRaporTarihi(sonRaporTarih);
        setIcmaller({
          ...icmaller,
          subeUniBsk: icmal.subeUniBsk,
          subeUniKom: icmal.subeUniKom,
          temsilciUniBsk: icmal.temsilciUniBsk,
          temsilciUniKom: icmal.temsilciUniKom,
          fakulteBsk: icmal.fakulteBsk,
          fakulteKom: icmal.fakulteKom,
          yuksekokulBsk: icmal.yuksekokulBsk,
          yuksekokulKom: icmal.yuksekokulKom,
          myoBsk: icmal.myoBsk,
          myoKom: icmal.myoKom,
          bolumBsk: icmal.bolumBsk,
          bolumKom: icmal.bolumKom,
          sinifTemsilcisi: icmal.sinifTemsilcisi,
        });

        setTeskilatRaporlari({
          ...teskilatRaporlari,
          subeUniToplantiKatilim: teskilat.subeUniToplantiKatilim,
          subeUniToplantiIzinli: teskilat.subeUniToplantiIzinli,
          subeUniToplantiIzinsiz: teskilat.subeUniToplantiIzinsiz,
          temsilciToplantiYapan: teskilat.temsilciToplantiYapan,
          temsilciToplantiKatilmali: teskilat.temsilciToplantiKatilmali,
          temsilciToplantisiKatilan: teskilat.temsilciToplantisiKatilan,
          fakulteToplantiYapan: teskilat.fakulteToplantiYapan,
          fakulteToplantiKatilmali: teskilat.fakulteToplantiKatilmali,
          fakulteToplantiKatilan: teskilat.fakulteToplantiKatilan,
          birebirGenclikYapan: teskilat.birebirGenclikYapan,
          birebirGenclikMuhatap: teskilat.birebirGenclikMuhatap,
          gundemYapan: teskilat.gundemYapan,
          gundemUlasilan: teskilat.gundemUlasilan,
          kantinNokta: teskilat.kantinNokta,
          kantinKatilan: teskilat.kantinKatilan,
          kantinMisafir: teskilat.kantinMisafir,
          ilceYerleske: teskilat.ilceYerleske,
          ilceZiyaret: teskilat.ilceZiyaret,
        });

        setEgitimRaporlari({
          ...egitimRaporlari,
          okumaGrubuSayisi: egitim.okumaGrubuSayisi,
          okumaGrubuToplamKatilimci: egitim.okumaGrubuToplamKatilimci,
          okumaGrubuTahlilSayisi: egitim.okumaGrubuTahlilSayisi,
          okumaGrubuTahlilKatilim: egitim.okumaGrubuTahlilKatilim,
          sabahNamaziNokta: egitim.sabahNamaziNokta,
          sabahNamaziTeskilatci: egitim.sabahNamaziTeskilatci,
          sabahNamaziMisafir: egitim.sabahNamaziMisafir,
          sohbetTeskilatci: egitim.sohbetTeskilatci,
          sohbetMisafir: egitim.sohbetMisafir,
          nesriyatMilliGazete: egitim.nesriyatMilliGazete,
          nesriyatAGD: egitim.nesriyatAGD,
        });

        setEvlerVeYurtlar({
          ...evlerVeYurtlar,
          teskilatEvSayisi: evler.teskilatEvSayisi,
          teskilatEvKalan: evler.teskilatEvKalan,
          teskilatEvZiyaret: evler.teskilatEvZiyaret,
          kardesEvSayisi: evler.kardesEvSayisi,
          kardesEvKalan: evler.kardesEvKalan,
          kardesEvZiyaret: evler.kardesEvZiyaret,
          teskilatYurtSayisi: evler.teskilatYurtSayisi,
          teskilatYurtKalan: evler.teskilatYurtKalan,
          teskilatYurtTeskilat: evler.teskilatYurtTeskilat,
          teskilatYurtMisafir: evler.teskilatYurtMisafir,
          kykSayisi: evler.kykSayisi,
          kykBaskan: evler.kykBaskan,
          kykZiyaret: evler.kykZiyaret,
          ozelYurtSayisi: evler.ozelYurtSayisi,
          ozelYurtBaskan: evler.ozelYurtBaskan,
          ozelYurtZiyaret: evler.ozelYurtZiyaret,
        });

        setOrtakRaporlar({
          ...ortakRaporlar,
          muhabbetFasliSayisi: ortak.muhabbetFasliSayisi,
          muhabbetFasliKatilim: ortak.muhabbetFasliKatilim,
          muhabbetFasliMisafir: ortak.muhabbetFasliMisafir,
          aidatciSayisi: ortak.aidatciSayisi,
        });
      })
      .catch((err) => setSonRaporTarihi(false));
  }, []);
  // const [formErrors, setFormErrors] = useState({});
  // setFormErrors(validate(placeValues));
  // if (Object.keys(formErrors).length === 0 && isSubmit) {}
  const validate = (values) => {
    const errors = {};
    if (!values.calismaAdi) {
      errors.calismaAdi = "Çalışma Adını girmek zorunludur.";
    } else if (!values.tarih) {
      errors.tarih = "Tarih giriniz.";
    } else if (!values.toplamKatilimci) {
      errors.toplamKatilimci = "Toplam katılımcı sayısı giriniz.";

    } else if (!values.teskilatciSayisi) {
      errors.teskilatciSayisi = "Teşkilatçı sayısı giriniz."
    } else if (!values.misafirSayisi) {
      errors.misafirSayisi = "Misafir sayısı giriniz."
    }

    return errors;
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: 0,
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(validate(yeniDigerCalisma)).length) {
      setErrors(validate(yeniDigerCalisma));
      return false
    } else {
      setDigerCalismalar([...digerCalismalar, yeniDigerCalisma]);
      setYeniDigerCalisma({
        calismaAdi: "",
        tarih: "",
        toplamKatilimci: "",
        teskilatciSayisi: "",
        misafirSayisi: "",
        not: "",
      });
      setErrors({})
    }


  };

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setYeniDigerCalisma((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Header />
      <RaporBaslik title={kunyeler?.sehir} sonRaporTarihi={sonRaporTarihi} />
      <div className="container mx-auto px-4 md:container md:mx-auto bg-gray-200">
        <div className="flex justify-end">
          <button
            className="bg-blue-700 text-white py-2 px-2 mt-2 rounded"
            onClick={() => setIsModalOpen(true)}
          >
            Ek Çalışmalar Ekle
          </button>
        </div>

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <div className="relative">
              <div className="md:container md:mx-auto">
                <div className="relative  md:hidden z-50 flex w-full  items-center bg-[rgba(208,239,255,0.5)] py-10">
                  <div className="absolute left-8 right-0 top-1/2   -translate-y-1/2 transform">
                    <button
                      className={`flex items-center justify-center rounded-full  p-2  bg-white text-blue-700`}
                      onClick={closeModal}
                    >
                      <IoIosArrowBack size={24} />
                    </button>
                  </div>
                  <h3 className=" flex w-full items-center justify-center pl-6 text-xl font-bold text-[#030000]">
                    Ek Çalışmalar Ekle
                  </h3>
                  <div className=" transfrom absolute -bottom-4 left-0 -z-10 h-8 w-full translate-y-1/2 bg-[rgba(208,239,255,0.5)] "></div>
                </div>

                <div className="hidden md:block py-4">
                  <button
                    onClick={closeModal}
                    className="absolute right-[2%] top-4 text-lg"
                  >
                    <RxCross2 size={24} color="black" />
                  </button>
                  <h4 className="text-2xl font-semibold text-center">
                    Ek Çalışmalar Ekle
                  </h4>
                </div>

                <div className="relative z-50 w-full rounded-tl-4xl rounded-tr-4xl  bg-white py-4 pb-10">
                  <div className="mb-4 flex flex-col items-center justify-center">
                    <form
                      className="w-full px-8"
                      onSubmit={(e) => handleFormSubmit(e)}
                    >
                      <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Çalışma Adı
                          </label>
                          <input
                            type="text"
                            name="calismaAdi"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Çalışma Adı"
                            value={yeniDigerCalisma.calismaAdi}
                            onChange={handleChange}
                          />
                          {errors.calismaAdi && <span className="text-red-500">{errors.calismaAdi}</span>}
                        </div>

                        <div>
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Çalışma Tarihi
                          </label>
                          <input
                            value={yeniDigerCalisma.tarih}
                            onChange={handleChange}
                            name="tarih"
                            type="date"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Gün - Ay - Yıl Şeklinde Yazınız"
                          />
                          {errors.tarih && <span className="text-red-500">{errors.tarih}</span>}
                        </div>

                        <div>
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Toplam Katılımcı
                          </label>
                          <input
                            value={yeniDigerCalisma.toplamKatilimci}
                            onChange={handleChange}
                            name="toplamKatilimci"
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Toplam Katılımcı Sayısı"
                          />
                          {errors.toplamKatilimci && <span className="text-red-500">{errors.toplamKatilimci}</span>}
                        </div>

                        <div>
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Teşkilatçı Sayısı
                          </label>
                          <input
                            name="teskilatciSayisi"
                            value={yeniDigerCalisma.teskilatciSayisi}
                            onChange={handleChange}
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Teşkilatcı Sayısı"
                          />
                          {errors.teskilatciSayisi && <span className="text-red-500">{errors.teskilatciSayisi}</span>}
                        </div>

                        <div>
                          <label
                            htmlFor="website"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Misafir Sayısı
                          </label>
                          <input
                            name="misafirSayisi"
                            value={yeniDigerCalisma.misafirSayisi}
                            onChange={handleChange}
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Misafir Sayısı"
                          />
                          {errors.misafirSayisi && <span className="text-red-500">{errors.misafirSayisi}</span>}
                        </div>

                        <div>
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            NOT :
                          </label>
                          <textarea
                            name="not"
                            value={yeniDigerCalisma.not}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            rows="5"
                            cols="30"
                            placeholder="Eklemek istediklerinizi yazınız..."
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Kaydet
                      </button>
                    </form>
                  </div>
                </div>
              </div>

              {/* Yeni Çalışmalar */}
              {digerCalismalar.length > 0 && (
                <div className="container mx-auto">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mx-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Çalışma Adı
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Çalışma Tarihi
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Toplam Katılımcı
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Teşkilatçı Sayısı / Misafir Sayısı
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Not
                          </th>
                          <th scope="col" className="px-6 py-3 text-end">
                            Aksiyon
                          </th>
                        </tr>
                      </thead>

                      {digerCalismalar?.map((item, index) => (
                        <>
                          <tbody key={index}>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {item.calismaAdi}
                              </th>
                              <td className="px-6 py-4">{item.tarih}</td>
                              <td className="px-6 py-4">
                                {item.toplamKatilimci}
                              </td>
                              <td className="px-6 py-4">
                                {item.teskilatciSayisi} / {item.misafirSayisi}
                              </td>
                              <td className="px-6 py-4">{item.not}</td>
                              <td className="px-6 py-4 text-right">
                                <button
                                  onClick={() => { setDigerCalismalar(digerCalismalar.filter(d => d.calismaAdi !== item.calismaAdi)) }}
                                  className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                >
                                  Sil
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ))}
                    </table>
                  </div>
                  {digerCalismalar.length && <button
                    type="submit"
                    onClick={() => setIsModalOpen(false)}
                    className="text-white bg-blue-700 my-4 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Tüm Çalışmaları Rapora Kaydet
                  </button>}

                </div>
              )}
            </div>
          </Modal>
        )}

        <form onSubmit={handleSubmit}>
          <h2 className="text-left font-bold leading-tight text-3xl ">Künye</h2>
          <div className="flex flex-col md:flex-row gap-0 border h-14 mb-52 md:mb-4 my-1 w-full">
            <Kunye kunyeler={kunyeler} setKunyeler={setKunyeler} />
          </div>
          <h2 className="text-left font-bold leading-tight text-3xl mt-2">
            İcmal
          </h2>
          <div className=" gap-0 h-18 mb-14 md:mb-5 my-1 w-full">
            <Icmal setIcmaller={setIcmaller} icmaller={icmaller} />
          </div>
          <h2 className="text-left font-bold leading-tight text-3xl">Rapor</h2>
          <div className="flex flex-col md:flex-row  gap-0 h-18 my-3">
            <TeskilatRaporu
              teskilatRaporlari={teskilatRaporlari}
              setTeskilatRaporlari={setTeskilatRaporlari}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-0 h-18 my-3">
            <EgitimRaporu
              egitimRaporlari={egitimRaporlari}
              setEgitimRaporlari={setEgitimRaporlari}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-0 h-18 my-10 md:my-3">
            <EvlerYurtlar
              setEvlerVeYurtlar={setEvlerVeYurtlar}
              evlerVeYurtlar={evlerVeYurtlar}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-0 h-18 my-3">
            <OrtakRapor
              setOrtakRaporlar={setOrtakRaporlar}
              ortakRaporlar={ortakRaporlar}
            />
            <ToplamTeskilatci
              uretim={0}
              gecenHafta={0}
              toplamTeskilatci={icmalHesapla(icmaller, evlerVeYurtlar)}
            />
          </div>



          {digerCalismalar.length > 0 &&
           <div className="overflow-x-auto shadow-md sm:rounded-lg mx-auto">
           <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
             <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
               <tr>
                 <th className="px-6 py-3">
                   Çalışma Adı
                 </th>
                 <th className="px-6 py-3">
                   Tarih
                 </th>
                 <th className="px-6 py-3">
                   Toplam Katılımcı
                 </th>
                 <th className="px-6 py-3">
                   Teşkilatçı / Misafir
                 </th>
                 <th className="px-6 py-3">
                   Not
                 </th>
                 <th className="px-6 py-3"></th>
               </tr>
             </thead>
             <tbody>
               {digerCalismalar?.map((item, index) => (
                 <tr
                   key={index}
                   className="border-b bg-gray-100  dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                 >
                   <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                     {item.calismaAdi}
                   </td>
                   <td className="px-6 py-4">{item.tarih}</td>
                   <td className="px-6 py-4">{item.toplamKatilimci}</td>
                   <td className="px-6 py-4">
                     {item.teskilatciSayisi} / {item.misafirSayisi}
                   </td>
                   <td className="px-6 py-4">{item.not}</td>
                   <td className="px-6 py-4 text-right">
                     <button
                       onClick={() => {
                         setDigerCalismalar(
                           digerCalismalar.filter((d) => d.calismaAdi !== item.calismaAdi)
                         );
                       }}
                       className="font-medium text-red-600 dark:text-red-500 hover:underline"
                     >
                       Sil
                       <span className="ml-1">
                         <FaTrash /> {/* Silme simgesini ekleyin */}
                       </span>
                     </button>
                   </td>
                 </tr>
               ))}
             </tbody>
           </table>
         </div>
         
          }







          <button
            type="submit"
            className="bg-transparent w-full my-5 bg-blue-500 hover:text-blue-700 font-semibold text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          >
            Gönder
          </button>
        </form>
      </div>
    </>
  );
}

export default Rapor;
