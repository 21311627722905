import axios from "../api/axios";

class BirimBolgeService{


    async birimBolgeGetir(token){
        const headers = { headers: { "Content-Type": "application/json", Authorization: `Bearer ` + token } };  
        const response = await axios.get("/birim",headers);
        return response.data;
        
        
    }
}

export default BirimBolgeService