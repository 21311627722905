import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import RaporItem from './RaporItem'
import RaporService from "../services/RaporService";
import Header from "../components/Header";
import Loading from './Loading';
function IlRaporlar({ kunye_id ,pageN}) {

    const [raporlar, setRaporlar] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const yetki = JSON.parse(user).izinler[0].tip;
    const { page } = useParams();
    let p = parseInt(page, 10)
    let pages = [];
    for (let i = 1; i <= Math.ceil(p); i++) {
        pages.push(i);
    }
    let raporService = new RaporService();
    const raporlariGetir = () => {
        if (!token) {
            return;
        }
        if (yetki === "turkiye") {
            if(!kunye_id) return;
            raporService.yetkiliIlRaporlariGetir(token, p, kunye_id)
                .then(res => {
                    setRaporlar(res.data);
                    setTotalPage(res.total_page);
                    setTotalCount(res.data.length);
                }).catch(err => { 
                    setRaporlar([])
                    setTotalPage(0);
                    setTotalCount(0);
                })
        } else if (yetki.substring(2, 7) === "bolge") {
            if(!kunye_id) return;
            raporService.yetkiliIlRaporlariGetir(token, p, kunye_id)
                .then(res => {
                    setRaporlar(res.data);
                    setTotalPage(res.total_page);
                    setTotalCount(res.data.length);
               
                }).catch(err => {
                    setRaporlar([])
                    setTotalPage(0);
                    setTotalCount(0);
                })
        } else {
            raporService.ilRaporlariGetir(token, p)
                .then(res => {
                    setRaporlar(res.data);
                    setTotalPage(res.total_page);
                    setTotalCount(res.data.length);
                })
        }


    }

    useEffect(() => {
        raporlariGetir();
    }, [p, kunye_id])

    // const onNext = () => {
    //     onPageChange(currentPage + 1);
    //   };

    //   const onPrevious = () => {
    //     onPageChange(currentPage - 1);
    //   };
    return (

        <div className="container mx-auto py-4">
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                <h2></h2>

                {raporlar.length>0 ?  (
                    <>
                      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">    
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
  
                          <tr>
                              <th scope="col" class="py-3 px-6">
                                  Rapor Tarihi
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Görüntüle & İndir
                              </th>
                              <th scope="col" class="py-3 px-6">
                                  Toplam Teşkilatçı
                              </th>
  
  
                          </tr>
                      </thead>
                      <tbody>
                          {raporlar?.map(rapor => (
                              <>
                                  <RaporItem rapor={rapor} />
                              </>
                          )) }
                      
  
  
                      </tbody>
                  </table>
                  <nav class="flex justify-between items-center pt-4" aria-label="Table navigation">
                  <span class="text-sm font-normal text-gray-500 dark:text-gray-400">Raporlar <span class="font-semibold text-gray-900 dark:text-gray-900">{`${p}-${p * 20}`}</span> gösterilmektedir. <span class="font-semibold text-gray-900 dark:text-gray-900">{totalCount} </span>rapor vardır.</span>
                  <ul class="inline-flex items-center -space-x-px">
                      <li>
                          <a href={`/${yetki==="turkiye" || yetki.substring(2,7)==="bolge" ? "il-raporlari" : "raporlar"}/${p <= 0 ? p = 1 : p - 1}`} class="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                              <span class="sr-only">Geri</span>
                              <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                          </a>
                      </li>

                      {
                          pages?.map((page, index) => {
                              return (<li key={index}>
                                  <a href={`/${yetki==="turkiye" || yetki.substring(2,7)==="bolge" ? "il-raporlari" : "raporlar"}/${page}`} class={`z-10 py-2 px-3 leading-tight text-gray-500 bg-blue-50 border border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:${p == page ? "text-white" : "text-gray-900"}`}>{page}</a>
                              </li>)
                          })
                      }
                      <li>
                          <a href={`/${yetki==="turkiye" || yetki.substring(2,7)==="bolge" ? "il-raporlari" : "raporlar"}/${p >= 0 ? p + 1 : p}`} class="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                              <span class="sr-only">İleri</span>
                              <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                          </a>
                      </li>
                  </ul>
              </nav>
              </>
                ) : kunye_id ? (<h2 className="text-center my-5 text-4xl text-red-500">Görüntülenebilecek bir rapor yok!</h2>) : null }
              
                
            </div>

        </div>
    )
}

export default IlRaporlar
