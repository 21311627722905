import date from "date-and-time";
import tr from 'date-and-time/locale/tr';
date.locale(tr)
export const sayiliYilaCevir = (tarih) => {

    const format = date.format(tarih, "DD.MM.YYYY HH:mm:ss");
    if (!format) {
        return;
    }
    return format;
}

export const yaziliTariheCevir = (tarih) => {
    const format = date?.format(tarih, "DD MMMM YYYY HH:mm:ss")
    if (!format) {
        return "";
    }
    return format;
}
export const yaziliAyGuneCevir = (tarih) => {
    const format = date.format(tarih, "DD MMMM");
    if (!format) {
        return false;
    }
    return format;
}

export const stringCevir = (veri) => {

    return veri?.toString();
}

export const uretimHesapla = (once, sonra) => {
    // console.log(once,sonra)
    if (!once || !sonra) return undefined
    return parseInt(sonra) - parseInt(once);
}

export const toplamTeskilatciHesapla = (icmal, kykBsk, ozelYurtBsk) => {
    if (!icmal) {
        return "0";
    }
    const filtered = Object.values(icmal).filter(value => value !== null)
    // const filtered =Object.entries(icmal).filter(([key,value]) => value!==null);
    const icmalToplam = filtered.reduce((acc, val) => acc + val)
    // console.log(filtered.map(([key,value]) => values+=value))
    const result = icmalToplam +kykBsk +ozelYurtBsk
    // const result =Object.keys(filtered).reduce((acc, key) => parseInt(acc)+parseInt(key))
    // const { subeUniBsk, subeUniKom, temsilciUniBsk, temsilciUniKom,
    //     bolumBsk, bolumKom, fakulteBsk, fakulteKom, myoBsk, myoKom,
    //     yuksekokulBsk, yuksekokulKom, sinifTemsilcisi } = icmal
    // const toplam = (parseInt(subeUniBsk) + parseInt(subeUniKom) + parseInt(temsilciUniBsk) +
    //     parseInt(temsilciUniKom) + parseInt(bolumBsk) + parseInt(bolumKom) + parseInt(fakulteBsk) + parseInt(fakulteKom) +
    //     parseInt(myoBsk) + parseInt(myoKom) + parseInt(yuksekokulBsk) + parseInt(yuksekokulKom) + parseInt(sinifTemsilcisi)+parseInt(kykBsk)+parseInt(ozelYurtBsk)) ?? "0";
    return `${result.toString()}` ?? "0"
}

export const gecenHaftaRaporHesapla = (oncekiIcmal, sonrakiIcmal) => {
}

export const toplamTeskilatHesapla = (teskilatciSayilari) => {
    const toplam = teskilatciSayilari.reduce((acc, obj) => (parseInt(acc) + parseInt(obj.toplamTeskilatciSayisi)), 0);
    return toplam
}

