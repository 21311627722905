import React from "react";

function SelectIl({ kunyeler, setSelectKunye ,selectKunye}) {

  return (
    <>
      <div class="flex justify-center mt-2">
        <div class="mb-3 xl:w-96">
          <select
            class="
                    form-select appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Default select example"
            onChange={(e) => setSelectKunye(e.target.value)}
            value={selectKunye}
          >
            <option>Raporunu görmek istediğiniz şehri seçiniz</option>
            {kunyeler?.map((kunye) => (
              <option key={kunye._id} value={kunye._id}>
                {kunye.sehir}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default SelectIl;
