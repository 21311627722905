import React, { useState, useEffect } from 'react'
import { createPdf } from "../utils/modifyPdf";

export default function TurkiyeGenelRaporGoruntule({ rapor, kunye, modal, setModal }) {
    const [base64Str, setBase64Str] = useState(null);
    const pdfSource = `data:application/pdf;base64,${base64Str}`;


    const [raporlar, setRaporlar] = useState()
    useEffect(() => {
        if (!rapor && !kunye) { return }
        bolgeRaporHesapla();
    }, [rapor, kunye])
    useEffect(() => { modify() }, [raporlar])

    const user = localStorage.getItem("user");
    const title = "Türkiye"
    
    function bolgeRaporHesapla() {
        // const iller = rapor.filter(element => { return element.gecenHaftaRapor !== null && element.rapor !==null })
        const iller = rapor.filter(element => { return element.gecenHaftaRapor !== null  })
        // iller.reduce((acc, obj) => (acc + obj?.rapor?.egitimRaporu['nesriyatAGD']), 0)
        const egitimRaporu = {
            nesriyatAGD: iller.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['nesriyatAGD']), 0),
            nesriyatMilliGazete: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['nesriyatMilliGazete']), 0),
            okumaGrubuSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['okumaGrubuSayisi']), 0),
            okumaGrubuTahlilSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['okumaGrubuTahlilSayisi']), 0),
            okumaGrubuTahlilKatilim: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['okumaGrubuTahlilKatilim']), 0),
            okumaGrubuToplamKatilimci: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['okumaGrubuToplamKatilimci']), 0),
            sabahNamaziMisafir: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['sabahNamaziMisafir']), 0),
            sabahNamaziNokta: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['sabahNamaziNokta']), 0),
            sabahNamaziTeskilatci: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['sabahNamaziTeskilatci']), 0),
            sohbetMisafir: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['sohbetMisafir']), 0),
            sohbetTeskilatci: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.egitimRaporu['sohbetTeskilatci']), 0),
        }


        const evlerYurtlar = {
            kardesEvKalan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['kardesEvKalan']), 0),
            kardesEvSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['kardesEvSayisi']), 0),
            kardesEvZiyaret: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['kardesEvZiyaret']), 0),
            kykSayisi:iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['kykSayisi']), 0),
            kykBaskan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['kykBaskan']), 0),
            kykZiyaret: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['kykZiyaret']), 0),
            ozelYurtBaskan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['ozelYurtBaskan']), 0),
            ozelYurtSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['ozelYurtSayisi']), 0),
            ozelYurtZiyaret: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['ozelYurtZiyaret']), 0),
            teskilatEvKalan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['teskilatEvKalan']), 0),
            teskilatEvSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['teskilatEvSayisi']), 0),
            teskilatEvZiyaret: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['teskilatEvZiyaret']), 0),
            teskilatYurtKalan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['teskilatYurtKalan']), 0),
            teskilatYurtMisafir: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['teskilatYurtMisafir']), 0),
            teskilatYurtSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['teskilatYurtSayisi']), 0),
            teskilatYurtTeskilat: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.evlerYurtlar['teskilatYurtTeskilat']), 0),
        }
        const icmal = {
            bolumBsk: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['bolumBsk']), 0),
            bolumKom: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['bolumKom']), 0),
            fakulteBsk: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['fakulteBsk']), 0),
            fakulteKom: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['fakulteKom']), 0),
            myoBsk: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['myoBsk']), 0),
            myoKom: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['myoKom']), 0),
            sinifTemsilcisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['sinifTemsilcisi']), 0),
            subeUniBsk: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['subeUniBsk']), 0),
            subeUniKom: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['subeUniKom']), 0),
            temsilciUniBsk: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['temsilciUniBsk']), 0),
            temsilciUniKom: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['temsilciUniKom']), 0),
            yuksekokulBsk: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['yuksekokulBsk']), 0),
            yuksekokulKom: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.icmal['yuksekokulKom']), 0),
        }

        const teskilatRaporu = {
            birebirGenclikMuhatap: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['birebirGenclikMuhatap']), 0),
            birebirGenclikYapan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['birebirGenclikYapan']), 0),
            fakulteToplantiKatilam: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['fakulteToplantiKatilam']), 0),
            fakulteToplantiKatilmali: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['fakulteToplantiKatilmali']), 0),
            fakulteToplantiYapan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['fakulteToplantiYapan']), 0),
            gundemUlasilan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['gundemUlasilan']), 0),
            gundemYapan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['gundemYapan']), 0),
            ilceYerleske: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['ilceYerleske']), 0),
            ilceZiyaret: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['ilceZiyaret']), 0),
            kantinKatilan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['kantinKatilan']), 0),
            kantinMisafir: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['kantinMisafir']), 0),
            kantinNokta: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['kantinNokta']), 0),
            subeUniToplantiIzinli: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['subeUniToplantiIzinli']), 0),
            subeUniToplantiIzinsiz: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['subeUniToplantiIzinsiz']), 0),
            subeUniToplantiKatilim: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['subeUniToplantiKatilim']), 0),
            temsilciToplantiKatilmali: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['temsilciToplantiKatilmali']), 0),
            temsilciToplantiYapan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['temsilciToplantiYapan']), 0),
            temsilciToplantisiKatilan: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.teskilatRaporu['temsilciToplantisiKatilan']), 0),
        }
        const ortakRapor = {
            aidatciSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.ortakRapor['aidatciSayisi']), 0),
            muhabbetFasliKatilim: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.ortakRapor['muhabbetFasliKatilim']), 0),
            muhabbetFasliMisafir: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.ortakRapor['muhabbetFasliMisafir']), 0),
            muhabbetFasliSayisi: iller?.reduce((acc, obj) => (acc + obj?.gecenHaftaRapor?.ortakRapor['muhabbetFasliSayisi']), 0),
        }   
        const teskilatciSayisi = iller?.reduce((acc,obj) => (parseInt(acc)+parseInt(obj.gecenHaftaTeskilatci)),0)
        const gecenHaftaTeskilatciSayisi = iller?.reduce((acc,obj) => (parseInt(acc)+parseInt(obj.gecenHaftaOnceTeskilatci)),0)
        //2 hafta raporu olmayan ili alma
        return  setRaporlar({ ortakRapor, egitimRaporu, teskilatRaporu, evlerYurtlar, icmal, kunye,teskilatciSayisi,gecenHaftaTeskilatciSayisi})
    }

    const modify = async () => {
        if (!raporlar) return;
        await createPdf(new Date(), title, raporlar, 0, raporlar?.gecenHaftaTeskilatciSayisi, raporlar?.teskilatciSayisi)
            .then((res) => {
                setBase64Str(res)
            })
            .catch(err => console.log(err));
    };
    const modalClose = () => {
        setModal(false);
    }

    return (
        <>
            <div id="defaultModal" tabindex="-1" aria-hidden="true" class={`${modal ? "" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`}>
                <div class="relative p-4 mx-auto w-full max-w-2xl h-full md:h-auto">

                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                Genel Merkez Türkiye Geçen Hafta Raporu
                            </h3>
                            <button type="button" onClick={modalClose} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div class="mx-auto p-10 space-y-6">
                            <embed className="mx-auto w-full"
                                src={pdfSource}
                                type="application/pdf"
                                width="300"
                                height="500"
                            />
                        </div>

                        <div class="items-center text-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                            AGD Üniversite 2022
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
