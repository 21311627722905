import React, { useState, useEffect } from "react";

function EgitimRaporu({ setEgitimRaporlari, egitimRaporlari }) {
  const initialState = {
    okumaGrubuSayisi: 0,
    okumaGrubuToplamKatilimci: 0,
    okumaGrubuTahlilSayisi: 0,
    okumaGrubuTahlilKatilim: 0,
    sabahNamaziNokta: 0,
    sabahNamaziTeskilatci: 0,
    sabahNamaziMisafir: 0,
    sohbetTeskilatci: 0,
    sohbetMisafir: 0,
    nesriyatMilliGazete: 0,
    nesriyatAGD: 0,
  };
  const [egitimRapor, setEgitimRapor] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEgitimRapor({ ...egitimRaporlari, [name]: value });
  };

  useEffect(() => {
    setEgitimRaporlari(egitimRapor);
  }, [egitimRapor]);

  return (
    <>
      {/* 0.Kolon Eğitim Raporu */}
      <div className="flex-column w-30">
        <div className="flex-1 border h-full border-black">
          <h2 className="text-center text-bold px-2 leading-tight py-7 text-xl">
            Eğitim <br /> Raporu
          </h2>
        </div>
      </div>

      {/* 1.KOLON Okuma Grubu */}
      <div className="flex-column w-full md:w-80">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full md:w-80 h-20 border border-black"
            readyonly
            value="Okuma Grubu"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="Grup"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="To.Katılımcı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="Tahlil"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="Ta.Katılımcı"
          disabled
        />

        <div className="flex-1">
          {/* //Grup Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="okumaGrubuSayisi"
            min={0}
            onChange={handleChange}
            value={
              egitimRaporlari.okumaGrubuSayisi ?? egitimRapor?.okumaGrubuSayisi
            }
            placeholder="Sayı Gir"
          />
          {/* //Toplam Katılımcı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="okumaGrubuToplamKatilimci"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.okumaGrubuToplamKatilimci ??
              egitimRapor?.okumaGrubuToplamKatilimci
            }
            placeholder="Sayı Gir"
          />
          {/* //Tahlil Sayisi */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="okumaGrubuTahlilSayisi"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.okumaGrubuTahlilSayisi ??
              egitimRapor?.okumaGrubuTahlilSayisi
            }
            placeholder="Sayı Gir"
          />
          {/* Tahlil Katılımcı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="okumaGrubuTahlilKatilim"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.okumaGrubuTahlilKatilim ??
              egitimRapor?.okumaGrubuTahlilKatilim
            }
            placeholder="Sayı Gir"
          />
        </div>
      </div>

      {/* 2.KOLON Sabah Namazı */}
      <div className="flex-column w-full md:w-80">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full md:w-80 h-20 border border-black"
            readyonly
            value="Sabah Namazı"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Nokta"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Teşkilatçı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Misafir"
          disabled
        />

        <div className="flex-1">
          {/* //Kaç Noktada */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="sabahNamaziNokta"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.sabahNamaziNokta ?? egitimRapor?.sabahNamaziNokta
            }
            placeholder="Sayı Gir"
          />
          {/* //Teşkilatçı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="sabahNamaziTeskilatci"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.sabahNamaziTeskilatci ??
              egitimRapor?.sabahNamaziTeskilatci
            }
            placeholder="Sayı Gir"
          />
          {/* //Misafir */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="sabahNamaziMisafir"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.sabahNamaziMisafir ??
              egitimRapor?.sabahNamaziMisafir
            }
            placeholder="Sayı Gir"
          />
        </div>
      </div>

      {/* 3.KOLON Sohbet */}
      <div className="flex-column w-full md:w-60">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full md:w-60 h-20 border border-black"
            readyonly
            value="Sohbet"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/2 border border-black"
          readyonly
          value="Teşkilatçı"
          disabled
        />

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/2 border border-black"
          readyonly
          value="Misafir"
          disabled
        />

        <div className="flex-1">
          {/* //Teşkilatçı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/2 h-7 border border-black"
            name="sohbetTeskilatci"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.sohbetTeskilatci ?? egitimRapor?.sohbetTeskilatci
            }
            placeholder="Sayı Gir"
          />
          {/* //Misafir */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/2 h-7 border border-black"
            name="sohbetMisafir"
            min={0}
            onChange={handleChange}
            value={egitimRaporlari.sohbetMisafir ?? egitimRapor?.sohbetMisafir}
            placeholder="Sayı Gir"
          />
        </div>
      </div>

      {/* 4.KOLON Neşriyat */}
      <div className="flex-column w-full md:w-60">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full md:w-60 h-20 border border-black"
            readyonly
            value="Neşriyat"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/2 border border-black"
          readyonly
          value="Milli Gazete"
          disabled
        />

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/2 border border-black"
          readyonly
          value="AGD Dergi"
          disabled
        />

        <div className="flex-1">
          {/* //Milli Gazete */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/2 h-7 border border-black"
            name="nesriyatMilliGazete"
            onChange={handleChange}
            min={0}
            value={
              egitimRaporlari.nesriyatMilliGazete ??
              egitimRapor?.nesriyatMilliGazete
            }
            placeholder="Sayı Gir"
          />
          {/* //AGD Dergi */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/2 h-7 border border-black"
            name="nesriyatAGD"
            min={0}
            onChange={handleChange}
            value={egitimRaporlari.nesriyatAGD ?? egitimRapor?.nesriyatAGD}
            placeholder="Sayı Gir"
          />
        </div>
      </div>
    </>
  );
}

export default EgitimRaporu;
