import React, { useState, useEffect, memo } from "react";

function Icmal({ icmaller, setIcmaller }) {
  const initialState = {
    subeUniBsk: 0,
    subeUniKom: 0,
    temsilciUniBsk: 0,
    temsilciUniKom: 0,
    fakulteBsk: 0,
    fakulteKom: 0,
    yuksekokulBsk: 0,
    yuksekokulKom: 0,
    myoBsk: 0,
    myoKom: 0,
    bolumBsk: 0,
    bolumKom: 0,
    sinifTemsilcisi: 0,
  };
  const [icmal, setIcmal] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIcmal({ ...icmaller, [name]: value });
  };

  useEffect(() => {
    setIcmaller(icmal);
  }, [icmal]);

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex-column w-30">
        <div className="flex-1 border px-2 h-full border-black">
          <h2 className="text-center text-bold leading-tight py-7 text-xl">
            Teşkilat <br /> Durumu
          </h2>
        </div>
      </div>

      <div className="flex">
        {/* 0.KOLON TEŞKİLAT DURUMU */}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-xl hover:text-sky-400 px-0 form-control w-full md:w-40 h-8 border border-black"
              readyonly
              value="Şube Üniversite"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <div className="flex-1">
            <input
              type="text"
              className="text-center  px-1 form-control w-1/2 border border-black"
              readyonly
              value="Şube Üni"
              aria-label="readonly input example"
              disabled
            />
            <input
              type="text"
              className="text-center px-1 form-control w-1/2 border border-black"
              readyonly
              value="Şube Kom"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-xl py-0 form-control w-1/2  border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <input
            type="text"
            className="text-center text-xl px-1  form-control w-1/2 border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <div className="flex-1">
            {/* Şube üni bşk */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 border border-black"
              name="subeUniBsk"
              min={0}
              onChange={handleChange}
              value={icmaller.subeUniBsk ?? icmal?.subeUniBsk}
              placeholder="Sayı Gir"
            />
            {/* Şube üni Kom */}
            <input
              type="number"
              className="text-center px-1 form-control w-1/2 border border-black"
              name="subeUniKom"
              min={0}
              onChange={handleChange}
              value={icmaller.subeUniKom ?? icmal?.subeUniKom}
              placeholder="Sayı Gir"
            />
          </div>
        </div>

        {/* 2.KOLON TEMSİLCİ ÜNİVERSİTE */}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg  px-0 form-control w-full md:w-40 h-8 border border-black"
              readyonly
              value="Temsilci Üniversite"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              className="text-center  px-1 form-control w-1/2 border border-black"
              readyonly
              value="Başkanı"
              aria-label="readonly input example"
              disabled
            />
            <input
              type="text"
              className="text-center px-1 form-control w-1/2 border border-black"
              readyonly
              value="Komisyonu"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-bold text-xl py-0 form-control w-1/2  border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <input
            type="text"
            className="text-center px-1 text-xl form-control w-1/2 border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <div className="flex-1">
            {/* Temsilci uni bsk */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 border border-black"
              name="temsilciUniBsk"
              min={0}
              onChange={handleChange}
              value={icmaller.temsilciUniBsk ?? icmal?.temsilciUniBsk}
              placeholder="Sayı Gir"
            />
            {/* Temsilci Uni Kom */}
            <input
              type="number"
              className="text-center px-1 form-control w-1/2 border border-black"
              name="temsilciUniKom"
              min={0}
              onChange={handleChange}
              value={icmaller.temsilciUniKom ?? icmal?.temsilciUniKom}
              placeholder="Sayı Gir"
            />
          </div>
        </div>
      </div>

      <div className="flex">
        {/* 3.KOLON FAKÜLTE */}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-xl  px-0 form-control w-full md:w-40 h-8 border border-black"
              readyonly
              value="Fakülte"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              className="text-center   px-1 form-control w-1/2 border border-black"
              readyonly
              value="Başkanı"
              aria-label="readonly input example"
              disabled
            />
            <input
              type="text"
              className="text-center px-1 form-control w-1/2 border border-black"
              readyonly
              value="Komisyonu"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-xl text-bold py-0 form-control w-1/2  border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <input
            type="text"
            className="text-center text-xl px-1 form-control w-1/2 border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <div className="flex-1">
            {/* Fakulte Bsk */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 border border-black"
              name="fakulteBsk"
              min={0}
              onChange={handleChange}
              value={icmaller.fakulteBsk ?? icmal?.fakulteBsk}
              placeholder="Sayı Gir"
            />
            {/* Fakulte Kom */}
            <input
              type="number"
              className="text-center px-1 form-control w-1/2 border border-black"
              name="fakulteKom"
              min={0}
              onChange={handleChange}
              value={icmaller.fakulteKom ?? icmal?.fakulteKom}
              placeholder="Sayı Gir"
            />
          </div>
        </div>

        {/* 4.KOLON YÜKSEKOKUL */}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center  text-xl px-0 form-control w-full md:w-40 h-8 border border-black"
              readyonly
              value="Yüksekokul"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              className="text-center  px-1 form-control w-1/2 border border-black"
              readyonly
              value="Başkanı"
              aria-label="readonly input example"
              disabled
            />
            <input
              type="text"
              className="text-center px-1 form-control w-1/2 border border-black"
              readyonly
              value="Komisyonu"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-xl text-bold py-0 form-control w-1/2  border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <input
            type="text"
            className="text-center text-xl px-1 form-control w-1/2 border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <div className="flex-1">
            {/* Yüksekokul bsk */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 border border-black"
              name="yuksekokulBsk"
              onChange={handleChange}
              min={0}
              value={icmaller.yuksekokulBsk ?? icmal?.yuksekokulBsk}
              placeholder="Sayı Gir"
            />
            {/* Yüksekokul Kom */}
            <input
              type="number"
              className="text-center px-1 form-control w-1/2 border border-black"
              name="yuksekokulKom"
              onChange={handleChange}
              min={0}
              value={icmaller.yuksekokulKom ?? icmal?.yuksekokulKom}
              placeholder="Sayı Gir"
            />
          </div>
        </div>
      </div>

      <div className="flex">
        {/* 5.KOLON MYO */}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-md  px-0 form-control w-full md:w-40 h-8 border border-black"
              readyonly
              value="Meslek Yüksekokulu"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              className="text-center  px-1 form-control w-1/2 border border-black"
              readyonly
              value="Başkanı"
              aria-label="readonly input example"
              disabled
            />
            <input
              type="text"
              className="text-center px-1 form-control w-1/2 border border-black"
              readyonly
              value="Komisyonu"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-xl text-bold py-0 form-control w-1/2  border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <input
            type="text"
            className="text-center text-xl px-1 form-control w-1/2 border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <div className="flex-1">
            {/* MYO BŞK */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 border border-black"
              name="myoBsk"
              onChange={handleChange}
              min={0}
              value={icmaller.myoBsk ?? icmal?.myoBsk}
              placeholder="Sayı Gir"
            />
            {/* Myo Kom */}
            <input
              type="number"
              className="text-center px-1 form-control w-1/2 border border-black"
              name="myoKom"
              onChange={handleChange}
              min={0}
              value={icmaller.myoKom ?? icmal?.myoKom}
              placeholder="Sayı Gir"
            />
          </div>
        </div>

        {/* 6.KOLON BÖLÜM */}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-xl px-0 form-control w-full md:w-40 h-8 border border-black"
              readyonly
              value="Bölüm"
              aria-label="readonly input example"
              disabled
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              className="text-center  px-1 form-control w-1/2 border border-black"
              readyonly
              value="Başkanı"
              aria-label="readonly input example"
              disabled
            />
            <input
              type="text"
              className="text-center px-1 form-control w-1/2 border border-black"
              readyonly
              value="Komisyonu"
              aria-label="readonly input example"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-xl text-bold py-0 form-control w-1/2  border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <input
            type="text"
            className="text-center text-xl px-1 form-control w-1/2 border border-black"
            readyonly
            value="Mevcut"
            aria-label="readonly input example"
            disabled
          />
          <div className="flex-1">
            {/* Bolum Bsk */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 border border-black"
              name="bolumBsk"
              onChange={handleChange}
              min={0}
              value={icmaller.bolumBsk ?? icmal?.bolumBsk}
              placeholder="Sayı Gir"
            />
            {/* Bolum Kom */}
            <input
              type="number"
              className="text-center px-1 form-control w-1/2 border border-black"
              name="bolumKom"
              onChange={handleChange}
              min={0}
              value={icmaller.bolumKom ?? icmal?.bolumKom}
              placeholder="Sayı Gir"
            />
          </div>
        </div>
      </div>

      {/* 7.KOLON Sınıf Temsilcisi */}
      <div className="flex-column w-full md:w-40">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-2xl px-0 form-control w-1/2 md:w-40 h-14 border border-black"
            readyonly
            value="Sınıf Temsilcisi"
            aria-label="readonly input example"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-xl text-bold py-0 form-control w-1/2 md:w-40 border border-black"
          readyonly
          value="Mevcut"
          aria-label="readonly input example"
          disabled
        />

        <div className="flex-1">
          {/* Sınıf Temsilcisi */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/2 md:w-40 h-7 border border-black"
            name="sinifTemsilcisi"
            onChange={handleChange}
            min={0}
            value={icmaller.sinifTemsilcisi ?? icmal?.sinifTemsilcisi}
            placeholder="Sayı Gir"
          />
        </div>
      </div>
    </div>
  );
}

export default memo(Icmal);
