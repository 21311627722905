import React, { useState } from "react";
import logout from "../logout.svg";
import { useNavigate, useLocation } from "react-router-dom";
import cs from "classnames";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import autoAnimate from "@formkit/auto-animate";
import agduniyan from "../agd_uni_yan.png"
function Header() {
  let navigate = useNavigate();
  let location = useLocation();
  const user = localStorage.getItem("user");
  const permission = user && JSON.parse(user)?.izinler[0]?.tip;
  const [animationParent] = useAutoAnimate()
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <header className="container mx-auto">
        <nav
          class="
          flex flex-wrap
          items-center
          justify-between
          w-full
          py-4
          md:py-0
          px-4
          text-lg text-gray-700
          bg-gray-200
        "
        >
          <div className="bg-grey-light hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center">
            <span className="md:p-4 py-2 block hover:text-blue-500">
              <a href="/"><img src={agduniyan} width="200" ></img></a>
            </span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="menu-button"
            class="h-6 w-6 cursor-pointer md:hidden block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => setNavbar(!navbar)}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
            {navbar ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </svg>

          <div className="w-full md:flex md:items-center md:w-auto">
            {navbar ? (<div class={`mobile-menu md:hidden  ${navbar ? "" : "hidden"}`}>
              <ul class="" ref={animationParent}>
                <li className={`${location.pathname == "/" ? 'active' : ''}`}><a onClick={() => { navigate("/", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Anasayfa</a></li>
                {permission === "DE" ? (<>
                  <li className={`${location.pathname == "/rapor" ? 'active' : ''}`}><a onClick={() => { navigate("/rapor", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Rapor Ekle</a></li>
                  <li className={`${location.pathname == "/raporlar/1" ? 'active' : ''}`}><a onClick={() => { navigate("/raporlar/1", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Raporlarım</a></li>
                  <li className={`${location.pathname == "/bilgi" ? 'active' : ''}`}><a onClick={() => { navigate("/bilgi", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Birim & Bölge</a></li>

                </>) : null}
                {permission.substring(2, 7) === "bolge" ? (<>
                  <li className={`${location.pathname == "/bolge" ? 'active' : ''}`}><a onClick={() => { navigate("/bolge", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Bölge Takip</a></li>
                  <li className={`${location.pathname == "/il-raporlari/1" ? 'active' : ''}`}><a onClick={() => { navigate("/il-raporlari/1", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">İl Raporları</a></li>
                </>
                ) : null}
                {permission === "turkiye" ? (
                  <>
                    <li className={`${location.pathname == "/turkiye" ? 'active' : ''}`}><a onClick={() => { navigate("/turkiye", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Rapor Takip</a></li>
                    <li className={`${location.pathname == "/turkiye-z-raporu" ? 'active' : ''}`}><a onClick={() => { navigate("/turkiye-z-raporu", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Z Raporu</a></li>
                    <li className={`${location.pathname == "/il-raporlari/1" ? 'active' : ''}`}><a onClick={() => { navigate("/il-raporlari/1", { replace: true }) }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Geçmiş Raporlar</a></li>

                  </>
                ) : null}
                <li><a onClick={() => {
                 
                  navigate("/sifre", { replace: true });
                }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Şifre</a></li>
                <li><a onClick={() => {
                  localStorage.removeItem("token")
                  navigate("/giris", { replace: true });
                }} class="block  text-sm px-2 py-4 text-white bg-green-400 hover:bg-green-500 transition duration-300 font-semibold">Çıkış Yap</a></li>



              </ul>
            </div>) : null}
          </div>
          <div
            class="hidden w-full md:flex md:items-center md:w-auto"
            id="menu"
          >

            <ul
              ref={animationParent}
              class="
              pt-4
              text-base text-gray-700
              md:flex
              md:justify-between 
              md:pt-0"
            >
              <li>
                <button onClick={() => { navigate("/", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/" ? 'bg-gray-400' : 'bg-grey-light')}>
                  <span className="md:p-4 py-2 block hover:text-blue-500">
                    Anasayfa
                  </span>
                </button>
              </li>
              {/* permission.substring(2, 7) === "bolge" */}
              {permission === "DE" ? (
                <>
                  <li>
                    <button onClick={() => { navigate("/rapor", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/rapor" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        Rapor Ekle
                      </span>
                    </button>
                  </li>
                  <li>
                    <button onClick={() => {
                      navigate("/raporlar/1", { replace: true })
                    }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/raporlar/1" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        Raporlarım
                      </span>
                    </button>
                  </li>
                  <li>
                    <button onClick={() => { navigate("/bilgi", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/bilgi" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        Birim & Bölge
                      </span>
                    </button>
                  </li>
                </>
              ) : null}

              {permission.substring(2, 7) === "bolge" ? (
                <>
                  <li>
                    <button onClick={() => { navigate("/bolge", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/bolge" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        Bölge Takip
                      </span>
                    </button>
                  </li>
                  <li>
                    <button onClick={() => { navigate("/il-raporlari/1", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/il-raporlari/1" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        İl Raporları
                      </span>
                    </button>
                  </li>
                </>
              ) : null}
              {permission === "turkiye" ? (
                <>
                  <li>
                    <button onClick={() => { navigate("/turkiye", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/turkiye" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        Rapor Takip
                      </span>
                    </button>
                  </li>
                  <li>
                    <button onClick={() => { navigate("/turkiye-z-raporu", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/turkiye-z-raporu" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        Z Raporu
                      </span>
                    </button>
                  </li>
                  <li>
                    <button onClick={() => { navigate("/il-raporlari/1", { replace: true }) }} className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/il-raporlari/1" ? 'bg-gray-400' : 'bg-grey-light')}>
                      <span className="md:p-4 py-2 block hover:text-blue-500">
                        Geçmiş Raporlar
                      </span>
                    </button>
                  </li>
                </>
              ) : null}

              <li>
                <button
                  className={cs('hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center', location.pathname == "/sifre" ? 'bg-gray-400' : 'bg-grey-light')}
                  onClick={() => {
                    navigate("/sifre", { replace: true });

                  }}
                >
                  <span className="md:p-4 py-2 block hover:text-blue-500">
                    Şifre
                  </span>
                </button>
              </li>
              <li>
                <button
                  className="bg-grey-light hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex hover:text-blue-500 items-center "
                  onClick={() => {
                    localStorage.removeItem("token")
                    navigate("/giris", { replace: true });

                  }}
                >
                  <img src={logout} width="24" height="24" />
                  <span className="md:p-4 py-2 block hover:text-blue-500">
                    Çıkış Yap
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
