import axios from "../api/axios";

class KulaniciService {

    async sifreDegistir(token, password) {
        const headers = { headers: { "Content-Type": "application/json", Authorization: `Bearer ` + token } };
        const response = await axios.post("/kullanici/sifre/degistir", { password }, headers);
        return response.data;

    }
}


export default KulaniciService;