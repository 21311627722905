import React, { useState, useEffect } from "react";

function OrtakRapor({ setOrtakRaporlar, ortakRaporlar }) {
  const initialState = {
    muhabbetFasliSayisi: 0,
    muhabbetFasliKatilim: 0,
    muhabbetFasliMisafir: 0,
    aidatciSayisi: 0,
  };
  const [ortakRapor, setOrtakRapor] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrtakRapor({ ...ortakRaporlar, [name]: value });
  };

  useEffect(() => {
    setOrtakRaporlar(ortakRapor);
  }, [ortakRapor]);

  return (
    <>
      {/* 0.Kolon SKSF & Mali İşler Raporu */}
      <div className="flex-column w-30">
        <div className="flex-1 border h-full border-black">
          <h2 className="text-center text-bold px-2 leading-tight py-10 text-xl">
            Ortak <br /> Rapor
          </h2>
        </div>
      </div>

      {/* 1.KOLON Muhabbet Faslı */}
      <div className="flex-column w-full md:w-60">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full md:w-60 h-20 border border-black"
            readyonly
            value="Muhabbet Faslı"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="M.F. Sayısı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Katılımcı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Misafir"
          disabled
        />

        <div className="flex-1">
          {/* Muhabbet Faslı Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="muhabbetFasliSayisi"
            onChange={handleChange}
            min={0}
            value={
              ortakRaporlar.muhabbetFasliSayisi ??
              ortakRapor?.muhabbetFasliSayisi
            }
            placeholder="Sayı Gir"
          />
          {/* Katılımcı Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="muhabbetFasliKatilim"
            onChange={handleChange}
            min={0}
            value={
              ortakRaporlar.muhabbetFasliKatilim ??
              ortakRapor?.muhabbetFasliKatilim
            }
            placeholder="Sayı Gir"
          />
          {/* Misafir  */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="muhabbetFasliMisafir"
            onChange={handleChange}
            min={0}
            value={
              ortakRaporlar.muhabbetFasliMisafir ??
              ortakRapor?.muhabbetFasliMisafir
            }
            placeholder="Sayı Gir"
          />
        </div>
      </div>

      {/* 2.KOLON Aidatçı Sayısı*/}
      <div className="flex-column w-full md:w-60">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full md:w-60 h-20 border border-black"
            readyonly
            value="Aidatçı"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-full border border-black"
          readyonly
          value="Sayısı"
          disabled
        />

        <div className="flex-1">
          {/* Aidatçı  Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-full h-7 border border-black"
            name="aidatciSayisi"
            onChange={handleChange}
            min={0}
            value={ortakRaporlar.aidatciSayisi ?? ortakRapor?.aidatciSayisi}
            placeholder="Sayı Gir"
          />
        </div>
      </div>
    </>
  );
}

export default OrtakRapor;
