import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Navigate, useLocation } from "react-router-dom";
import SifreDegistir from '../components/SifreDegistir';
import resim1 from "../assets/img/resim1.jpeg"
function Anasayfa() {
    let location = useLocation();
    if(!localStorage.getItem("token")){
        return <Navigate to="/giris"/>
    }
    return (
        <>
            <Header />

            {!localStorage.getItem("token") ? <Navigate to="/giris" state={{ from: location }} /> : (<>
                <div className="container container-md  mx-auto ">
                    <img src={resim1} className=" object-fill  md:h-[700px] md:w-[1280px] "  />

                    {/* Şifre Değiştir
                | Raporlara Git
                | Dosya Arşivlerine git */}
                </div>
            </>)}


            <Footer />


        </>
    )
}

export default Anasayfa
