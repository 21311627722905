import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h2 class="mb-4 text-3xl tracking-tight font-extrabold lg:text-7xl text-primary-600  text-white">Yetkiniz yok</h2>

            <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Lütfen aradığınız sayfayı bulmak için anasayfaya dönünüz. </p>
            <button onClick={goBack} class="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-3xl px-5 py-2.5 text-center hover:text-blue-600 dark:focus:ring-primary-900 my-4">Bir önceki sayfaya dön</button>
        </div>   
    </div>
</section>
    )
}

export default Unauthorized