import { PDFDocument } from "pdf-lib";
import fontkit from '@pdf-lib/fontkit';
import { stringCevir, toplamTeskilatHesapla } from "./functions";

async function create4zRapor(rap, title, toplamTeskilatci) {
    const url = "/assets/files/4z_rapor_compressed.pdf";
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    // const urlFont = '/assets/fonts/calibri.ttf';
    const urlFont = '/assets/fonts/Poppins-Regular.ttf';
    const urlFontBold = '/assets/fonts/Poppins-Bold.ttf';
    const regularFontBytes = await fetch(urlFont).then(res => res.arrayBuffer());
    const boldFontBytes = await fetch(urlFontBold).then(res => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const font = await pdfDoc.embedFont(regularFontBytes);
    const bold = await pdfDoc.embedFont(boldFontBytes);
    const page1 = pdfDoc.getPages()[0], page2 = pdfDoc.getPages()[1], page3 = pdfDoc.getPages()[2]
        , page4 = pdfDoc.getPages()[3], page5 = pdfDoc.getPages()[4], page6 = pdfDoc.getPages()[5]



    const h1 = page1.getSize().height, h2 = page2.getSize().height, h3 = page3.getSize().height
        , h4 = page4.getSize().height, h5 = page5.getSize().height, h6 = page6.getSize().height;


    const drawText = (prefSize, infoToWrite, page, xCoord, yCoord, font, h) => {
        let infoTo = stringCevir(infoToWrite)
        let fieldWidth = 100;
        let lastWidth = font.widthOfTextAtSize(infoTo, prefSize);
        let leftMargin = (fieldWidth - lastWidth) / 2;
        return page.drawText(stringCevir(infoToWrite), {
            x: xCoord + leftMargin,
            y: h - yCoord,
            size: prefSize,
            font,
        })

    }
    // 1.Sayfa
    // 1.sayfa ADANA - Üniversite Başkanı
    drawText(4, rap[0]?.kunye?.baskan ?? "", page1, 48.5, 62, font, h1)
    // 1.sayfa ADANA - Üniversite Başkanı Telefon No:
    drawText(5, rap[0]?.kunye?.telefon ?? " ", page1, 46.5, 69, font, h1)
    // Adana - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[0]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 69, bold, h1)
    //Adana - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[0]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 69, bold, h1)
    //Adana - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[0]?.kunye?.fakulteSayisi ?? "0", page1, 243, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 69, bold, h1)
    //Adana Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[0]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 69, bold, h1)
    //Adana MYO Sayı-Bşk - Kom
    drawText(5, rap[0]?.kunye?.myoSayisi ?? "0", page1, 400, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 69, bold, h1)
    //Adana Bölüm Sayı-Bşk - Kom
    drawText(5, rap[0]?.kunye?.bolumSayisi ?? "0", page1, 480, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 69, bold, h1)
    //Adana Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 69, bold, h1)
    //Adana Birebir İlgilenen-İlgilenilen
    drawText(5, rap[0]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 69, bold, h1)
    drawText(5, rap[0]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 69, bold, h1)
    // 1.sayfa ADANA - Bölge Başkanı
    drawText(4, rap[0]?.kunye?.bolgeBsk ?? "", page1, 46.5, 88.5, font, h1)
    // 1.sayfa Adana - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[0]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 89, bold, h1)
    // 1.sayfa Adana - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[0]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 89, bold, h1)
    // 1.sayfa Adana - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 89, bold, h1)
    // 1.sayfa Adana - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 89, bold, h1)
    // 1.sayfa Adana - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 89, bold, h1)
    // 1.sayfa Adana - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 89, bold, h1)
    // 1.sayfa Adana - Özel Yurt  Sayi-Bşk
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 89, bold, h1)
    drawText(5, rap[0]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 89, bold, h1)
    // 1.sayfa Adana - Toplam Öğr -Erkek Öğr
    drawText(5, rap[0]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 89, bold, h1)
    drawText(5, rap[0]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 89, bold, h1)
    // Adana - Teşkilatçı Sayısı
    drawText(7, rap[0]?.toplamTeskilatciSayisi ?? "0", page1, 725, 83, bold, h1)

    /////////////////////////////////////////////////////////

    // 1.sayfa Adıyaman - Üniversite Başkanı
    drawText(4, rap[1]?.kunye?.baskan ?? "", page1, 46.5, 101, font, h1)
    // 1.sayfa Adıyaman - Üniversite Başkanı Telefonu
    drawText(5, rap[1]?.kunye?.telefon ?? "", page1, 46.5, 107.5, font, h1)
    // Adıyaman - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[1]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 107.5, bold, h1)
    //Adıyaman - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[1]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 107.5, bold, h1)
    //Adıyaman - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[1]?.kunye?.fakulteSayisi ?? "0", page1, 243, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 107.5, bold, h1)
    //Adıyaman Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[1]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 107.5, bold, h1)
    //Adıyaman MYO Sayı-Bşk - Kom
    drawText(5, rap[1]?.kunye?.myoSayisi ?? "0", page1, 400, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 107.5, bold, h1)
    //Adıyaman Bölüm Sayı-Bşk - Kom
    drawText(5, rap[1]?.kunye?.bolumSayisi ?? "0", page1, 480, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 107.5, bold, h1)
    //Adıyaman Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 107.5, bold, h1)
    //Adıyaman Birebir İlgilenen-İlgilenilen
    drawText(5, rap[1]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 107.5, bold, h1)
    drawText(5, rap[1]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 107.5, bold, h1)
    // 1.sayfa Adıyaman - Bölge Başkanı
    drawText(4, rap[1]?.kunye?.bolgeBsk ?? "", page1, 46.5, 127, font, h1)
    // 1.sayfa Adıyaman - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[1]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 127, bold, h1)
    // 1.sayfa Adıyaman - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[1]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 127, bold, h1)
    // 1.sayfa Adıyaman - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 127, bold, h1)
    // 1.sayfa Adıyaman - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 127, bold, h1)
    // 1.sayfa Adıyaman - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 127, bold, h1)
    // 1.sayfa Adıyaman - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 127, bold, h1)
    // 1.sayfa Adıyaman - Özel Yurt  Sayi-Bşk
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 127, bold, h1)
    drawText(5, rap[1]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 127, bold, h1)
    // 1.sayfa Adıyaman - Toplam Öğr -Erkek Öğr
    drawText(5, rap[1]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 127, bold, h1)
    drawText(5, rap[1]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 127, bold, h1)
    // Adıyaman - Teşkilatçı Sayısı
    drawText(7, rap[0]?.toplamTeskilatciSayisi ?? "0", page1, 725, 121, bold, h1)


    // 1.sayfa Afyon - Üniversite Başkanı
    drawText(4, rap[2]?.kunye?.baskan ?? "", page1, 46.5, 140, font, h1)
    // 1.sayfa Afyon - Üniversite Başkanı Telefon
    drawText(5, rap[2]?.kunye?.telefon ?? "", page1, 46.5, 146.5, font, h1)
    // Afyon - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[2]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 146.5, bold, h1)
    //Afyon - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[2]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 146.5, bold, h1)
    //Afyon - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[2]?.kunye?.fakulteSayisi ?? "0", page1, 243, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 146.5, bold, h1)
    //Afyon Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[2]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 146.5, bold, h1)
    //Afyon MYO Sayı-Bşk - Kom
    drawText(5, rap[2]?.kunye?.myoSayisi ?? "0", page1, 400, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 146.5, bold, h1)
    //Afyon Bölüm Sayı-Bşk - Kom
    drawText(5, rap[2]?.kunye?.bolumSayisi ?? "0", page1, 480, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 146.5, bold, h1)
    //Afyon Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 146.5, bold, h1)
    //Afyon Birebir İlgilenen-İlgilenilen
    drawText(5, rap[2]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 146.5, bold, h1)
    drawText(5, rap[2]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 146.5, bold, h1)
    // 1.sayfa Afyon - Bölge Başkanı
    drawText(4, rap[2]?.kunye?.bolgeBsk ?? "", page1, 46.5, 166, font, h1)
    // 1.sayfa Afyon - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[2]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 166, bold, h1)
    // 1.sayfa Afyon - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[2]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 166, bold, h1)
    // 1.sayfa Afyon - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 166, bold, h1)
    // 1.sayfa Afyon - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 166, bold, h1)
    // 1.sayfa Afyon - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 166, bold, h1)
    // 1.sayfa Afyon - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 166, bold, h1)
    // 1.sayfa Afyon - Özel Yurt  Sayi-Bşk
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 166, bold, h1)
    drawText(5, rap[2]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 166, bold, h1)
    // 1.sayfa Afyon - Toplam Öğr -Erkek Öğr
    drawText(5, rap[2]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 166, bold, h1)
    drawText(5, rap[2]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 166, bold, h1)
    // Afyon - Teşkilatçı Sayısı
    drawText(7, rap[2]?.toplamTeskilatciSayisi ?? "0", page1, 725, 160, bold, h1)


    // 1.sayfa Ağrı - Üniversite Başkanı
    drawText(4, rap[3]?.kunye?.baskan ?? "", page1, 46.5, 179, font, h1)
    // 1.sayfa Ağrı - Üniversite Başkanı Telefon
    drawText(5, rap[3]?.kunye?.telefon ?? "", page1, 46.5, 185.5, font, h1)
    // Ağrı - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[3]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 185.5, bold, h1)
    //Ağrı - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[3]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 185.5, bold, h1)
    //Ağrı - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[3]?.kunye?.fakulteSayisi ?? "0", page1, 243, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 185.5, bold, h1)
    //Ağrı Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[3]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 185.5, bold, h1)
    //Ağrı MYO Sayı-Bşk - Kom
    drawText(5, rap[3]?.kunye?.myoSayisi ?? "0", page1, 400, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 185.5, bold, h1)
    //Ağrı Bölüm Sayı-Bşk - Kom
    drawText(5, rap[3]?.kunye?.bolumSayisi ?? "0", page1, 480, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 185.5, bold, h1)
    //Ağrı Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 185.5, bold, h1)
    //Ağrı Birebir İlgilenen-İlgilenilen
    drawText(5, rap[3]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 185.5, bold, h1)
    drawText(5, rap[3]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 185.5, bold, h1)
    // 1.sayfa Ağrı - Bölge Başkanı
    drawText(4, rap[3]?.kunye?.bolgeBsk ?? "", page1, 46.5, 204, font, h1)
    // 1.sayfa Ağrı - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[3]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 204, bold, h1)
    // 1.sayfa Ağrı - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[3]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 204, bold, h1)
    // 1.sayfa Ağrı - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 204, bold, h1)
    // 1.sayfa Ağrı - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 204, bold, h1)
    // 1.sayfa Ağrı - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 204, bold, h1)
    // 1.sayfa Ağrı - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 204, bold, h1)
    // 1.sayfa Ağrı - Özel Yurt  Sayi-Bşk
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 204, bold, h1)
    drawText(5, rap[3]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 204, bold, h1)
    // 1.sayfa Ağrı - Toplam Öğr -Erkek Öğr
    drawText(5, rap[3]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 204, bold, h1)
    drawText(5, rap[3]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 204, bold, h1)
    // Ağrı - Teşkilatçı Sayısı
    drawText(7, rap[3]?.toplamTeskilatciSayisi ?? "0", page1, 725, 198, bold, h1)

    // 1.sayfa Amasya - Üniversite Başkanı
    drawText(4, rap[4]?.kunye?.baskan ?? "", page1, 46.5, 217, font, h1)
    // 1.sayfa Amasya - Üniversite Başkanı Telefon
    drawText(5, rap[4]?.kunye?.telefon ?? "", page1, 46.5, 223.5, font, h1)
    // Amasya - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[4]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 223.5, bold, h1)
    //Amasya - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[4]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 223.5, bold, h1)
    //Amasya - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[4]?.kunye?.fakulteSayisi ?? "0", page1, 243, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 223.5, bold, h1)
    //Amasya Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[4]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 223.5, bold, h1)
    //Amasya MYO Sayı-Bşk - Kom
    drawText(5, rap[4]?.kunye?.myoSayisi ?? "0", page1, 400, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 223.5, bold, h1)
    //Amasya Bölüm Sayı-Bşk - Kom
    drawText(5, rap[4]?.kunye?.bolumSayisi ?? "0", page1, 480, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 223.5, bold, h1)
    //Amasya Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 223.5, bold, h1)
    //Amasya Birebir İlgilenen-İlgilenilen
    drawText(5, rap[4]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 223.5, bold, h1)
    drawText(5, rap[4]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 223.5, bold, h1)
    // 1.sayfa Amasya - Bölge Başkanı
    drawText(4, rap[4]?.kunye?.bolgeBsk ?? "", page1, 46.5, 243, font, h1)
    // 1.sayfa Amasya - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[4]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 243, bold, h1)
    // 1.sayfa Amasya - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[4]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 243, bold, h1)
    // 1.sayfa Amasya - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 243, bold, h1)
    // 1.sayfa Amasya - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 243, bold, h1)
    // 1.sayfa Amasya - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 243, bold, h1)
    // 1.sayfa Amasya - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 243, bold, h1)
    // 1.sayfa Amasya - Özel Yurt  Sayi-Bşk
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 243, bold, h1)
    drawText(5, rap[4]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 243, bold, h1)
    // 1.sayfa Amasya - Toplam Öğr -Erkek Öğr
    drawText(5, rap[4]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 243, bold, h1)
    drawText(5, rap[4]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 243, bold, h1)
    // Amasya - Teşkilatçı Sayısı
    drawText(7, rap[4]?.toplamTeskilatciSayisi ?? "0", page1, 725, 237, bold, h1)


    // 1.sayfa Ankara - Üniversite Başkanı
    drawText(4, rap[5]?.kunye?.baskan ?? "", page1, 46.5, 255, font, h1)
    // 1.sayfa Ankara - Üniversite Başkanı Telefon
    drawText(5, rap[5]?.kunye?.telefon ?? "", page1, 46.5, 262.5, font, h1)
    // Ankara - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[5]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 262.5, bold, h1)
    //Ankara - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[5]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 262.5, bold, h1)
    //Ankara - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[5]?.kunye?.fakulteSayisi ?? "0", page1, 243, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 262.5, bold, h1)
    //Ankara Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[5]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 262.5, bold, h1)
    //Ankara MYO Sayı-Bşk - Kom
    drawText(5, rap[5]?.kunye?.myoSayisi ?? "0", page1, 400, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 262.5, bold, h1)
    //Ankara Bölüm Sayı-Bşk - Kom
    drawText(5, rap[5]?.kunye?.bolumSayisi ?? "0", page1, 480, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 262.5, bold, h1)
    //Ankara Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 262.5, bold, h1)
    //Ankara Birebir İlgilenen-İlgilenilen
    drawText(5, rap[5]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 262.5, bold, h1)
    drawText(5, rap[5]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 262.5, bold, h1)
    // 1.sayfa Ankara - Bölge Başkanı
    drawText(4, rap[5]?.kunye?.bolgeBsk ?? "", page1, 46.5, 282, font, h1)
    // 1.sayfa Ankara - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[5]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 282, bold, h1)
    // 1.sayfa Ankara - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[5]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 282, bold, h1)
    // 1.sayfa Ankara - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 282, bold, h1)
    // 1.sayfa Ankara - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 282, bold, h1)
    // 1.sayfa Ankara - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 282, bold, h1)
    // 1.sayfa Ankara - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 282, bold, h1)
    // 1.sayfa Ankara - Özel Yurt  Sayi-Bşk
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 282, bold, h1)
    drawText(5, rap[5]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 282, bold, h1)
    // 1.sayfa Ankara - Toplam Öğr -Erkek Öğr
    drawText(5, rap[5]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 282, bold, h1)
    drawText(5, rap[5]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 282, bold, h1)
    // Ankara - Teşkilatçı Sayısı
    drawText(7, rap[5]?.toplamTeskilatciSayisi ?? "0", page1, 725, 276, bold, h1)


    // 1.sayfa Antalya - Üniversite Başkanı
    drawText(4, rap[6]?.kunye?.baskan ?? "", page1, 46.5, 294, font, h1)
    // 1.sayfa Antalya - Üniversite Başkanı Telefon
    drawText(5, rap[6]?.kunye?.telefon ?? "", page1, 46.5, 301.5, font, h1)
    // Antalya - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[6]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 301.5, bold, h1)
    //Antalya - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[6]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 301.5, bold, h1)
    //Antalya - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[6]?.kunye?.fakulteSayisi ?? "0", page1, 243, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 301.5, bold, h1)
    //Antalya Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[6]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 301.5, bold, h1)
    //Antalya MYO Sayı-Bşk - Kom
    drawText(5, rap[6]?.kunye?.myoSayisi ?? "0", page1, 400, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 301.5, bold, h1)
    //Antalya Bölüm Sayı-Bşk - Kom
    drawText(5, rap[6]?.kunye?.bolumSayisi ?? "0", page1, 480, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 301.5, bold, h1)
    //Antalya Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 301.5, bold, h1)
    //Antalya Birebir İlgilenen-İlgilenilen
    drawText(5, rap[6]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 301.5, bold, h1)
    drawText(5, rap[6]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 301.5, bold, h1)
    // 1.sayfa Antalya - Bölge Başkanı
    drawText(4, rap[6]?.kunye?.bolgeBsk ?? "", page1, 46.5, 320, font, h1)
    // 1.sayfa Antalya - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[6]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 320, bold, h1)
    // 1.sayfa Antalya - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[6]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 320, bold, h1)
    // 1.sayfa Antalya - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 320, bold, h1)
    // 1.sayfa Antalya - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 320, bold, h1)
    // 1.sayfa Antalya - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 320, bold, h1)
    // 1.sayfa Antalya - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 320, bold, h1)
    // 1.sayfa Antalya - Özel Yurt  Sayi-Bşk
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 320, bold, h1)
    drawText(5, rap[6]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 320, bold, h1)
    // 1.sayfa Antalya - Toplam Öğr -Erkek Öğr
    drawText(5, rap[6]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 320, bold, h1)
    drawText(5, rap[6]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 320, bold, h1)
    // Antalya - Teşkilatçı Sayısı
    drawText(7, rap[6]?.toplamTeskilatciSayisi ?? "0", page1, 725, 314, bold, h1)

    // 1.sayfa Artvin - Üniversite Başkanı
    drawText(4, rap[7]?.kunye?.baskan ?? "", page1, 46.5, 333, font, h1)
    // 1.sayfa Artvin - Üniversite Başkanı Telefon
    drawText(5, rap[7]?.kunye?.telefon ?? "", page1, 46.5, 339.5, font, h1)
    // Artvin - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[7]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 339.5, bold, h1)
    //Artvin - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[7]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 339.5, bold, h1)
    //Artvin - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[7]?.kunye?.fakulteSayisi ?? "0", page1, 243, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 339.5, bold, h1)
    //Artvin Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[7]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 339.5, bold, h1)
    //Artvin MYO Sayı-Bşk - Kom
    drawText(5, rap[7]?.kunye?.myoSayisi ?? "0", page1, 400, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 339.5, bold, h1)
    //Artvin Bölüm Sayı-Bşk - Kom
    drawText(5, rap[7]?.kunye?.bolumSayisi ?? "0", page1, 480, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 339.5, bold, h1)
    //Artvin Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 339.5, bold, h1)
    //Artvin Birebir İlgilenen-İlgilenilen
    drawText(5, rap[7]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 339.5, bold, h1)
    drawText(5, rap[7]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 339.5, bold, h1)
    // 1.sayfa Artvin - Bölge Başkanı
    drawText(4, rap[7]?.kunye?.bolgeBsk ?? "", page1, 46.5, 358.5, font, h1)
    // 1.sayfa Artvin - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[7]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 358.5, bold, h1)
    // 1.sayfa Artvin - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[7]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 358.5, bold, h1)
    // 1.sayfa Artvin - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 358.5, bold, h1)
    // 1.sayfa Artvin - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 358.5, bold, h1)
    // 1.sayfa Artvin - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 358.5, bold, h1)
    // 1.sayfa Artvin - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 358.5, bold, h1)
    // 1.sayfa Artvin - Özel Yurt  Sayi-Bşk
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 358.5, bold, h1)
    drawText(5, rap[7]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 358.5, bold, h1)
    // 1.sayfa Artvin - Toplam Öğr -Erkek Öğr
    drawText(5, rap[7]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 358.5, bold, h1)
    drawText(5, rap[7]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 358.5, bold, h1)
    // Artvin - Teşkilatçı Sayısı
    drawText(7, rap[7]?.toplamTeskilatciSayisi ?? "0", page1, 725, 352.5, bold, h1)



    // 1.sayfa Aydın - Üniversite Başkanı
    drawText(4, rap[8]?.kunye?.baskan ?? "", page1, 46.5, 371, font, h1)
    // 1.sayfa Aydın - Üniversite Başkanı Telefon
    drawText(5, rap[8]?.kunye?.telefon ?? "", page1, 46.5, 377.5, font, h1)
    // Aydın - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[8]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 377.5, bold, h1)
    //Aydın - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[8]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 377.5, bold, h1)
    //Aydın - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[8]?.kunye?.fakulteSayisi ?? "0", page1, 243, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 377.5, bold, h1)
    //Aydın Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[8]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 377.5, bold, h1)
    //Aydın MYO Sayı-Bşk - Kom
    drawText(5, rap[8]?.kunye?.myoSayisi ?? "0", page1, 400, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 377.5, bold, h1)
    //Aydın Bölüm Sayı-Bşk - Kom
    drawText(5, rap[8]?.kunye?.bolumSayisi ?? "0", page1, 480, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 377.5, bold, h1)
    //Aydın Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 377.5, bold, h1)
    //Aydın Birebir İlgilenen-İlgilenilen
    drawText(5, rap[8]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 377.5, bold, h1)
    drawText(5, rap[8]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 377.5, bold, h1)
    // 1.sayfa Aydın - Bölge Başkanı
    drawText(4, rap[8]?.kunye?.bolgeBsk ?? "", page1, 46.5, 397, font, h1)
    // 1.sayfa Aydın - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[8]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 397, bold, h1)
    // 1.sayfa Aydın - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[8]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 397, bold, h1)
    // 1.sayfa Aydın - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 397, bold, h1)
    // 1.sayfa Aydın - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 397, bold, h1)
    // 1.sayfa Aydın - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 397, bold, h1)
    // 1.sayfa Aydın - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 397, bold, h1)
    // 1.sayfa Aydın - Özel Yurt  Sayi-Bşk
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 397, bold, h1)
    drawText(5, rap[8]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 397, bold, h1)
    // 1.sayfa Aydın - Toplam Öğr -Erkek Öğr
    drawText(5, rap[8]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 397, bold, h1)
    drawText(5, rap[8]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 397, bold, h1)
    // Aydın - Teşkilatçı Sayısı
    drawText(7, rap[8]?.toplamTeskilatciSayisi ?? "0", page1, 725, 391, bold, h1)



    // 1.sayfa Balıkesir - Üniversite Başkanı
    drawText(4, rap[9]?.kunye?.baskan ?? "", page1, 46.5, 409, font, h1)
    // 1.sayfa Balıkesir - Üniversite Başkanı Telefon
    drawText(5, rap[9]?.kunye?.telefon ?? "", page1, 46.5, 416.5, font, h1)
    // Balıkesir - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[9]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 416.5, bold, h1)
    //Balıkesir - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[9]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 416.5, bold, h1)
    //Balıkesir - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[9]?.kunye?.fakulteSayisi ?? "0", page1, 243, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 416.5, bold, h1)
    //Balıkesir Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[9]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 416.5, bold, h1)
    //Balıkesir MYO Sayı-Bşk - Kom
    drawText(5, rap[9]?.kunye?.myoSayisi ?? "0", page1, 400, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 416.5, bold, h1)
    //Balıkesir Bölüm Sayı-Bşk - Kom
    drawText(5, rap[9]?.kunye?.bolumSayisi ?? "0", page1, 480, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 416.5, bold, h1)
    //Balıkesir Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 416.5, bold, h1)
    //Balıkesir Birebir İlgilenen-İlgilenilen
    drawText(5, rap[9]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 416.5, bold, h1)
    drawText(5, rap[9]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 416.5, bold, h1)
    // 1.sayfa Balıkesir - Bölge Başkanı
    drawText(4, rap[9]?.kunye?.bolgeBsk ?? "", page1, 46.5, 436, font, h1)
    // 1.sayfa Balıkesir - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[9]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 436, bold, h1)
    // 1.sayfa Balıkesir - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[9]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 436, bold, h1)
    // 1.sayfa Balıkesir - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 436, bold, h1)
    // 1.sayfa Balıkesir - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 436, bold, h1)
    // 1.sayfa Balıkesir - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 436, bold, h1)
    // 1.sayfa Balıkesir - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 436, bold, h1)
    // 1.sayfa Balıkesir - Özel Yurt  Sayi-Bşk
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 436, bold, h1)
    drawText(5, rap[9]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 436, bold, h1)
    // 1.sayfa Balıkesir - Toplam Öğr -Erkek Öğr
    drawText(5, rap[9]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 436, bold, h1)
    drawText(5, rap[9]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 436, bold, h1)
    // Balıkesir - Teşkilatçı Sayısı
    drawText(7, rap[9]?.toplamTeskilatciSayisi ?? "0", page1, 725, 430, bold, h1)



    // 1.sayfa Bilecik - Üniversite Başkanı
    drawText(4, rap[10]?.kunye?.baskan ?? "", page1, 46.5, 449, font, h1)
    // 1.sayfa Bilecik - Üniversite Başkanı Telefon
    drawText(5, rap[10]?.kunye?.telefon ?? "", page1, 46.5, 455, font, h1)
    // Bilecik - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[10]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 455, bold, h1)
    //Bilecik - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[10]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 455, bold, h1)
    //Bilecik - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[10]?.kunye?.fakulteSayisi ?? "0", page1, 243, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 455, bold, h1)
    //Bilecik Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[10]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 455, bold, h1)
    //Bilecik MYO Sayı-Bşk - Kom
    drawText(5, rap[10]?.kunye?.myoSayisi ?? "0", page1, 400, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 455, bold, h1)
    //Bilecik Bölüm Sayı-Bşk - Kom
    drawText(5, rap[10]?.kunye?.bolumSayisi ?? "0", page1, 480, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 455, bold, h1)
    //Bilecik Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 455, bold, h1)
    //Bilecik Birebir İlgilenen-İlgilenilen
    drawText(5, rap[10]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 455, bold, h1)
    drawText(5, rap[10]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 455, bold, h1)
    // 1.sayfa Bilecik - Bölge Başkanı
    drawText(4, rap[10]?.kunye?.bolgeBsk ?? "", page1, 46.5, 474, font, h1)
    // 1.sayfa Bilecik - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[10]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 474, bold, h1)
    // 1.sayfa Bilecik - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[10]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 474, bold, h1)
    // 1.sayfa Bilecik - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 474, bold, h1)
    // 1.sayfa Bilecik - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 474, bold, h1)
    // 1.sayfa Bilecik - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 474, bold, h1)
    // 1.sayfa Bilecik - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 474, bold, h1)
    // 1.sayfa Bilecik - Özel Yurt  Sayi-Bşk
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 474, bold, h1)
    drawText(5, rap[10]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 474, bold, h1)
    // 1.sayfa Bilecik - Toplam Öğr -Erkek Öğr
    drawText(5, rap[10]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 474, bold, h1)
    drawText(5, rap[10]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 474, bold, h1)
    // Bilecik - Teşkilatçı Sayısı
    drawText(7, rap[10]?.toplamTeskilatciSayisi ?? "0", page1, 725, 468, bold, h1)


    // 1.sayfa Bingöl - Üniversite Başkanı
    drawText(4, rap[11]?.kunye?.baskan ?? "", page1, 46.5, 487, font, h1)
    // 1.sayfa Bingöl - Üniversite Başkanı Telefon
    drawText(5, rap[11]?.kunye?.telefon ?? "", page1, 46.5, 493.5, font, h1)
    // Bingöl - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[11]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 493.5, bold, h1)
    //Bingöl - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[11]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 493.5, bold, h1)
    //Bingöl - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[11]?.kunye?.fakulteSayisi ?? "0", page1, 243, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 493.5, bold, h1)
    //Bingöl Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[11]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 493.5, bold, h1)
    //Bingöl MYO Sayı-Bşk - Kom
    drawText(5, rap[11]?.kunye?.myoSayisi ?? "0", page1, 400, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 493.5, bold, h1)
    //Bingöl Bölüm Sayı-Bşk - Kom
    drawText(5, rap[11]?.kunye?.bolumSayisi ?? "0", page1, 480, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 493.5, bold, h1)
    //Bingöl Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 493.5, bold, h1)
    //Bingöl Birebir İlgilenen-İlgilenilen
    drawText(5, rap[11]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 493.5, bold, h1)
    drawText(5, rap[11]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 493.5, bold, h1)
    // 1.sayfa Bingöl - Bölge Başkanı
    drawText(4, rap[11]?.kunye?.bolgeBsk ?? "", page1, 46.5, 512.5, font, h1)
    // 1.sayfa Bingöl - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[11]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 512.5, bold, h1)
    // 1.sayfa Bingöl - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[11]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 512.5, bold, h1)
    // 1.sayfa Bingöl - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 512.5, bold, h1)
    // 1.sayfa Bingöl - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 512.5, bold, h1)
    // 1.sayfa Bingöl - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 512.5, bold, h1)
    // 1.sayfa Bingöl - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 512.5, bold, h1)
    // 1.sayfa Bingöl - Özel Yurt  Sayi-Bşk
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 512.5, bold, h1)
    drawText(5, rap[11]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 512.5, bold, h1)
    // 1.sayfa Bingöl - Toplam Öğr -Erkek Öğr
    drawText(5, rap[11]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 512.5, bold, h1)
    drawText(5, rap[11]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 512.5, bold, h1)
    // Bingöl - Teşkilatçı Sayısı
    drawText(7, rap[11]?.toplamTeskilatciSayisi ?? "0", page1, 725, 506.5, bold, h1)


    // 1.sayfa Bitlis - Üniversite Başkanı
    drawText(4, rap[12]?.kunye?.baskan ?? "", page1, 46.5, 525, font, h1)
    // 1.sayfa Bitlis - Üniversite Başkanı Telefon
    drawText(5, rap[12]?.kunye?.telefon ?? "", page1, 46.5, 531.5, font, h1)
    // Bitlis - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[12]?.rapor?.icmal?.subeUniBsk ?? "0", page1, 97, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.subeUniKom ?? "0", page1, 122, 531.5, bold, h1)
    //Bitlis - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[12]?.kunye?.temsilciUniSayisi ?? "0", page1, 150, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.temsilciUniBsk ?? "0", page1, 178, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.temsilciUniKom ?? "0", page1, 210, 531.5, bold, h1)
    //Bitlis - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[12]?.kunye?.fakulteSayisi ?? "0", page1, 243, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.fakulteBsk ?? "0", page1, 265, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.fakulteKom ?? "0", page1, 295, 531.5, bold, h1)
    //Bitlis Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[12]?.kunye?.yuksekokulSayisi ?? "0", page1, 323, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.yuksekokulBsk ?? "0", page1, 348, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.yuksekokulKom ?? "0", page1, 373, 531.5, bold, h1)
    //Bitlis MYO Sayı-Bşk - Kom
    drawText(5, rap[12]?.kunye?.myoSayisi ?? "0", page1, 400, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.myoBsk ?? "0", page1, 428, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.myoKom ?? "0", page1, 453, 531.5, bold, h1)
    //Bitlis Bölüm Sayı-Bşk - Kom
    drawText(5, rap[12]?.kunye?.bolumSayisi ?? "0", page1, 480, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.bolumBsk ?? "0", page1, 505, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.bolumKom ?? "0", page1, 530, 531.5, bold, h1)
    //Bitlis Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page1, 558, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page1, 580, 531.5, bold, h1)
    //Bitlis Birebir İlgilenen-İlgilenilen
    drawText(5, rap[12]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page1, 612, 531.5, bold, h1)
    drawText(5, rap[12]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page1, 655, 531.5, bold, h1)
    // 1.sayfa Bitlis - Bölge Başkanı
    drawText(4, rap[12]?.kunye?.bolgeBsk ?? "", page1, 46.5, 551, font, h1)
    // 1.sayfa Bitlis - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[12]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page1, 97, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page1, 122, 551, bold, h1)
    // 1.sayfa Bitlis - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[12]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page1, 150, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page1, 178, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page1, 210, 551, bold, h1)
    // 1.sayfa Bitlis - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page1, 243, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page1, 265, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page1, 295, 551, bold, h1)
    // 1.sayfa Bitlis - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page1, 323, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page1, 348, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page1, 373, 551, bold, h1)
    // 1.sayfa Bitlis - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page1, 400, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page1, 428, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page1, 453, 551, bold, h1)
    // 1.sayfa Bitlis - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page1, 480, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page1, 505, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page1, 530, 551, bold, h1)
    // 1.sayfa Bitlis - Özel Yurt  Sayi-Bşk
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page1, 558, 551, bold, h1)
    drawText(5, rap[12]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page1, 580, 551, bold, h1)
    // 1.sayfa Bitlis - Toplam Öğr -Erkek Öğr
    drawText(5, rap[12]?.kunye?.toplamOgrSayisi ?? "0", page1, 612, 551, bold, h1)
    drawText(5, rap[12]?.kunye?.erkekOgrSayisi ?? "0", page1, 655, 551, bold, h1)
    // Bitlis - Teşkilatçı Sayısı
    drawText(7, rap[12]?.toplamTeskilatciSayisi ?? "0", page1, 725, 545, bold, h1)


    //2.SAYFA
    //Bolu
    //Bolu Üniversite Başkanı
    drawText(4, rap[13]?.kunye?.baskan ?? "", page2, 48.5, 42.5, font, h2)
    //Bolu Üniversite Başkanı
    drawText(5, rap[13]?.kunye?.telefon ?? "", page2, 46.5, 49.5, font, h2)

    // Bolu - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[13]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 49.5, bold, h1)
    //Bolu - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[13]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 49.5, bold, h1)
    //Bolu - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[13]?.kunye?.fakulteSayisi ?? "0", page2, 243, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 49.5, bold, h1)
    //Bolu Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[13]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 49.5, bold, h1)
    //Bolu MYO Sayı-Bşk - Kom
    drawText(5, rap[13]?.kunye?.myoSayisi ?? "0", page2, 400, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 49.5, bold, h1)
    //Bolu Bölüm Sayı-Bşk - Kom
    drawText(5, rap[13]?.kunye?.bolumSayisi ?? "0", page2, 480, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 49.5, bold, h1)
    //Bolu Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 49.5, bold, h1)
    //Bolu Birebir İlgilenen-İlgilenilen
    drawText(5, rap[13]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 49.5, bold, h1)
    drawText(5, rap[13]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 49.5, bold, h1)
    // 2.sayfa Bolu - Bölge Başkanı
    drawText(4, rap[13]?.kunye?.bolgeBsk ?? "", page2, 46.5, 68.5, font, h2)
    // 2.sayfa Bolu - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[13]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 68.5, bold, h1)
    // 2.sayfa Bolu - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[13]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 68.5, bold, h1)
    // 2.sayfa Bolu - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 68.5, bold, h1)
    // 2.sayfa Bolu - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 68.5, bold, h1)
    // 2.sayfa Bolu - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 68.5, bold, h1)
    // 2.sayfa Bolu - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 68.5, bold, h1)
    // 2.sayfa Bolu - Özel Yurt  Sayi-Bşk
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 68.5, bold, h1)
    drawText(5, rap[13]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 68.5, bold, h1)
    // 2.sayfa Bolu - Toplam Öğr -Erkek Öğr
    drawText(5, rap[13]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 68.5, bold, h1)
    drawText(5, rap[13]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 68.5, bold, h1)
    // Bolu - Teşkilatçı Sayısı
    drawText(7, rap[13]?.toplamTeskilatciSayisi ?? "0", page2, 725, 62.5, bold, h1)


    //Burdur 
    //Burdur Üniversite Başkanı
    drawText(4, rap[14]?.kunye?.baskan ?? "", page2, 48.5, 81, font, h2)
    //Burdur Üniversite Başkanı
    drawText(5, rap[14]?.kunye?.telefon ?? "", page2, 46.5, 88, font, h2)

    // Burdur - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[14]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 88, bold, h1)
    //Burdur - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[14]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 88, bold, h1)
    //Burdur - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[14]?.kunye?.fakulteSayisi ?? "0", page2, 243, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 88, bold, h1)
    //Burdur Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[14]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 88, bold, h1)
    //Burdur MYO Sayı-Bşk - Kom
    drawText(5, rap[14]?.kunye?.myoSayisi ?? "0", page2, 400, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 88, bold, h1)
    //Burdur Bölüm Sayı-Bşk - Kom
    drawText(5, rap[14]?.kunye?.bolumSayisi ?? "0", page2, 480, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 88, bold, h1)
    //Burdur Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 88, bold, h1)
    //Burdur Birebir İlgilenen-İlgilenilen
    drawText(5, rap[14]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 88, bold, h1)
    drawText(5, rap[14]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 88, bold, h1)
    // 2.sayfa Burdur - Bölge Başkanı
    drawText(4, rap[14]?.kunye?.bolgeBsk ?? "", page2, 46.5, 107, font, h2)
    // 2.sayfa Burdur - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[14]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 107, bold, h1)
    // 2.sayfa Burdur - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[14]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 107, bold, h1)
    // 2.sayfa Burdur - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 107, bold, h1)
    // 2.sayfa Burdur - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 107, bold, h1)
    // 2.sayfa Burdur - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 107, bold, h1)
    // 2.sayfa Burdur - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 107, bold, h1)
    // 2.sayfa Burdur - Özel Yurt  Sayi-Bşk
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 107, bold, h1)
    drawText(5, rap[14]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 107, bold, h1)
    // 2.sayfa Burdur - Toplam Öğr -Erkek Öğr
    drawText(5, rap[14]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 107, bold, h1)
    drawText(5, rap[14]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 107, bold, h1)
    // Burdur - Teşkilatçı Sayısı
    drawText(7, rap[14]?.toplamTeskilatciSayisi ?? "0", page2, 725, 101, bold, h1)



    //Bursa
    //Bursa Üniversite Başkanı
    drawText(4, rap[15]?.kunye?.baskan ?? "", page2, 48.5, 120, font, h2)
    //Bursa Üniversite Başkanı
    drawText(5, rap[15]?.kunye?.telefon ?? "", page2, 46.5, 126, font, h2)

    // Bursa - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[15]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 126, bold, h1)
    //Bursa - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[15]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 126, bold, h1)
    //Bursa - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[15]?.kunye?.fakulteSayisi ?? "0", page2, 243, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 126, bold, h1)
    //Bursa Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[15]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 126, bold, h1)
    //Bursa MYO Sayı-Bşk - Kom
    drawText(5, rap[15]?.kunye?.myoSayisi ?? "0", page2, 400, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 126, bold, h1)
    //Bursa Bölüm Sayı-Bşk - Kom
    drawText(5, rap[15]?.kunye?.bolumSayisi ?? "0", page2, 480, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 126, bold, h1)
    //Bursa Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 126, bold, h1)
    //Bursa Birebir İlgilenen-İlgilenilen
    drawText(5, rap[15]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 126, bold, h1)
    drawText(5, rap[15]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 126, bold, h1)
    // 2.sayfa Bursa - Bölge Başkanı
    drawText(4, rap[15]?.kunye?.bolgeBsk ?? "", page2, 46.5, 146, font, h2)
    // 2.sayfa Bursa - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[15]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 146, bold, h1)
    // 2.sayfa Bursa - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[15]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 146, bold, h1)
    // 2.sayfa Bursa - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 146, bold, h1)
    // 2.sayfa Bursa - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 146, bold, h1)
    // 2.sayfa Bursa - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 146, bold, h1)
    // 2.sayfa Bursa - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 146, bold, h1)
    // 2.sayfa Bursa - Özel Yurt  Sayi-Bşk
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 146, bold, h1)
    drawText(5, rap[15]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 146, bold, h1)
    // 2.sayfa Bursa - Toplam Öğr -Erkek Öğr
    drawText(5, rap[15]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 146, bold, h1)
    drawText(5, rap[15]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 146, bold, h1)
    // Bursa - Teşkilatçı Sayısı
    drawText(7, rap[15]?.toplamTeskilatciSayisi ?? "0", page2, 725, 140, bold, h1)





    //Çanakkale
    //Çanakkale Üniversite Başkanı
    drawText(4, rap[16]?.kunye?.baskan ?? "", page2, 48.5, 158.5, font, h2)
    //Çanakkale Üniversite Başkanı
    drawText(5, rap[16]?.kunye?.telefon ?? "", page2, 46.5, 165, font, h2)

    // Çanakkale - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[16]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 165, bold, h1)
    //Çanakkale - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[16]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 165, bold, h1)
    //Çanakkale - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[16]?.kunye?.fakulteSayisi ?? "0", page2, 243, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 165, bold, h1)
    //Çanakkale Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[16]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 165, bold, h1)
    //Çanakkale MYO Sayı-Bşk - Kom
    drawText(5, rap[16]?.kunye?.myoSayisi ?? "0", page2, 400, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 165, bold, h1)
    //Çanakkale Bölüm Sayı-Bşk - Kom
    drawText(5, rap[16]?.kunye?.bolumSayisi ?? "0", page2, 480, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 165, bold, h1)
    //Çanakkale Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 165, bold, h1)
    //Çanakkale Birebir İlgilenen-İlgilenilen
    drawText(5, rap[16]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 165, bold, h1)
    drawText(5, rap[16]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 165, bold, h1)
    // 2.sayfa Çanakkale - Bölge Başkanı
    drawText(4, rap[16]?.kunye?.bolgeBsk ?? "", page2, 46.5, 184.5, font, h2)
    // 2.sayfa Çanakkale - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[16]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 184.5, bold, h1)
    // 2.sayfa Çanakkale - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[16]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 184.5, bold, h1)
    // 2.sayfa Çanakkale - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 184.5, bold, h1)
    // 2.sayfa Çanakkale - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 184.5, bold, h1)
    // 2.sayfa Çanakkale - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 184.5, bold, h1)
    // 2.sayfa Çanakkale - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 184.5, bold, h1)
    // 2.sayfa Çanakkale - Özel Yurt  Sayi-Bşk
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 184.5, bold, h1)
    drawText(5, rap[16]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 184.5, bold, h1)
    // 2.sayfa Çanakkale - Toplam Öğr -Erkek Öğr
    drawText(5, rap[16]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 184.5, bold, h1)
    drawText(5, rap[16]?.kunye?.erkekOgrSayisi ?? "16", page2, 655, 184.5, bold, h1)
    // Çanakkale - Teşkilatçı Sayısı
    drawText(7, rap[16]?.toplamTeskilatciSayisi ?? "0", page2, 725, 178.5, bold, h1)

    //Çankırı
    //Çankırı Üniversite Başkanı
    drawText(4, rap[17]?.kunye?.baskan ?? "", page2, 48.5, 197, font, h2)
    //Çankırı Üniversite Başkanı
    drawText(5, rap[17]?.kunye?.telefon ?? "", page2, 46.5, 203.5, font, h2)

    // Çankırı - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[17]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 203.5, bold, h1)
    //Çankırı - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[17]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 203.5, bold, h1)
    //Çankırı - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[17]?.kunye?.fakulteSayisi ?? "0", page2, 243, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 203.5, bold, h1)
    //Çankırı Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[17]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 203.5, bold, h1)
    //Çankırı MYO Sayı-Bşk - Kom
    drawText(5, rap[17]?.kunye?.myoSayisi ?? "0", page2, 400, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 203.5, bold, h1)
    //Çankırı Bölüm Sayı-Bşk - Kom
    drawText(5, rap[17]?.kunye?.bolumSayisi ?? "0", page2, 480, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 203.5, bold, h1)
    //Çankırı Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 203.5, bold, h1)
    //Çankırı Birebir İlgilenen-İlgilenilen
    drawText(5, rap[17]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 203.5, bold, h1)
    drawText(5, rap[17]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 203.5, bold, h1)
    // 2.sayfa Çankırı - Bölge Başkanı
    drawText(4, rap[17]?.kunye?.bolgeBsk ?? "", page2, 46.5, 223, font, h2)
    // 2.sayfa Çankırı - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[17]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 223, bold, h1)
    // 2.sayfa Çankırı - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[17]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 223, bold, h1)
    // 2.sayfa Çankırı - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 223, bold, h1)
    // 2.sayfa Çankırı - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 223, bold, h1)
    // 2.sayfa Çankırı - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 223, bold, h1)
    // 2.sayfa Çankırı - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 223, bold, h1)
    // 2.sayfa Çankırı - Özel Yurt  Sayi-Bşk
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 223, bold, h1)
    drawText(5, rap[17]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 223, bold, h1)
    // 2.sayfa Çankırı - Toplam Öğr -Erkek Öğr
    drawText(5, rap[17]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 223, bold, h1)
    drawText(5, rap[17]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 223, bold, h1)
    // Çankırı - Teşkilatçı Sayısı
    drawText(7, rap[17]?.toplamTeskilatciSayisi ?? "0", page2, 725, 217, bold, h1)



    //Çorum

    //Çorum Üniversite Başkanı
    drawText(4, rap[18]?.kunye?.baskan ?? "", page2, 48.5, 235.5, font, h2)
    //Çorum Üniversite Başkanı
    drawText(5, rap[18]?.kunye?.telefon ?? "", page2, 46.5, 242, font, h2)

    // Çorum - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[18]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 242, bold, h1)
    //Çorum - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[18]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 242, bold, h1)
    //Çorum - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[18]?.kunye?.fakulteSayisi ?? "0", page2, 243, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 242, bold, h1)
    //Çorum Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[18]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 242, bold, h1)
    //Çorum MYO Sayı-Bşk - Kom
    drawText(5, rap[18]?.kunye?.myoSayisi ?? "0", page2, 400, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 242, bold, h1)
    //Çorum Bölüm Sayı-Bşk - Kom
    drawText(5, rap[18]?.kunye?.bolumSayisi ?? "0", page2, 480, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 242, bold, h1)
    //Çorum Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 242, bold, h1)
    //Çorum Birebir İlgilenen-İlgilenilen
    drawText(5, rap[18]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 242, bold, h1)
    drawText(5, rap[18]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 242, bold, h1)
    // 2.sayfa Çorum - Bölge Başkanı
    drawText(4, rap[18]?.kunye?.bolgeBsk ?? "", page2, 46.5, 261.5, font, h2)
    // 2.sayfa Çorum - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[18]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 261.5, bold, h1)
    // 2.sayfa Çorum - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[18]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 261.5, bold, h1)
    // 2.sayfa Çorum - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 261.5, bold, h1)
    // 2.sayfa Çorum - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 261.5, bold, h1)
    // 2.sayfa Çorum - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 261.5, bold, h1)
    // 2.sayfa Çorum - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 261.5, bold, h1)
    // 2.sayfa Çorum - Özel Yurt  Sayi-Bşk
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 261.5, bold, h1)
    drawText(5, rap[18]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 261.5, bold, h1)
    // 2.sayfa Çorum - Toplam Öğr -Erkek Öğr
    drawText(5, rap[18]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 261.5, bold, h1)
    drawText(5, rap[18]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 261.5, bold, h1)
    // Çorum - Teşkilatçı Sayısı
    drawText(7, rap[18]?.toplamTeskilatciSayisi ?? "0", page2, 725, 255.5, bold, h1)

    //Denizli
    //Denizli Üniversite Başkanı
    drawText(4, rap[19]?.kunye?.baskan ?? "", page2, 48.5, 274, font, h2)
    //Denizli Üniversite Başkanı
    drawText(5, rap[19]?.kunye?.telefon ?? "", page2, 46.5, 280.5, font, h2)

    // Denizli - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[19]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 280.5, bold, h1)
    //Denizli - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[19]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 280.5, bold, h1)
    //Denizli - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[19]?.kunye?.fakulteSayisi ?? "0", page2, 243, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 280.5, bold, h1)
    //Denizli Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[19]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 280.5, bold, h1)
    //Denizli MYO Sayı-Bşk - Kom
    drawText(5, rap[19]?.kunye?.myoSayisi ?? "0", page2, 400, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 280.5, bold, h1)
    //Denizli Bölüm Sayı-Bşk - Kom
    drawText(5, rap[19]?.kunye?.bolumSayisi ?? "0", page2, 480, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 280.5, bold, h1)
    //Denizli Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 280.5, bold, h1)
    //Denizli Birebir İlgilenen-İlgilenilen
    drawText(5, rap[19]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 280.5, bold, h1)
    drawText(5, rap[19]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 280.5, bold, h1)
    // 2.sayfa Denizli - Bölge Başkanı
    drawText(4, rap[19]?.kunye?.bolgeBsk ?? "", page2, 46.5, 300, font, h2)
    // 2.sayfa Denizli - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[19]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 300, bold, h1)
    // 2.sayfa Denizli - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[19]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 300, bold, h1)
    // 2.sayfa Denizli - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 300, bold, h1)
    // 2.sayfa Denizli - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 300, bold, h1)
    // 2.sayfa Denizli - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 300, bold, h1)
    // 2.sayfa Denizli - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 300, bold, h1)
    // 2.sayfa Denizli - Özel Yurt  Sayi-Bşk
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 300, bold, h1)
    drawText(5, rap[19]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 300, bold, h1)
    // 2.sayfa Denizli - Toplam Öğr -Erkek Öğr
    drawText(5, rap[19]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 300, bold, h1)
    drawText(5, rap[19]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 300, bold, h1)
    // Denizli - Teşkilatçı Sayısı
    drawText(7, rap[19]?.toplamTeskilatciSayisi ?? "0", page2, 725, 294, bold, h1)


    //Diyarbakır

    //Diyarbakır Üniversite Başkanı
    drawText(4, rap[20]?.kunye?.baskan ?? "", page2, 48.5, 312.5, font, h2)
    //Diyarbakır Üniversite Başkanı
    drawText(5, rap[20]?.kunye?.telefon ?? "", page2, 46.5, 319, font, h2)

    // Diyarbakır - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[20]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 319, bold, h1)
    //Diyarbakır - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[20]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 319, bold, h1)
    //Diyarbakır - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[20]?.kunye?.fakulteSayisi ?? "0", page2, 243, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 319, bold, h1)
    //Diyarbakır Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[20]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 319, bold, h1)
    //Diyarbakır MYO Sayı-Bşk - Kom
    drawText(5, rap[20]?.kunye?.myoSayisi ?? "0", page2, 400, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 319, bold, h1)
    //Diyarbakır Bölüm Sayı-Bşk - Kom
    drawText(5, rap[20]?.kunye?.bolumSayisi ?? "0", page2, 480, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 319, bold, h1)
    //Diyarbakır Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 319, bold, h1)
    //Diyarbakır Birebir İlgilenen-İlgilenilen
    drawText(5, rap[20]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 319, bold, h1)
    drawText(5, rap[20]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 319, bold, h1)
    // 2.sayfa Diyarbakır - Bölge Başkanı
    drawText(4, rap[20]?.kunye?.bolgeBsk ?? "", page2, 46.5, 338.5, font, h2)
    // 2.sayfa Diyarbakır - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[20]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 338.5, bold, h1)
    // 2.sayfa Diyarbakır - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[20]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 338.5, bold, h1)
    // 2.sayfa Diyarbakır - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 338.5, bold, h1)
    // 2.sayfa Diyarbakır - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 338.5, bold, h1)
    // 2.sayfa Diyarbakır - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 338.5, bold, h1)
    // 2.sayfa Diyarbakır - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 338.5, bold, h1)
    // 2.sayfa Diyarbakır - Özel Yurt  Sayi-Bşk
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 338.5, bold, h1)
    drawText(5, rap[20]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 338.5, bold, h1)
    // 2.sayfa Diyarbakır - Toplam Öğr -Erkek Öğr
    drawText(5, rap[20]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 338.5, bold, h1)
    drawText(5, rap[20]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 338.5, bold, h1)
    // Diyarbakır - Teşkilatçı Sayısı
    drawText(7, rap[20]?.toplamTeskilatciSayisi ?? "0", page2, 725, 332.5, bold, h1)




    //Edirne
    //Edirne Üniversite Başkanı
    drawText(4, rap[21]?.kunye?.baskan ?? "", page2, 48.5, 351, font, h2)
    //Edirne Üniversite Başkanı
    drawText(5, rap[21]?.kunye?.telefon ?? "", page2, 46.5, 357.5, font, h2)

    // Edirne - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[21]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 357.5, bold, h1)
    //Edirne - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[21]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 357.5, bold, h1)
    //Edirne - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[21]?.kunye?.fakulteSayisi ?? "0", page2, 243, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 357.5, bold, h1)
    //Edirne Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[21]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 357.5, bold, h1)
    //Edirne MYO Sayı-Bşk - Kom
    drawText(5, rap[21]?.kunye?.myoSayisi ?? "0", page2, 400, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 357.5, bold, h1)
    //Edirne Bölüm Sayı-Bşk - Kom
    drawText(5, rap[21]?.kunye?.bolumSayisi ?? "0", page2, 480, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 357.5, bold, h1)
    //Edirne Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 357.5, bold, h1)
    //Edirne Birebir İlgilenen-İlgilenilen
    drawText(5, rap[21]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 357.5, bold, h1)
    drawText(5, rap[21]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 357.5, bold, h1)
    // 2.sayfa Edirne - Bölge Başkanı
    drawText(4, rap[21]?.kunye?.bolgeBsk ?? "", page2, 46.5, 377, font, h2)
    // 2.sayfa Edirne - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[21]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 377, bold, h1)
    // 2.sayfa Edirne - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[21]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 377, bold, h1)
    // 2.sayfa Edirne - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 377, bold, h1)
    // 2.sayfa Edirne - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 377, bold, h1)
    // 2.sayfa Edirne - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 377, bold, h1)
    // 2.sayfa Edirne - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 377, bold, h1)
    // 2.sayfa Edirne - Özel Yurt  Sayi-Bşk
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 377, bold, h1)
    drawText(5, rap[21]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 377, bold, h1)
    // 2.sayfa Edirne - Toplam Öğr -Erkek Öğr
    drawText(5, rap[21]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 377, bold, h1)
    drawText(5, rap[21]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 377, bold, h1)
    // Edirne - Teşkilatçı Sayısı
    drawText(7, rap[21]?.toplamTeskilatciSayisi ?? "0", page2, 725, 371, bold, h1)



    //Elazığ
    //Elazığ Üniversite Başkanı
    drawText(4, rap[22]?.kunye?.baskan ?? "", page2, 48.5, 389.5, font, h2)
    //Elazığ Üniversite Başkanı
    drawText(5, rap[22]?.kunye?.telefon ?? "", page2, 46.5, 396, font, h2)

    // Elazığ - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[22]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 396, bold, h1)
    //Elazığ - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[22]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 396, bold, h1)
    //Elazığ - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[22]?.kunye?.fakulteSayisi ?? "0", page2, 243, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 396, bold, h1)
    //Elazığ Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[22]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 396, bold, h1)
    //Elazığ MYO Sayı-Bşk - Kom
    drawText(5, rap[22]?.kunye?.myoSayisi ?? "0", page2, 400, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 396, bold, h1)
    //Elazığ Bölüm Sayı-Bşk - Kom
    drawText(5, rap[22]?.kunye?.bolumSayisi ?? "0", page2, 480, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 396, bold, h1)
    //Elazığ Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 396, bold, h1)
    //Elazığ Birebir İlgilenen-İlgilenilen
    drawText(5, rap[22]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 396, bold, h1)
    drawText(5, rap[22]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 396, bold, h1)
    // 2.sayfa Elazığ - Bölge Başkanı
    drawText(4, rap[22]?.kunye?.bolgeBsk ?? "", page2, 46.5, 415.5, font, h2)
    // 2.sayfa Elazığ - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[22]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 415.5, bold, h1)
    // 2.sayfa Elazığ - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[22]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 415.5, bold, h1)
    // 2.sayfa Elazığ - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 415.5, bold, h1)
    // 2.sayfa Elazığ - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 415.5, bold, h1)
    // 2.sayfa Elazığ - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 415.5, bold, h1)
    // 2.sayfa Elazığ - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 415.5, bold, h1)
    // 2.sayfa Elazığ - Özel Yurt  Sayi-Bşk
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 415.5, bold, h1)
    drawText(5, rap[22]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 415.5, bold, h1)
    // 2.sayfa Elazığ - Toplam Öğr -Erkek Öğr
    drawText(5, rap[22]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 415.5, bold, h1)
    drawText(5, rap[22]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 415.5, bold, h1)
    // Elazığ - Teşkilatçı Sayısı
    drawText(7, rap[22]?.toplamTeskilatciSayisi ?? "0", page2, 725, 409.5, bold, h1)



    //Erzincan
    //Erzincan Üniversite Başkanı
    drawText(4, rap[23]?.kunye?.baskan ?? "", page2, 48.5, 428, font, h2)
    //Erzincan Üniversite Başkanı
    drawText(5, rap[23]?.kunye?.telefon ?? "", page2, 46.5, 434.5, font, h2)

    // Erzincan - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[23]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 434.5, bold, h1)
    //Erzincan - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[23]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 434.5, bold, h1)
    //Erzincan - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[23]?.kunye?.fakulteSayisi ?? "0", page2, 243, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 434.5, bold, h1)
    //Erzincan Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[23]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 434.5, bold, h1)
    //Erzincan MYO Sayı-Bşk - Kom
    drawText(5, rap[23]?.kunye?.myoSayisi ?? "0", page2, 400, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 434.5, bold, h1)
    //Erzincan Bölüm Sayı-Bşk - Kom
    drawText(5, rap[23]?.kunye?.bolumSayisi ?? "0", page2, 480, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 434.5, bold, h1)
    //Erzincan Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 434.5, bold, h1)
    //Erzincan Birebir İlgilenen-İlgilenilen
    drawText(5, rap[23]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 434.5, bold, h1)
    drawText(5, rap[23]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 434.5, bold, h1)
    // 2.sayfa Erzincan - Bölge Başkanı
    drawText(4, rap[23]?.kunye?.bolgeBsk ?? "", page2, 46.5, 454, font, h2)
    // 2.sayfa Erzincan - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[23]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 454, bold, h1)
    // 2.sayfa Erzincan - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[23]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 454, bold, h1)
    // 2.sayfa Erzincan - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 454, bold, h1)
    // 2.sayfa Erzincan - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 454, bold, h1)
    // 2.sayfa Erzincan - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 454, bold, h1)
    // 2.sayfa Erzincan - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 454, bold, h1)
    // 2.sayfa Erzincan - Özel Yurt  Sayi-Bşk
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 454, bold, h1)
    drawText(5, rap[23]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 454, bold, h1)
    // 2.sayfa Erzincan - Toplam Öğr -Erkek Öğr
    drawText(5, rap[23]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 454, bold, h1)
    drawText(5, rap[23]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 454, bold, h1)
    // Erzincan - Teşkilatçı Sayısı
    drawText(7, rap[23]?.toplamTeskilatciSayisi ?? "0", page2, 725, 448, bold, h1)




    //Erzurum
    //Erzurum Üniversite Başkanı
    drawText(4, rap[24]?.kunye?.baskan ?? "", page2, 48.5, 466.5, font, h2)
    //Erzurum Üniversite Başkanı
    drawText(5, rap[24]?.kunye?.telefon ?? "", page2, 46.5, 473, font, h2)

    // Erzurum - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[24]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 473, bold, h1)
    //Erzurum - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[24]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 473, bold, h1)
    //Erzurum - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[24]?.kunye?.fakulteSayisi ?? "0", page2, 243, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 473, bold, h1)
    //Erzurum Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[24]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 473, bold, h1)
    //Erzurum MYO Sayı-Bşk - Kom
    drawText(5, rap[24]?.kunye?.myoSayisi ?? "0", page2, 400, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 473, bold, h1)
    //Erzurum Bölüm Sayı-Bşk - Kom
    drawText(5, rap[24]?.kunye?.bolumSayisi ?? "0", page2, 480, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 473, bold, h1)
    //Erzurum Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 473, bold, h1)
    //Erzurum Birebir İlgilenen-İlgilenilen
    drawText(5, rap[24]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 473, bold, h1)
    drawText(5, rap[24]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 473, bold, h1)
    // 2.sayfa Erzurum - Bölge Başkanı
    drawText(4, rap[24]?.kunye?.bolgeBsk ?? "", page2, 46.5, 492.5, font, h2)
    // 2.sayfa Erzurum - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[24]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 492.5, bold, h1)
    // 2.sayfa Erzurum - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[24]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 492.5, bold, h1)
    // 2.sayfa Erzurum - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 492.5, bold, h1)
    // 2.sayfa Erzurum - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 492.5, bold, h1)
    // 2.sayfa Erzurum - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 492.5, bold, h1)
    // 2.sayfa Erzurum - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 492.5, bold, h1)
    // 2.sayfa Erzurum - Özel Yurt  Sayi-Bşk
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 492.5, bold, h1)
    drawText(5, rap[24]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 492.5, bold, h1)
    // 2.sayfa Erzurum - Toplam Öğr -Erkek Öğr
    drawText(5, rap[24]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 492.5, bold, h1)
    drawText(5, rap[24]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 492.5, bold, h1)
    // Erzurum - Teşkilatçı Sayısı
    drawText(7, rap[24]?.toplamTeskilatciSayisi ?? "0", page2, 725, 486.5, bold, h1)

    //Eskişehir
    //Eskişehir Üniversite Başkanı
    drawText(4, rap[25]?.kunye?.baskan ?? "", page2, 48.5, 505, font, h2)
    //Eskişehir Üniversite Başkanı
    drawText(5, rap[25]?.kunye?.telefon ?? "", page2, 46.5, 511.5, font, h2)

    // Eskişehir - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[25]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 511.5, bold, h1)
    //Eskişehir - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[25]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 511.5, bold, h1)
    //Eskişehir - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[25]?.kunye?.fakulteSayisi ?? "0", page2, 243, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 511.5, bold, h1)
    //Eskişehir Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[25]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 511.5, bold, h1)
    //Eskişehir MYO Sayı-Bşk - Kom
    drawText(5, rap[25]?.kunye?.myoSayisi ?? "0", page2, 400, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 511.5, bold, h1)
    //Eskişehir Bölüm Sayı-Bşk - Kom
    drawText(5, rap[25]?.kunye?.bolumSayisi ?? "0", page2, 480, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 511.5, bold, h1)
    //Eskişehir Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 511.5, bold, h1)
    //Eskişehir Birebir İlgilenen-İlgilenilen
    drawText(5, rap[25]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 511.5, bold, h1)
    drawText(5, rap[25]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 511.5, bold, h1)
    // 2.sayfa Eskişehir - Bölge Başkanı
    drawText(4, rap[25]?.kunye?.bolgeBsk ?? "", page2, 46.5, 531, font, h2)
    // 2.sayfa Eskişehir - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[25]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 531, bold, h1)
    // 2.sayfa Eskişehir - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[25]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 531, bold, h1)
    // 2.sayfa Eskişehir - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 531, bold, h1)
    // 2.sayfa Eskişehir - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 531, bold, h1)
    // 2.sayfa Eskişehir - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 531, bold, h1)
    // 2.sayfa Eskişehir - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 531, bold, h1)
    // 2.sayfa Eskişehir - Özel Yurt  Sayi-Bşk
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 531, bold, h1)
    drawText(5, rap[25]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 531, bold, h1)
    // 2.sayfa Eskişehir - Toplam Öğr -Erkek Öğr
    drawText(5, rap[25]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 531, bold, h1)
    drawText(5, rap[25]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 531, bold, h1)
    // Eskişehir - Teşkilatçı Sayısı
    drawText(7, rap[25]?.toplamTeskilatciSayisi ?? "0", page2, 725, 525, bold, h1)



    //Gaziantep
    //Gaziantep Üniversite Başkanı
    drawText(4, rap[26]?.kunye?.baskan ?? "", page2, 48.5, 543.5, font, h2)
    //Gaziantep Üniversite Başkanı
    drawText(5, rap[26]?.kunye?.telefon ?? "", page2, 46.5, 550, font, h2)

    // Gaziantep - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[26]?.rapor?.icmal?.subeUniBsk ?? "0", page2, 97, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.subeUniKom ?? "0", page2, 122, 550, bold, h1)
    //Gaziantep - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[26]?.kunye?.temsilciUniSayisi ?? "0", page2, 150, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.temsilciUniBsk ?? "0", page2, 178, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.temsilciUniKom ?? "0", page2, 210, 550, bold, h1)
    //Gaziantep - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[26]?.kunye?.fakulteSayisi ?? "0", page2, 243, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.fakulteBsk ?? "0", page2, 265, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.fakulteKom ?? "0", page2, 295, 550, bold, h1)
    //Gaziantep Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[26]?.kunye?.yuksekokulSayisi ?? "0", page2, 323, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.yuksekokulBsk ?? "0", page2, 348, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.yuksekokulKom ?? "0", page2, 373, 550, bold, h1)
    //Gaziantep MYO Sayı-Bşk - Kom
    drawText(5, rap[26]?.kunye?.myoSayisi ?? "0", page2, 400, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.myoBsk ?? "0", page2, 428, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.myoKom ?? "0", page2, 453, 550, bold, h1)
    //Gaziantep Bölüm Sayı-Bşk - Kom
    drawText(5, rap[26]?.kunye?.bolumSayisi ?? "0", page2, 480, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.bolumBsk ?? "0", page2, 505, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.bolumKom ?? "0", page2, 530, 550, bold, h1)
    //Gaziantep Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page2, 558, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page2, 580, 550, bold, h1)
    //Gaziantep Birebir İlgilenen-İlgilenilen
    drawText(5, rap[26]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page2, 612, 550, bold, h1)
    drawText(5, rap[26]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page2, 655, 550, bold, h1)
    // 2.sayfa Gaziantep - Bölge Başkanı
    drawText(4, rap[26]?.kunye?.bolgeBsk ?? "", page2, 46.5, 569.5, font, h2)
    // 2.sayfa Gaziantep - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[26]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page2, 97, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page2, 122, 569.5, bold, h1)
    // 2.sayfa Gaziantep - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[26]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page2, 150, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page2, 178, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page2, 210, 569.5, bold, h1)
    // 2.sayfa Gaziantep - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page2, 243, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page2, 265, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page2, 295, 569.5, bold, h1)
    // 2.sayfa Gaziantep - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page2, 323, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page2, 348, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page2, 373, 569.5, bold, h1)
    // 2.sayfa Gaziantep - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page2, 400, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page2, 428, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page2, 453, 569.5, bold, h1)
    // 2.sayfa Gaziantep - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page2, 480, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page2, 505, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page2, 530, 569.5, bold, h1)
    // 2.sayfa Gaziantep - Özel Yurt  Sayi-Bşk
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page2, 558, 569.5, bold, h1)
    drawText(5, rap[26]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page2, 580, 569.5, bold, h1)
    // 2.sayfa Gaziantep - Toplam Öğr -Erkek Öğr
    drawText(5, rap[26]?.kunye?.toplamOgrSayisi ?? "0", page2, 612, 569.5, bold, h1)
    drawText(5, rap[26]?.kunye?.erkekOgrSayisi ?? "0", page2, 655, 569.5, bold, h1)
    // Gaziantep - Teşkilatçı Sayısı
    drawText(7, rap[26]?.toplamTeskilatciSayisi ?? "0", page2, 725, 563.5, bold, h1)

    //3.SAYFA
    //Giresun
    //Giresun Üniversite Başkanı
    drawText(4, rap[27]?.kunye?.baskan ?? "", page3, 48.5, 36, font, h3)
    //Giresun Üniversite Başkanı
    drawText(5, rap[27]?.kunye?.telefon ?? "", page3, 46.5, 42.5, font, h3)

    // Giresun - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[27]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 42.5, bold, h3)
    //Giresun - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[27]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 42.5, bold, h3)
    //Giresun - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[27]?.kunye?.fakulteSayisi ?? "0", page3, 243, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 42.5, bold, h3)
    //Giresun Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[27]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 42.5, bold, h3)
    //Giresun MYO Sayı-Bşk - Kom
    drawText(5, rap[27]?.kunye?.myoSayisi ?? "0", page3, 400, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 42.5, bold, h3)
    //Giresun Bölüm Sayı-Bşk - Kom
    drawText(5, rap[27]?.kunye?.bolumSayisi ?? "0", page3, 480, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 42.5, bold, h3)
    //Giresun Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 42.5, bold, h3)
    //Giresun Birebir İlgilenen-İlgilenilen
    drawText(5, rap[27]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 42.5, bold, h3)
    drawText(5, rap[27]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 42.5, bold, h3)
    // 2.sayfa Giresun - Bölge Başkanı
    drawText(4, rap[27]?.kunye?.bolgeBsk ?? "", page3, 46.5, 60.5, font, h3)
    // 2.sayfa Giresun - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[27]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 60.5, bold, h3)
    // 2.sayfa Giresun - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[27]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 60.5, bold, h3)
    // 2.sayfa Giresun - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 60.5, bold, h3)
    // 2.sayfa Giresun - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 60.5, bold, h3)
    // 2.sayfa Giresun - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 60.5, bold, h3)
    // 2.sayfa Giresun - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 60.5, bold, h3)
    // 2.sayfa Giresun - Özel Yurt  Sayi-Bşk
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 60.5, bold, h3)
    drawText(5, rap[27]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 60.5, bold, h3)
    // 2.sayfa Giresun - Toplam Öğr -Erkek Öğr
    drawText(5, rap[27]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 60.5, bold, h3)
    drawText(5, rap[27]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 60.5, bold, h3)
    // Giresun - Teşkilatçı Sayısı
    drawText(7, rap[27]?.toplamTeskilatciSayisi ?? "0", page3, 725, 54.5, bold, h1)



    //Gümüşhane
    //Gümüşhane Üniversite Başkanı
    drawText(4, rap[28]?.kunye?.baskan ?? "", page3, 48.5, 74, font, h3)
    //Gümüşhane Üniversite Başkanı
    drawText(5, rap[28]?.kunye?.telefon ?? "", page3, 46.5, 80, font, h3)

    // Gümüşhane - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[28]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 80, bold, h3)
    //Gümüşhane - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[28]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 80, bold, h3)
    //Gümüşhane - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[28]?.kunye?.fakulteSayisi ?? "0", page3, 243, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 80, bold, h3)
    //Gümüşhane Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[28]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 80, bold, h3)
    //Gümüşhane MYO Sayı-Bşk - Kom
    drawText(5, rap[28]?.kunye?.myoSayisi ?? "0", page3, 400, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 80, bold, h3)
    //Gümüşhane Bölüm Sayı-Bşk - Kom
    drawText(5, rap[28]?.kunye?.bolumSayisi ?? "0", page3, 480, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 80, bold, h3)
    //Gümüşhane Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 80, bold, h3)
    //Gümüşhane Birebir İlgilenen-İlgilenilen
    drawText(5, rap[28]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 80, bold, h3)
    drawText(5, rap[28]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 80, bold, h3)
    // 3.sayfa Gümüşhane - Bölge Başkanı
    drawText(4, rap[28]?.kunye?.bolgeBsk ?? "", page3, 46.5, 98, font, h3)
    // 3.sayfa Gümüşhane - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[28]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 98, bold, h3)
    // 3.sayfa Gümüşhane - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[28]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 98, bold, h3)
    // 3.sayfa Gümüşhane - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 98, bold, h3)
    // 3.sayfa Gümüşhane - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 98, bold, h3)
    // 3.sayfa Gümüşhane - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 98, bold, h3)
    // 3.sayfa Gümüşhane - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 98, bold, h3)
    // 3.sayfa Gümüşhane - Özel Yurt  Sayi-Bşk
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 98, bold, h3)
    drawText(5, rap[28]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 98, bold, h3)
    // 3.sayfa Gümüşhane - Toplam Öğr -Erkek Öğr
    drawText(5, rap[28]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 98, bold, h3)
    drawText(5, rap[28]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 98, bold, h3)
    // Gümüşhane - Teşkilatçı Sayısı
    drawText(7, rap[28]?.toplamTeskilatciSayisi ?? "0", page3, 725, 92, bold, h1)

    //Hakkari
    //Hakkari Üniversite Başkanı
    drawText(4, rap[29]?.kunye?.baskan ?? "", page3, 48.5, 111, font, h3)
    //Hakkari Üniversite Başkanı
    drawText(5, rap[29]?.kunye?.telefon ?? "", page3, 46.5, 117, font, h3)

    // Hakkari - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[29]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 117, bold, h3)
    //Hakkari - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[29]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 117, bold, h3)
    //Hakkari - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[29]?.kunye?.fakulteSayisi ?? "0", page3, 243, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 117, bold, h3)
    //Hakkari Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[29]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 117, bold, h3)
    //Hakkari MYO Sayı-Bşk - Kom
    drawText(5, rap[29]?.kunye?.myoSayisi ?? "0", page3, 400, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 117, bold, h3)
    //Hakkari Bölüm Sayı-Bşk - Kom
    drawText(5, rap[29]?.kunye?.bolumSayisi ?? "0", page3, 480, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 117, bold, h3)
    //Hakkari Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 117, bold, h3)
    //Hakkari Birebir İlgilenen-İlgilenilen
    drawText(5, rap[29]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 117, bold, h3)
    drawText(5, rap[29]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 117, bold, h3)
    //3.sayfa Hakkari - Bölge Başkanı
    drawText(4, rap[29]?.kunye?.bolgeBsk ?? "", page3, 46.5, 135, font, h3)
    //3.sayfa Hakkari - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[29]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 135, bold, h3)
    //3.sayfa Hakkari - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[29]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 135, bold, h3)
    //3.sayfa Hakkari - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 135, bold, h3)
    //3.sayfa Hakkari - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 135, bold, h3)
    //3.sayfa Hakkari - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 135, bold, h3)
    //3.sayfa Hakkari - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 135, bold, h3)
    //3.sayfa Hakkari - Özel Yurt  Sayi-Bşk
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 135, bold, h3)
    drawText(5, rap[29]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 135, bold, h3)
    //3.sayfa Hakkari - Toplam Öğr -Erkek Öğr
    drawText(5, rap[29]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 135, bold, h3)
    drawText(5, rap[29]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 135, bold, h3)
    // Hakkari - Teşkilatçı Sayısı
    drawText(7, rap[29]?.toplamTeskilatciSayisi ?? "0", page3, 725, 129, bold, h1)


    //Hatay
    //Hatay Üniversite Başkanı
    drawText(4, rap[30]?.kunye?.baskan ?? "", page3, 48.5, 148, font, h3)
    //Hatay Üniversite Başkanı
    drawText(5, rap[30]?.kunye?.telefon ?? "", page3, 46.5, 154, font, h3)

    // Hatay - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[30]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 154, bold, h3)
    //Hatay - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[30]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 154, bold, h3)
    //Hatay - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[30]?.kunye?.fakulteSayisi ?? "0", page3, 243, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 154, bold, h3)
    //Hatay Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[30]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 154, bold, h3)
    //Hatay MYO Sayı-Bşk - Kom
    drawText(5, rap[30]?.kunye?.myoSayisi ?? "0", page3, 400, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 154, bold, h3)
    //Hatay Bölüm Sayı-Bşk - Kom
    drawText(5, rap[30]?.kunye?.bolumSayisi ?? "0", page3, 480, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 154, bold, h3)
    //Hatay Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 154, bold, h3)
    //Hatay Birebir İlgilenen-İlgilenilen
    drawText(5, rap[30]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 154, bold, h3)
    drawText(5, rap[30]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 154, bold, h3)
    //3.sayfa Hatay - Bölge Başkanı
    drawText(4, rap[30]?.kunye?.bolgeBsk ?? "", page3, 46.5, 172.5, font, h3)
    //3.sayfa Hatay - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[30]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 172.5, bold, h3)
    //3.sayfa Hatay - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[30]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 172.5, bold, h3)
    //3.sayfa Hatay - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 172.5, bold, h3)
    //3.sayfa Hatay - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 172.5, bold, h3)
    //3.sayfa Hatay - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 172.5, bold, h3)
    //3.sayfa Hatay - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 172.5, bold, h3)
    //3.sayfa Hatay - Özel Yurt  Sayi-Bşk
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 172.5, bold, h3)
    drawText(5, rap[30]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 172.5, bold, h3)
    //3.sayfa Hatay - Toplam Öğr -Erkek Öğr
    drawText(5, rap[30]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 172.5, bold, h3)
    drawText(5, rap[30]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 172.5, bold, h3)
    // Hatay - Teşkilatçı Sayısı
    drawText(7, rap[30]?.toplamTeskilatciSayisi ?? "0", page3, 725, 166.5, bold, h1)

    //Isparta
    //Isparta Üniversite Başkanı
    drawText(4, rap[31]?.kunye?.baskan ?? "", page3, 48.5, 185, font, h3)
    //Isparta Üniversite Başkanı
    drawText(5, rap[31]?.kunye?.telefon ?? "", page3, 46.5, 192, font, h3)

    // Isparta - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[31]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 192, bold, h3)
    //Isparta - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[31]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 192, bold, h3)
    //Isparta - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[31]?.kunye?.fakulteSayisi ?? "0", page3, 243, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 192, bold, h3)
    //Isparta Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[31]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 192, bold, h3)
    //Isparta MYO Sayı-Bşk - Kom
    drawText(5, rap[31]?.kunye?.myoSayisi ?? "0", page3, 400, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 192, bold, h3)
    //Isparta Bölüm Sayı-Bşk - Kom
    drawText(5, rap[31]?.kunye?.bolumSayisi ?? "0", page3, 480, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 192, bold, h3)
    //Isparta Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 192, bold, h3)
    //Isparta Birebir İlgilenen-İlgilenilen
    drawText(5, rap[31]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 192, bold, h3)
    drawText(5, rap[31]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 192, bold, h3)
    //3.sayfa Isparta - Bölge Başkanı
    drawText(4, rap[31]?.kunye?.bolgeBsk ?? "", page3, 46.5, 210.5, font, h3)
    //3.sayfa Isparta - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[31]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 210.5, bold, h3)
    //3.sayfa Isparta - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[31]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 210.5, bold, h3)
    //3.sayfa Isparta - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 210.5, bold, h3)
    //3.sayfa Isparta - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 210.5, bold, h3)
    //3.sayfa Isparta - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 210.5, bold, h3)
    //3.sayfa Isparta - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 210.5, bold, h3)
    //3.sayfa Isparta - Özel Yurt  Sayi-Bşk
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 210.5, bold, h3)
    drawText(5, rap[31]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 210.5, bold, h3)
    //3.sayfa Isparta - Toplam Öğr -Erkek Öğr
    drawText(5, rap[31]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 210.5, bold, h3)
    drawText(5, rap[31]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 210.5, bold, h3)
    // Isparta - Teşkilatçı Sayısı
    drawText(7, rap[31]?.toplamTeskilatciSayisi ?? "0", page3, 725, 214.5, bold, h1)



    //Mersin
    //Mersin Üniversite Başkanı
    drawText(4, rap[32]?.kunye?.baskan ?? "", page3, 48.5, 223, font, h3)
    //Mersin Üniversite Başkanı
    drawText(5, rap[32]?.kunye?.telefon ?? "", page3, 46.5, 230, font, h3)

    // Mersin - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[32]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 230, bold, h3)
    //Mersin - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[32]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 230, bold, h3)
    //Mersin - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[32]?.kunye?.fakulteSayisi ?? "0", page3, 243, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 230, bold, h3)
    //Mersin Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[32]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 230, bold, h3)
    //Mersin MYO Sayı-Bşk - Kom
    drawText(5, rap[32]?.kunye?.myoSayisi ?? "0", page3, 400, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 230, bold, h3)
    //Mersin Bölüm Sayı-Bşk - Kom
    drawText(5, rap[32]?.kunye?.bolumSayisi ?? "0", page3, 480, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 230, bold, h3)
    //Mersin Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 230, bold, h3)
    //Mersin Birebir İlgilenen-İlgilenilen
    drawText(5, rap[32]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 230, bold, h3)
    drawText(5, rap[32]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 230, bold, h3)
    //3.sayfa Mersin - Bölge Başkanı
    drawText(4, rap[32]?.kunye?.bolgeBsk ?? "", page3, 46.5, 248.5, font, h3)
    //3.sayfa Mersin - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[32]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 248.5, bold, h3)
    //3.sayfa Mersin - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[32]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 248.5, bold, h3)
    //3.sayfa Mersin - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 248.5, bold, h3)
    //3.sayfa Mersin - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 248.5, bold, h3)
    //3.sayfa Mersin - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 248.5, bold, h3)
    //3.sayfa Mersin - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 248.5, bold, h3)
    //3.sayfa Mersin - Özel Yurt  Sayi-Bşk
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 248.5, bold, h3)
    drawText(5, rap[32]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 248.5, bold, h3)
    //3.sayfa Mersin - Toplam Öğr -Erkek Öğr
    drawText(5, rap[32]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 248.5, bold, h3)
    drawText(5, rap[32]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 248.5, bold, h3)
    // Mersin - Teşkilatçı Sayısı
    drawText(7, rap[32]?.toplamTeskilatciSayisi ?? "0", page3, 725, 242.5, bold, h1)



    //İstanbul
    //İstanbul Üniversite Başkanı
    drawText(4, rap[33]?.kunye?.baskan ?? "", page3, 48.5, 261, font, h3)
    //İstanbul Üniversite Başkanı
    drawText(5, rap[33]?.kunye?.telefon ?? "", page3, 46.5, 267, font, h3)

    // İstanbul - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[33]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 267, bold, h3)
    //İstanbul - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[33]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 267, bold, h3)
    //İstanbul - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[33]?.kunye?.fakulteSayisi ?? "0", page3, 243, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 267, bold, h3)
    //İstanbul Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[33]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 267, bold, h3)
    //İstanbul MYO Sayı-Bşk - Kom
    drawText(5, rap[33]?.kunye?.myoSayisi ?? "0", page3, 400, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 267, bold, h3)
    //İstanbul Bölüm Sayı-Bşk - Kom
    drawText(5, rap[33]?.kunye?.bolumSayisi ?? "0", page3, 480, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 267, bold, h3)
    //İstanbul Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 267, bold, h3)
    //İstanbul Birebir İlgilenen-İlgilenilen
    drawText(5, rap[33]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 267, bold, h3)
    drawText(5, rap[33]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 267, bold, h3)
    //3.sayfa İstanbul - Bölge Başkanı
    drawText(4, rap[33]?.kunye?.bolgeBsk ?? "", page3, 46.5, 285.5, font, h3)
    //3.sayfa İstanbul - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[33]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 285.5, bold, h3)
    //3.sayfa İstanbul - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[33]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 285.5, bold, h3)
    //3.sayfa İstanbul - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 285.5, bold, h3)
    //3.sayfa İstanbul - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 285.5, bold, h3)
    //3.sayfa İstanbul - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 285.5, bold, h3)
    //3.sayfa İstanbul - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 285.5, bold, h3)
    //3.sayfa İstanbul - Özel Yurt  Sayi-Bşk
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 285.5, bold, h3)
    drawText(5, rap[33]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 285.5, bold, h3)
    //3.sayfa İstanbul - Toplam Öğr -Erkek Öğr
    drawText(5, rap[33]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 285.5, bold, h3)
    drawText(5, rap[33]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 285.5, bold, h3)
    // İstanbul - Teşkilatçı Sayısı
    drawText(7, rap[33]?.toplamTeskilatciSayisi ?? "0", page3, 725, 279.5, bold, h1)


    //İzmir
    //İzmir Üniversite Başkanı
    drawText(4, rap[34]?.kunye?.baskan ?? "", page3, 48.5, 298, font, h3)
    //İzmir Üniversite Başkanı
    drawText(5, rap[34]?.kunye?.telefon ?? "", page3, 46.5, 305, font, h3)

    // İzmir - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[34]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 305, bold, h3)
    //İzmir - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[34]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 305, bold, h3)
    //İzmir - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[34]?.kunye?.fakulteSayisi ?? "0", page3, 243, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 305, bold, h3)
    //İzmir Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[34]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 305, bold, h3)
    //İzmir MYO Sayı-Bşk - Kom
    drawText(5, rap[34]?.kunye?.myoSayisi ?? "0", page3, 400, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 305, bold, h3)
    //İzmir Bölüm Sayı-Bşk - Kom
    drawText(5, rap[34]?.kunye?.bolumSayisi ?? "0", page3, 480, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 305, bold, h3)
    //İzmir Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 305, bold, h3)
    //İzmir Birebir İlgilenen-İlgilenilen
    drawText(5, rap[34]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 305, bold, h3)
    drawText(5, rap[34]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 305, bold, h3)
    //3.sayfa İzmir - Bölge Başkanı
    drawText(4, rap[34]?.kunye?.bolgeBsk ?? "", page3, 46.5, 323, font, h3)
    //3.sayfa İzmir - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[34]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 323, bold, h3)
    //3.sayfa İzmir - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[34]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 323, bold, h3)
    //3.sayfa İzmir - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 323, bold, h3)
    //3.sayfa İzmir - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 323, bold, h3)
    //3.sayfa İzmir - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 323, bold, h3)
    //3.sayfa İzmir - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 323, bold, h3)
    //3.sayfa İzmir - Özel Yurt  Sayi-Bşk
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 323, bold, h3)
    drawText(5, rap[34]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 323, bold, h3)
    //3.sayfa İzmir - Toplam Öğr -Erkek Öğr
    drawText(5, rap[34]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 323, bold, h3)
    drawText(5, rap[34]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 323, bold, h3)
    // İzmir - Teşkilatçı Sayısı
    drawText(7, rap[34]?.toplamTeskilatciSayisi ?? "0", page3, 725, 317, bold, h1)


    //Kars
    //Kars Üniversite Başkanı
    drawText(4, rap[35]?.kunye?.baskan ?? "", page3, 48.5, 336, font, h3)
    //Kars Üniversite Başkanı
    drawText(5, rap[35]?.kunye?.telefon ?? "", page3, 46.5, 342, font, h3)

    // Kars - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[35]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 342, bold, h3)
    //Kars - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[35]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 342, bold, h3)
    //Kars - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[35]?.kunye?.fakulteSayisi ?? "0", page3, 243, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 342, bold, h3)
    //Kars Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[35]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 342, bold, h3)
    //Kars MYO Sayı-Bşk - Kom
    drawText(5, rap[35]?.kunye?.myoSayisi ?? "0", page3, 400, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 342, bold, h3)
    //Kars Bölüm Sayı-Bşk - Kom
    drawText(5, rap[35]?.kunye?.bolumSayisi ?? "0", page3, 480, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 342, bold, h3)
    //Kars Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 342, bold, h3)
    //Kars Birebir İlgilenen-İlgilenilen
    drawText(5, rap[35]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 342, bold, h3)
    drawText(5, rap[35]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 342, bold, h3)
    //3.sayfa Kars - Bölge Başkanı
    drawText(4, rap[35]?.kunye?.bolgeBsk ?? "", page3, 46.5, 360.5, font, h3)
    //3.sayfa Kars - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[35]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 360.5, bold, h3)
    //3.sayfa Kars - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[35]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 360.5, bold, h3)
    //3.sayfa Kars - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 360.5, bold, h3)
    //3.sayfa Kars - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 360.5, bold, h3)
    //3.sayfa Kars - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 360.5, bold, h3)
    //3.sayfa Kars - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 360.5, bold, h3)
    //3.sayfa Kars - Özel Yurt  Sayi-Bşk
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 360.5, bold, h3)
    drawText(5, rap[35]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 360.5, bold, h3)
    //3.sayfa Kars - Toplam Öğr -Erkek Öğr
    drawText(5, rap[35]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 360.5, bold, h3)
    drawText(5, rap[35]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 360.5, bold, h3)
    // Kars - Teşkilatçı Sayısı
    drawText(7, rap[35]?.toplamTeskilatciSayisi ?? "0", page3, 725, 354.5, bold, h1)

    //Kastamonu
    //Kastamonu Üniversite Başkanı
    drawText(4, rap[36]?.kunye?.baskan ?? "", page3, 48.5, 373, font, h3)
    //Kastamonu Üniversite Başkanı
    drawText(5, rap[36]?.kunye?.telefon ?? "", page3, 46.5, 380, font, h3)

    // Kastamonu - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[36]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 380, bold, h3)
    //Kastamonu - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[36]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 380, bold, h3)
    //Kastamonu - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[36]?.kunye?.fakulteSayisi ?? "0", page3, 243, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 380, bold, h3)
    //Kastamonu Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[36]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 380, bold, h3)
    //Kastamonu MYO Sayı-Bşk - Kom
    drawText(5, rap[36]?.kunye?.myoSayisi ?? "0", page3, 400, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 380, bold, h3)
    //Kastamonu Bölüm Sayı-Bşk - Kom
    drawText(5, rap[36]?.kunye?.bolumSayisi ?? "0", page3, 480, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 380, bold, h3)
    //Kastamonu Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 380, bold, h3)
    //Kastamonu Birebir İlgilenen-İlgilenilen
    drawText(5, rap[36]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 380, bold, h3)
    drawText(5, rap[36]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 380, bold, h3)
    //3.sayfa Kastamonu - Bölge Başkanı
    drawText(4, rap[36]?.kunye?.bolgeBsk ?? "", page3, 46.5, 398.5, font, h3)
    //3.sayfa Kastamonu - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[36]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 97, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 122, 398.5, bold, h3)
    //3.sayfa Kastamonu - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[36]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 150, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 178, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 210, 398.5, bold, h3)
    //3.sayfa Kastamonu - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 243, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 265, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 295, 398.5, bold, h3)
    //3.sayfa Kastamonu - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 323, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 348, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 373, 398.5, bold, h3)
    //3.sayfa Kastamonu - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 400, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 428, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 453, 398.5, bold, h3)
    //3.sayfa Kastamonu - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 480, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 505, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 530, 398.5, bold, h3)
    //3.sayfa Kastamonu - Özel Yurt  Sayi-Bşk
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 558, 398.5, bold, h3)
    drawText(5, rap[36]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 580, 398.5, bold, h3)
    //3.sayfa Kastamonu - Toplam Öğr -Erkek Öğr
    drawText(5, rap[36]?.kunye?.toplamOgrSayisi ?? "0", page3, 612, 398.5, bold, h3)
    drawText(5, rap[36]?.kunye?.erkekOgrSayisi ?? "0", page3, 655, 398.5, bold, h3)
    // Kastamonu - Teşkilatçı Sayısı
    drawText(7, rap[36]?.toplamTeskilatciSayisi ?? "0", page3, 725, 392.5, bold, h1)

    //Kayseri
    //Kayseri Üniversite Başkanı
    drawText(4, rap[37]?.kunye?.baskan ?? "", page3, 48.5, 410, font, h3)
    //Kayseri Üniversite Başkanı
    drawText(5, rap[37]?.kunye?.telefon ?? "", page3, 46.5, 417, font, h3)

    // Kayseri - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[37]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 97, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.subeUniKom ?? "0", page3, 122, 417, bold, h3)
    //Kayseri - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[37]?.kunye?.temsilciUniSayisi ?? "0", page3, 150, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 178, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 210, 417, bold, h3)
    //Kayseri - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[37]?.kunye?.fakulteSayisi ?? "0", page3, 243, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 265, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.fakulteKom ?? "0", page3, 295, 417, bold, h3)
    //Kayseri Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[37]?.kunye?.yuksekokulSayisi ?? "0", page3, 323, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 348, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 373, 417, bold, h3)
    //Kayseri MYO Sayı-Bşk - Kom
    drawText(5, rap[37]?.kunye?.myoSayisi ?? "0", page3, 400, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.myoBsk ?? "0", page3, 428, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.myoKom ?? "0", page3, 453, 417, bold, h3)
    //Kayseri Bölüm Sayı-Bşk - Kom
    drawText(5, rap[37]?.kunye?.bolumSayisi ?? "0", page3, 480, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.bolumBsk ?? "0", page3, 505, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.bolumKom ?? "0", page3, 530, 417, bold, h3)
    //Kayseri Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 558, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 580, 417, bold, h3)
    //Kayseri Birebir İlgilenen-İlgilenilen
    drawText(5, rap[37]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 612, 417, bold, h3)
    drawText(5, rap[37]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 655, 417, bold, h3)
    //3.sayfa Kayseri - Bölge Başkanı
    drawText(4, rap[37]?.kunye?.bolgeBsk ?? "", page3, 46.5, 435, font, h3)
    //3.sayfa Kayseri - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[37]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 98, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 123, 435, bold, h3)
    //3.sayfa Kayseri - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[37]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 151, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 179, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 211, 435, bold, h3)
    //3.sayfa Kayseri - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 244, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 266, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 296, 435, bold, h3)
    //3.sayfa Kayseri - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 324, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 349, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 374, 435, bold, h3)
    //3.sayfa Kayseri - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 401, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 429, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 454, 435, bold, h3)
    //3.sayfa Kayseri - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 481, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 506, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 531, 435, bold, h3)
    //3.sayfa Kayseri - Özel Yurt  Sayi-Bşk
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 559, 435, bold, h3)
    drawText(5, rap[37]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 581, 435, bold, h3)
    //3.sayfa Kayseri - Toplam Öğr -Erkek Öğr
    drawText(5, rap[37]?.kunye?.toplamOgrSayisi ?? "0", page3, 613, 435, bold, h3)
    drawText(5, rap[37]?.kunye?.erkekOgrSayisi ?? "0", page3, 656, 435, bold, h3)
    // Kayseri - Teşkilatçı Sayısı
    drawText(7, rap[37]?.toplamTeskilatciSayisi ?? "0", page3, 725, 429, bold, h1)


    //Kırklareli
    //Kırklareli Üniversite Başkanı
    drawText(4, rap[38]?.kunye?.baskan ?? "", page3, 48.5, 447, font, h3)
    //Kırklareli Üniversite Başkanı
    drawText(5, rap[38]?.kunye?.telefon ?? "", page3, 46.5, 454, font, h3)

    // Kırklareli - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[38]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 98, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.subeUniKom ?? "0", page3, 123, 454, bold, h3)
    //Kırklareli - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[38]?.kunye?.temsilciUniSayisi ?? "0", page3, 151, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 179, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 211, 454, bold, h3)
    //Kırklareli - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[38]?.kunye?.fakulteSayisi ?? "0", page3, 244, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 266, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.fakulteKom ?? "0", page3, 296, 454, bold, h3)
    //Kırklareli Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[38]?.kunye?.yuksekokulSayisi ?? "0", page3, 324, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 349, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 374, 454, bold, h3)
    //Kırklareli MYO Sayı-Bşk - Kom
    drawText(5, rap[38]?.kunye?.myoSayisi ?? "0", page3, 401, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.myoBsk ?? "0", page3, 429, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.myoKom ?? "0", page3, 454, 454, bold, h3)
    //Kırklareli Bölüm Sayı-Bşk - Kom
    drawText(5, rap[38]?.kunye?.bolumSayisi ?? "0", page3, 481, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.bolumBsk ?? "0", page3, 506, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.bolumKom ?? "0", page3, 531, 454, bold, h3)
    //Kırklareli Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 559, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 581, 454, bold, h3)
    //Kırklareli Birebir İlgilenen-İlgilenilen
    drawText(5, rap[38]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 613, 454, bold, h3)
    drawText(5, rap[38]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 656, 454, bold, h3)
    //3.sayfa Kırklareli - Bölge Başkanı
    drawText(4, rap[38]?.kunye?.bolgeBsk ?? "", page3, 46.5, 472, font, h3)
    //3.sayfa Kırklareli - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[38]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 98, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 123, 472, bold, h3)
    //3.sayfa Kırklareli - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[38]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 151, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 179, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 211, 472, bold, h3)
    //3.sayfa Kırklareli - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 244, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 266, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 296, 472, bold, h3)
    //3.sayfa Kırklareli - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 324, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 349, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 374, 472, bold, h3)
    //3.sayfa Kırklareli - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 401, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 429, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 454, 472, bold, h3)
    //3.sayfa Kırklareli - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 481, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 506, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 531, 472, bold, h3)
    //3.sayfa Kırklareli - Özel Yurt  Sayi-Bşk
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 559, 472, bold, h3)
    drawText(5, rap[38]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 581, 472, bold, h3)
    //3.sayfa Kırklareli - Toplam Öğr -Erkek Öğr
    drawText(5, rap[38]?.kunye?.toplamOgrSayisi ?? "0", page3, 613, 472, bold, h3)
    drawText(5, rap[38]?.kunye?.erkekOgrSayisi ?? "0", page3, 656, 472, bold, h3)
    // Kırklareli - Teşkilatçı Sayısı
    drawText(7, rap[38]?.toplamTeskilatciSayisi ?? "0", page3, 725, 466, bold, h1)



    //Kırşehir
    //Kırşehir Üniversite Başkanı
    drawText(4, rap[39]?.kunye?.baskan ?? "", page3, 48.5, 484.5, font, h3)
    //Kırşehir Üniversite Başkanı
    drawText(5, rap[39]?.kunye?.telefon ?? "", page3, 46.5, 491.5, font, h3)

    // Kırşehir - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[39]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 98, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.subeUniKom ?? "0", page3, 123, 491.5, bold, h3)
    //Kırşehir - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[39]?.kunye?.temsilciUniSayisi ?? "0", page3, 151, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 179, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 211, 491.5, bold, h3)
    //Kırşehir - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[39]?.kunye?.fakulteSayisi ?? "0", page3, 244, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 266, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.fakulteKom ?? "0", page3, 296, 491.5, bold, h3)
    //Kırşehir Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[39]?.kunye?.yuksekokulSayisi ?? "0", page3, 324, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 349, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 374, 491.5, bold, h3)
    //Kırşehir MYO Sayı-Bşk - Kom
    drawText(5, rap[39]?.kunye?.myoSayisi ?? "0", page3, 401, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.myoBsk ?? "0", page3, 429, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.myoKom ?? "0", page3, 455, 491.5, bold, h3)
    //Kırşehir Bölüm Sayı-Bşk - Kom
    drawText(5, rap[39]?.kunye?.bolumSayisi ?? "0", page3, 481, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.bolumBsk ?? "0", page3, 506, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.bolumKom ?? "0", page3, 531, 491.5, bold, h3)
    //Kırşehir Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 559, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 581, 491.5, bold, h3)
    //Kırşehir Birebir İlgilenen-İlgilenilen
    drawText(5, rap[39]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 613, 491.5, bold, h3)
    drawText(5, rap[39]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 656, 491.5, bold, h3)
    //3.sayfa Kırşehir - Bölge Başkanı
    drawText(4, rap[39]?.kunye?.bolgeBsk ?? "", page3, 46.5, 509.5, font, h3)
    //3.sayfa Kırşehir - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[39]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 98, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 123, 509.5, bold, h3)
    //3.sayfa Kırşehir - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[39]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 151, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 179, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 211, 509.5, bold, h3)
    //3.sayfa Kırşehir - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 244, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 266, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 296, 509.5, bold, h3)
    //3.sayfa Kırşehir - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 324, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 349, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 374, 509.5, bold, h3)
    //3.sayfa Kırşehir - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 401, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 429, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 454, 509.5, bold, h3)
    //3.sayfa Kırşehir - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 481, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 506, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 531, 509.5, bold, h3)
    //3.sayfa Kırşehir - Özel Yurt  Sayi-Bşk
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 559, 509.5, bold, h3)
    drawText(5, rap[39]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 581, 509.5, bold, h3)
    //3.sayfa Kırşehir - Toplam Öğr -Erkek Öğr
    drawText(5, rap[39]?.kunye?.toplamOgrSayisi ?? "0", page3, 613, 509.5, bold, h3)
    drawText(5, rap[39]?.kunye?.erkekOgrSayisi ?? "0", page3, 656, 509.5, bold, h3)
    // Kırşehir - Teşkilatçı Sayısı
    drawText(7, rap[39]?.toplamTeskilatciSayisi ?? "0", page3, 725, 503.5, bold, h1)


    //Kocaeli
    //Kocaeli Üniversite Başkanı
    drawText(4, rap[40]?.kunye?.baskan ?? "", page3, 48.5, 522, font, h3)
    //Kocaeli Üniversite Başkanı
    drawText(5, rap[40]?.kunye?.telefon ?? "", page3, 46.5, 529, font, h3)

    // Kocaeli - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[40]?.rapor?.icmal?.subeUniBsk ?? "0", page3, 98, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.subeUniKom ?? "0", page3, 123, 529, bold, h3)
    //Kocaeli - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[40]?.kunye?.temsilciUniSayisi ?? "0", page3, 151, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.temsilciUniBsk ?? "0", page3, 179, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.temsilciUniKom ?? "0", page3, 211, 529, bold, h3)
    //Kocaeli - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[40]?.kunye?.fakulteSayisi ?? "0", page3, 244, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.fakulteBsk ?? "0", page3, 266, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.fakulteKom ?? "0", page3, 296, 529, bold, h3)
    //Kocaeli Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[40]?.kunye?.yuksekokulSayisi ?? "0", page3, 324, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.yuksekokulBsk ?? "0", page3, 349, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.yuksekokulKom ?? "0", page3, 374, 529, bold, h3)
    //Kocaeli MYO Sayı-Bşk - Kom
    drawText(5, rap[40]?.kunye?.myoSayisi ?? "0", page3, 401, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.myoBsk ?? "0", page3, 429, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.myoKom ?? "0", page3, 455, 529, bold, h3)
    //Kocaeli Bölüm Sayı-Bşk - Kom
    drawText(5, rap[40]?.kunye?.bolumSayisi ?? "0", page3, 481, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.bolumBsk ?? "0", page3, 506, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.bolumKom ?? "0", page3, 531, 529, bold, h3)
    //Kocaeli Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page3, 559, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page3, 581, 529, bold, h3)
    //Kocaeli Birebir İlgilenen-İlgilenilen
    drawText(5, rap[40]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page3, 613, 529, bold, h3)
    drawText(5, rap[40]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page3, 656, 529, bold, h3)
    //3.sayfa Kocaeli - Bölge Başkanı
    drawText(4, rap[40]?.kunye?.bolgeBsk ?? "", page3, 46.5, 547, font, h3)
    //3.sayfa Kocaeli - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[40]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page3, 98, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page3, 123, 547, bold, h3)
    //3.sayfa Kocaeli - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[40]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page3, 151, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page3, 179, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page3, 211, 547, bold, h3)
    //3.sayfa Kocaeli - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page3, 244, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page3, 266, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page3, 296, 547, bold, h3)
    //3.sayfa Kocaeli - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page3, 324, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page3, 349, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page3, 374, 547, bold, h3)
    //3.sayfa Kocaeli - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page3, 401, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page3, 429, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page3, 454, 547, bold, h3)
    //3.sayfa Kocaeli - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page3, 481, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page3, 506, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page3, 531, 547, bold, h3)
    //3.sayfa Kocaeli - Özel Yurt  Sayi-Bşk
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page3, 559, 547, bold, h3)
    drawText(5, rap[40]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page3, 581, 547, bold, h3)
    //3.sayfa Kocaeli - Toplam Öğr -Erkek Öğr
    drawText(5, rap[40]?.kunye?.toplamOgrSayisi ?? "0", page3, 613, 547, bold, h3)
    drawText(5, rap[40]?.kunye?.erkekOgrSayisi ?? "0", page3, 656, 547, bold, h3)
    // Kocaeli - Teşkilatçı Sayısı
    drawText(7, rap[40]?.toplamTeskilatciSayisi ?? "0", page3, 725, 541, bold, h1)


    //4.SAYFA

    //Konya
    //Konya Üniversite Başkanı
    drawText(4, rap[41]?.kunye?.baskan ?? "", page4, 48.5, 40, font, h4)
    //Konya Üniversite Başkanı
    drawText(5, rap[41]?.kunye?.telefon ?? "", page4, 46.5, 47, font, h4)

    // Konya - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[41]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 47, bold, h4)
    //Konya - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[41]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 47, bold, h4)
    //Konya - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[41]?.kunye?.fakulteSayisi ?? "0", page4, 244, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 47, bold, h4)
    //Konya Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[41]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 47, bold, h4)
    //Konya MYO Sayı-Bşk - Kom
    drawText(5, rap[41]?.kunye?.myoSayisi ?? "0", page4, 401, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 47, bold, h4)
    //Konya Bölüm Sayı-Bşk - Kom
    drawText(5, rap[41]?.kunye?.bolumSayisi ?? "0", page4, 481, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 47, bold, h4)
    //Konya Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 47, bold, h4)
    //Konya Birebir İlgilenen-İlgilenilen
    drawText(5, rap[41]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 47, bold, h4)
    drawText(5, rap[41]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 47, bold, h4)
    //4.sayfa Konya - Bölge Başkanı
    drawText(4, rap[41]?.kunye?.bolgeBsk ?? "", page4, 46.5, 65, font, h4)
    //4.sayfa Konya - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[41]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 65, bold, h4)
    //4.sayfa Konya - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[41]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 65, bold, h4)
    //4.sayfa Konya - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 65, bold, h4)
    //4.sayfa Konya - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 65, bold, h4)
    //4.sayfa Konya - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 65, bold, h4)
    //4.sayfa Konya - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 65, bold, h4)
    //4.sayfa Konya - Özel Yurt  Sayi-Bşk
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 65, bold, h4)
    drawText(5, rap[41]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 65, bold, h4)
    //4.sayfa Konya - Toplam Öğr -Erkek Öğr
    drawText(5, rap[41]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 65, bold, h4)
    drawText(5, rap[41]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 65, bold, h4)
    // Konya - Teşkilatçı Sayısı
    drawText(7, rap[41]?.toplamTeskilatciSayisi ?? "0", page4, 725, 59, bold, h1)

    //Kütahya
    //Kütahya Üniversite Başkanı
    drawText(4, rap[42]?.kunye?.baskan ?? "", page4, 48.5, 77, font, h4)
    //Kütahya Üniversite Başkanı
    drawText(5, rap[42]?.kunye?.telefon ?? "", page4, 46.5, 84, font, h4)

    // Kütahya - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[42]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 84, bold, h4)
    //Kütahya - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[42]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 84, bold, h4)
    //Kütahya - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[42]?.kunye?.fakulteSayisi ?? "0", page4, 244, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 84, bold, h4)
    //Kütahya Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[42]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 84, bold, h4)
    //Kütahya MYO Sayı-Bşk - Kom
    drawText(5, rap[42]?.kunye?.myoSayisi ?? "0", page4, 401, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 84, bold, h4)
    //Kütahya Bölüm Sayı-Bşk - Kom
    drawText(5, rap[42]?.kunye?.bolumSayisi ?? "0", page4, 481, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 84, bold, h4)
    //Kütahya Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 84, bold, h4)
    //Kütahya Birebir İlgilenen-İlgilenilen
    drawText(5, rap[42]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 84, bold, h4)
    drawText(5, rap[42]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 84, bold, h4)
    //4.sayfa Kütahya - Bölge Başkanı
    drawText(4, rap[42]?.kunye?.bolgeBsk ?? "", page4, 46.5, 102, font, h4)
    //4.sayfa Kütahya - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[42]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 102, bold, h4)
    //4.sayfa Kütahya - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[42]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 102, bold, h4)
    //4.sayfa Kütahya - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 102, bold, h4)
    //4.sayfa Kütahya - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 102, bold, h4)
    //4.sayfa Kütahya - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 102, bold, h4)
    //4.sayfa Kütahya - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 102, bold, h4)
    //4.sayfa Kütahya - Özel Yurt  Sayi-Bşk
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 102, bold, h4)
    drawText(5, rap[42]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 102, bold, h4)
    //4.sayfa Kütahya - Toplam Öğr -Erkek Öğr
    drawText(5, rap[42]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 102, bold, h4)
    drawText(5, rap[42]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 102, bold, h4)
    // Kütahya - Teşkilatçı Sayısı
    drawText(7, rap[42]?.toplamTeskilatciSayisi ?? "0", page4, 725, 96, bold, h1)    


    //Malatya
    //Malatya Üniversite Başkanı
    drawText(4, rap[43]?.kunye?.baskan ?? "", page4, 48.5, 114, font, h4)
    //Malatya Üniversite Başkanı
    drawText(5, rap[43]?.kunye?.telefon ?? "", page4, 46.5, 121, font, h4)

    // Malatya - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[43]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 121, bold, h4)
    //Malatya - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[43]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 121, bold, h4)
    //Malatya - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[43]?.kunye?.fakulteSayisi ?? "0", page4, 244, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 121, bold, h4)
    //Malatya Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[43]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 121, bold, h4)
    //Malatya MYO Sayı-Bşk - Kom
    drawText(5, rap[43]?.kunye?.myoSayisi ?? "0", page4, 401, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 121, bold, h4)
    //Malatya Bölüm Sayı-Bşk - Kom
    drawText(5, rap[43]?.kunye?.bolumSayisi ?? "0", page4, 481, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 121, bold, h4)
    //Malatya Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 121, bold, h4)
    //Malatya Birebir İlgilenen-İlgilenilen
    drawText(5, rap[43]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 121, bold, h4)
    drawText(5, rap[43]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 121, bold, h4)
    //4.sayfa Malatya - Bölge Başkanı
    drawText(4, rap[43]?.kunye?.bolgeBsk ?? "", page4, 46.5, 139, font, h4)
    //4.sayfa Malatya - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[43]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 139, bold, h4)
    //4.sayfa Malatya - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[43]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 139, bold, h4)
    //4.sayfa Malatya - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 139, bold, h4)
    //4.sayfa Malatya - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 139, bold, h4)
    //4.sayfa Malatya - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 139, bold, h4)
    //4.sayfa Malatya - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 139, bold, h4)
    //4.sayfa Malatya - Özel Yurt  Sayi-Bşk
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 139, bold, h4)
    drawText(5, rap[43]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 139, bold, h4)
    //4.sayfa Malatya - Toplam Öğr -Erkek Öğr
    drawText(5, rap[43]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 139, bold, h4)
    drawText(5, rap[43]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 139, bold, h4)
    // Malatya - Teşkilatçı Sayısı
    drawText(7, rap[43]?.toplamTeskilatciSayisi ?? "0", page4, 725, 133, bold, h1)    

    //Manisa

    //Manisa Üniversite Başkanı
    drawText(4, rap[44]?.kunye?.baskan ?? "", page4, 48.5, 151, font, h4)
    //Manisa Üniversite Başkanı
    drawText(5, rap[44]?.kunye?.telefon ?? "", page4, 46.5, 158.5, font, h4)

    // Manisa - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[44]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 158.5, bold, h4)
    //Manisa - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[44]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 158.5, bold, h4)
    //Manisa - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[44]?.kunye?.fakulteSayisi ?? "0", page4, 244, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 158.5, bold, h4)
    //Manisa Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[44]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 158.5, bold, h4)
    //Manisa MYO Sayı-Bşk - Kom
    drawText(5, rap[44]?.kunye?.myoSayisi ?? "0", page4, 401, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 158.5, bold, h4)
    //Manisa Bölüm Sayı-Bşk - Kom
    drawText(5, rap[44]?.kunye?.bolumSayisi ?? "0", page4, 481, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 158.5, bold, h4)
    //Manisa Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 158.5, bold, h4)
    //Manisa Birebir İlgilenen-İlgilenilen
    drawText(5, rap[44]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 158.5, bold, h4)
    drawText(5, rap[44]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 158.5, bold, h4)
    //4.sayfa Manisa - Bölge Başkanı
    drawText(4, rap[44]?.kunye?.bolgeBsk ?? "", page4, 46.5, 176, font, h4)
    //4.sayfa Manisa - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[44]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 176, bold, h4)
    //4.sayfa Manisa - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[44]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 176, bold, h4)
    //4.sayfa Manisa - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 176, bold, h4)
    //4.sayfa Manisa - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 176, bold, h4)
    //4.sayfa Manisa - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 176, bold, h4)
    //4.sayfa Manisa - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 176, bold, h4)
    //4.sayfa Manisa - Özel Yurt  Sayi-Bşk
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 176, bold, h4)
    drawText(5, rap[44]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 176, bold, h4)
    //4.sayfa Manisa - Toplam Öğr -Erkek Öğr
    drawText(5, rap[44]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 176, bold, h4)
    drawText(5, rap[44]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 176, bold, h4)
    // Manisa - Teşkilatçı Sayısı
    drawText(7, rap[44]?.toplamTeskilatciSayisi ?? "0", page4, 725, 170, bold, h1)  

    //K.Maraş
    //K.Maraş Üniversite Başkanı
    drawText(4, rap[45]?.kunye?.baskan ?? "", page4, 48.5, 189, font, h4)
    //K.Maraş Üniversite Başkanı
    drawText(5, rap[45]?.kunye?.telefon ?? "", page4, 46.5, 196, font, h4)

    // K.Maraş - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[45]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 196, bold, h4)
    //K.Maraş - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[45]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 196, bold, h4)
    //K.Maraş - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[45]?.kunye?.fakulteSayisi ?? "0", page4, 244, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 196, bold, h4)
    //K.Maraş Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[45]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 196, bold, h4)
    //K.Maraş MYO Sayı-Bşk - Kom
    drawText(5, rap[45]?.kunye?.myoSayisi ?? "0", page4, 401, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 196, bold, h4)
    //K.Maraş Bölüm Sayı-Bşk - Kom
    drawText(5, rap[45]?.kunye?.bolumSayisi ?? "0", page4, 481, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 196, bold, h4)
    //K.Maraş Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 196, bold, h4)
    //K.Maraş Birebir İlgilenen-İlgilenilen
    drawText(5, rap[45]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 196, bold, h4)
    drawText(5, rap[45]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 196, bold, h4)
    //4.sayfa K.Maraş - Bölge Başkanı
    drawText(4, rap[45]?.kunye?.bolgeBsk ?? "", page4, 46.5, 214, font, h4)
    //4.sayfa K.Maraş - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[45]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 214, bold, h4)
    //4.sayfa K.Maraş - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[45]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 214, bold, h4)
    //4.sayfa K.Maraş - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 214, bold, h4)
    //4.sayfa K.Maraş - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 214, bold, h4)
    //4.sayfa K.Maraş - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 214, bold, h4)
    //4.sayfa K.Maraş - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 214, bold, h4)
    //4.sayfa K.Maraş - Özel Yurt  Sayi-Bşk
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 214, bold, h4)
    drawText(5, rap[45]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 214, bold, h4)
    //4.sayfa K.Maraş - Toplam Öğr -Erkek Öğr
    drawText(5, rap[45]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 214, bold, h4)
    drawText(5, rap[45]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 214, bold, h4)
    // K.maraş - Teşkilatçı Sayısı
    drawText(7, rap[45]?.toplamTeskilatciSayisi ?? "0", page4, 725, 208, bold, h1)  


    //Mardin
    //Mardin Üniversite Başkanı
    drawText(4, rap[46]?.kunye?.baskan ?? "", page4, 48.5, 226, font, h4)
    //Mardin Üniversite Başkanı
    drawText(5, rap[46]?.kunye?.telefon ?? "", page4, 46.5, 233.5, font, h4)

    // Mardin - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[46]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 233.5, bold, h4)
    //Mardin - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[46]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 233.5, bold, h4)
    //Mardin - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[46]?.kunye?.fakulteSayisi ?? "0", page4, 244, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 233.5, bold, h4)
    //Mardin Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[46]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 233.5, bold, h4)
    //Mardin MYO Sayı-Bşk - Kom
    drawText(5, rap[46]?.kunye?.myoSayisi ?? "0", page4, 401, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 233.5, bold, h4)
    //Mardin Bölüm Sayı-Bşk - Kom
    drawText(5, rap[46]?.kunye?.bolumSayisi ?? "0", page4, 481, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 233.5, bold, h4)
    //Mardin Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 233.5, bold, h4)
    //Mardin Birebir İlgilenen-İlgilenilen
    drawText(5, rap[46]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 233.5, bold, h4)
    drawText(5, rap[46]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 233.5, bold, h4)
    //4.sayfa Mardin - Bölge Başkanı
    drawText(4, rap[46]?.kunye?.bolgeBsk ?? "", page4, 46.5, 251, font, h4)
    //4.sayfa Mardin - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[46]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 251, bold, h4)
    //4.sayfa Mardin - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[46]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 251, bold, h4)
    //4.sayfa Mardin - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 251, bold, h4)
    //4.sayfa Mardin - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 251, bold, h4)
    //4.sayfa Mardin - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 251, bold, h4)
    //4.sayfa Mardin - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 251, bold, h4)
    //4.sayfa Mardin - Özel Yurt  Sayi-Bşk
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 251, bold, h4)
    drawText(5, rap[46]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 251, bold, h4)
    //4.sayfa Mardin - Toplam Öğr -Erkek Öğr
    drawText(5, rap[46]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 251, bold, h4)
    drawText(5, rap[46]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 251, bold, h4)
    // Mardin - Teşkilatçı Sayısı
    drawText(7, rap[46]?.toplamTeskilatciSayisi ?? "0", page4, 725, 245, bold, h1)  


    //Muğla
    //Muğla Üniversite Başkanı
    drawText(4, rap[47]?.kunye?.baskan ?? "", page4, 48.5, 263, font, h4)
    //Muğla Üniversite Başkanı
    drawText(5, rap[47]?.kunye?.telefon ?? "", page4, 46.5, 270.5, font, h4)

    // Muğla - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[47]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 270.5, bold, h4)
    //Muğla - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[47]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 270.5, bold, h4)
    //Muğla - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[47]?.kunye?.fakulteSayisi ?? "0", page4, 244, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 270.5, bold, h4)
    //Muğla Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[47]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 270.5, bold, h4)
    //Muğla MYO Sayı-Bşk - Kom
    drawText(5, rap[47]?.kunye?.myoSayisi ?? "0", page4, 401, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 270.5, bold, h4)
    //Muğla Bölüm Sayı-Bşk - Kom
    drawText(5, rap[47]?.kunye?.bolumSayisi ?? "0", page4, 481, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 270.5, bold, h4)
    //Muğla Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 270.5, bold, h4)
    //Muğla Birebir İlgilenen-İlgilenilen
    drawText(5, rap[47]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 270.5, bold, h4)
    drawText(5, rap[47]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 270.5, bold, h4)
    //4.sayfa Muğla - Bölge Başkanı
    drawText(4, rap[47]?.kunye?.bolgeBsk ?? "", page4, 46.5, 288, font, h4)
    //4.sayfa Muğla - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[47]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 288, bold, h4)
    //4.sayfa Muğla - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[47]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 288, bold, h4)
    //4.sayfa Muğla - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 288, bold, h4)
    //4.sayfa Muğla - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 288, bold, h4)
    //4.sayfa Muğla - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 288, bold, h4)
    //4.sayfa Muğla - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 288, bold, h4)
    //4.sayfa Muğla - Özel Yurt  Sayi-Bşk
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 288, bold, h4)
    drawText(5, rap[47]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 288, bold, h4)
    //4.sayfa Muğla - Toplam Öğr -Erkek Öğr
    drawText(5, rap[47]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 288, bold, h4)
    drawText(5, rap[47]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 288, bold, h4)
    // Muğla - Teşkilatçı Sayısı
    drawText(7, rap[47]?.toplamTeskilatciSayisi ?? "0", page4, 725, 282, bold, h1)  


    //Muş
    //Muş Üniversite Başkanı
    drawText(4, rap[48]?.kunye?.baskan ?? "", page4, 48.5, 301, font, h4)
    //Muş Üniversite Başkanı
    drawText(5, rap[48]?.kunye?.telefon ?? "", page4, 46.5, 308, font, h4)

    // Muş - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[48]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 308, bold, h4)
    //Muş - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[48]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 308, bold, h4)
    //Muş - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[48]?.kunye?.fakulteSayisi ?? "0", page4, 244, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 308, bold, h4)
    //Muş Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[48]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 308, bold, h4)
    //Muş MYO Sayı-Bşk - Kom
    drawText(5, rap[48]?.kunye?.myoSayisi ?? "0", page4, 401, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 308, bold, h4)
    //Muş Bölüm Sayı-Bşk - Kom
    drawText(5, rap[48]?.kunye?.bolumSayisi ?? "0", page4, 481, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 308, bold, h4)
    //Muş Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 308, bold, h4)
    //Muş Birebir İlgilenen-İlgilenilen
    drawText(5, rap[48]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 308, bold, h4)
    drawText(5, rap[48]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 308, bold, h4)
    //4.sayfa Muş - Bölge Başkanı
    drawText(4, rap[48]?.kunye?.bolgeBsk ?? "", page4, 46.5, 326, font, h4)
    //4.sayfa Muş - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[48]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 326, bold, h4)
    //4.sayfa Muş - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[48]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 326, bold, h4)
    //4.sayfa Muş - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 326, bold, h4)
    //4.sayfa Muş - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 326, bold, h4)
    //4.sayfa Muş - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 326, bold, h4)
    //4.sayfa Muş - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 326, bold, h4)
    //4.sayfa Muş - Özel Yurt  Sayi-Bşk
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 326, bold, h4)
    drawText(5, rap[48]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 326, bold, h4)
    //4.sayfa Muş - Toplam Öğr -Erkek Öğr
    drawText(5, rap[48]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 326, bold, h4)
    drawText(5, rap[48]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 326, bold, h4)
    // Muş - Teşkilatçı Sayısı
    drawText(7, rap[48]?.toplamTeskilatciSayisi ?? "0", page4, 725, 320, bold, h1)  


    //Nevşehir
    //Nevşehir Üniversite Başkanı
    drawText(4, rap[49]?.kunye?.baskan ?? "", page4, 48.5, 338, font, h4)
    //Nevşehir Üniversite Başkanı
    drawText(5, rap[49]?.kunye?.telefon ?? "", page4, 46.5, 345.5, font, h4)

    // Nevşehir - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[49]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 345.5, bold, h4)
    //Nevşehir - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[49]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 345.5, bold, h4)
    //Nevşehir - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[49]?.kunye?.fakulteSayisi ?? "0", page4, 244, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 345.5, bold, h4)
    //Nevşehir Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[49]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 345.5, bold, h4)
    //Nevşehir MYO Sayı-Bşk - Kom
    drawText(5, rap[49]?.kunye?.myoSayisi ?? "0", page4, 401, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 345.5, bold, h4)
    //Nevşehir Bölüm Sayı-Bşk - Kom
    drawText(5, rap[49]?.kunye?.bolumSayisi ?? "0", page4, 481, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 345.5, bold, h4)
    //Nevşehir Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 345.5, bold, h4)
    //Nevşehir Birebir İlgilenen-İlgilenilen
    drawText(5, rap[49]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 345.5, bold, h4)
    drawText(5, rap[49]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 345.5, bold, h4)
    //4.sayfa Nevşehir - Bölge Başkanı
    drawText(4, rap[49]?.kunye?.bolgeBsk ?? "", page4, 46.5, 363.5, font, h4)
    //4.sayfa Nevşehir - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[49]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 363.5, bold, h4)
    //4.sayfa Nevşehir - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[49]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 363.5, bold, h4)
    //4.sayfa Nevşehir - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 363.5, bold, h4)
    //4.sayfa Nevşehir - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 363.5, bold, h4)
    //4.sayfa Nevşehir - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 363.5, bold, h4)
    //4.sayfa Nevşehir - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 363.5, bold, h4)
    //4.sayfa Nevşehir - Özel Yurt  Sayi-Bşk
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 363.5, bold, h4)
    drawText(5, rap[49]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 363.5, bold, h4)
    //4.sayfa Nevşehir - Toplam Öğr -Erkek Öğr
    drawText(5, rap[49]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 363.5, bold, h4)
    drawText(5, rap[49]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 363.5, bold, h4)
    // Nevşehir - Teşkilatçı Sayısı
    drawText(7, rap[49]?.toplamTeskilatciSayisi ?? "0", page4, 725, 357.5, bold, h1)  


    //Niğde
    //Niğde Üniversite Başkanı
    drawText(4, rap[50]?.kunye?.baskan ?? "", page4, 48.5, 376, font, h4)
    //Niğde Üniversite Başkanı
    drawText(5, rap[50]?.kunye?.telefon ?? "", page4, 46.5, 383, font, h4)

    // Niğde - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[50]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 383, bold, h4)
    //Niğde - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[50]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 383, bold, h4)
    //Niğde - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[50]?.kunye?.fakulteSayisi ?? "0", page4, 244, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 383, bold, h4)
    //Niğde Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[50]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 383, bold, h4)
    //Niğde MYO Sayı-Bşk - Kom
    drawText(5, rap[50]?.kunye?.myoSayisi ?? "0", page4, 401, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 383, bold, h4)
    //Niğde Bölüm Sayı-Bşk - Kom
    drawText(5, rap[50]?.kunye?.bolumSayisi ?? "0", page4, 481, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 383, bold, h4)
    //Niğde Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 383, bold, h4)
    //Niğde Birebir İlgilenen-İlgilenilen
    drawText(5, rap[50]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 383, bold, h4)
    drawText(5, rap[50]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 383, bold, h4)
    //4.sayfa Niğde - Bölge Başkanı
    drawText(4, rap[50]?.kunye?.bolgeBsk ?? "", page4, 46.5, 401, font, h4)
    //4.sayfa Niğde - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[50]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 401, bold, h4)
    //4.sayfa Niğde - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[50]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 401, bold, h4)
    //4.sayfa Niğde - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 401, bold, h4)
    //4.sayfa Niğde - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 401, bold, h4)
    //4.sayfa Niğde - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 401, bold, h4)
    //4.sayfa Niğde - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 401, bold, h4)
    //4.sayfa Niğde - Özel Yurt  Sayi-Bşk
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 401, bold, h4)
    drawText(5, rap[50]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 401, bold, h4)
    //4.sayfa Niğde - Toplam Öğr -Erkek Öğr
    drawText(5, rap[50]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 401, bold, h4)
    drawText(5, rap[50]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 401, bold, h4)
    // Niğde - Teşkilatçı Sayısı
    drawText(7, rap[50]?.toplamTeskilatciSayisi ?? "0", page4, 725, 395, bold, h1)  


    //Ordu
    //Ordu Üniversite Başkanı
    drawText(4, rap[51]?.kunye?.baskan ?? "", page4, 48.5, 413, font, h4)
    //Ordu Üniversite Başkanı
    drawText(5, rap[51]?.kunye?.telefon ?? "", page4, 46.5, 420, font, h4)

    // Ordu - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[51]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 420, bold, h4)
    //Ordu - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[51]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 420, bold, h4)
    //Ordu - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[51]?.kunye?.fakulteSayisi ?? "0", page4, 244, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 420, bold, h4)
    //Ordu Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[51]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 420, bold, h4)
    //Ordu MYO Sayı-Bşk - Kom
    drawText(5, rap[51]?.kunye?.myoSayisi ?? "0", page4, 401, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 420, bold, h4)
    //Ordu Bölüm Sayı-Bşk - Kom
    drawText(5, rap[51]?.kunye?.bolumSayisi ?? "0", page4, 481, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 420, bold, h4)
    //Ordu Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 420, bold, h4)
    //Ordu Birebir İlgilenen-İlgilenilen
    drawText(5, rap[51]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 420, bold, h4)
    drawText(5, rap[51]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 420, bold, h4)
    //4.sayfa Ordu - Bölge Başkanı
    drawText(4, rap[51]?.kunye?.bolgeBsk ?? "", page4, 46.5, 438, font, h4)
    //4.sayfa Ordu - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[51]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 438, bold, h4)
    //4.sayfa Ordu - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[51]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 438, bold, h4)
    //4.sayfa Ordu - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 438, bold, h4)
    //4.sayfa Ordu - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 438, bold, h4)
    //4.sayfa Ordu - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 438, bold, h4)
    //4.sayfa Ordu - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 438, bold, h4)
    //4.sayfa Ordu - Özel Yurt  Sayi-Bşk
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 438, bold, h4)
    drawText(5, rap[51]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 438, bold, h4)
    //4.sayfa Ordu - Toplam Öğr -Erkek Öğr
    drawText(5, rap[51]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 438, bold, h4)
    drawText(5, rap[51]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 438, bold, h4)
    // Ordu - Teşkilatçı Sayısı
    drawText(7, rap[51]?.toplamTeskilatciSayisi ?? "0", page4, 725, 432, bold, h1)  


    //Rize
    //Rize Üniversite Başkanı
    drawText(4, rap[52]?.kunye?.baskan ?? "", page4, 48.5, 451, font, h4)
    //Rize Üniversite Başkanı
    drawText(5, rap[52]?.kunye?.telefon ?? "", page4, 46.5, 458, font, h4)

    // Rize - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[52]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 458, bold, h4)
    //Rize - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[52]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 458, bold, h4)
    //Rize - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[52]?.kunye?.fakulteSayisi ?? "0", page4, 244, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 458, bold, h4)
    //Rize Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[52]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 458, bold, h4)
    //Rize MYO Sayı-Bşk - Kom
    drawText(5, rap[52]?.kunye?.myoSayisi ?? "0", page4, 401, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 458, bold, h4)
    //Rize Bölüm Sayı-Bşk - Kom
    drawText(5, rap[52]?.kunye?.bolumSayisi ?? "0", page4, 481, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 458, bold, h4)
    //Rize Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 458, bold, h4)
    //Rize Birebir İlgilenen-İlgilenilen
    drawText(5, rap[52]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 458, bold, h4)
    drawText(5, rap[52]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 458, bold, h4)
    //4.sayfa Rize - Bölge Başkanı
    drawText(4, rap[52]?.kunye?.bolgeBsk ?? "", page4, 46.5, 476, font, h4)
    //4.sayfa Rize - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[52]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 476, bold, h4)
    //4.sayfa Rize - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[52]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 476, bold, h4)
    //4.sayfa Rize - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 476, bold, h4)
    //4.sayfa Rize - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 476, bold, h4)
    //4.sayfa Rize - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 476, bold, h4)
    //4.sayfa Rize - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 476, bold, h4)
    //4.sayfa Rize - Özel Yurt  Sayi-Bşk
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 476, bold, h4)
    drawText(5, rap[52]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 476, bold, h4)
    //4.sayfa Rize - Toplam Öğr -Erkek Öğr
    drawText(5, rap[52]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 476, bold, h4)
    drawText(5, rap[52]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 476, bold, h4)
    // Rize - Teşkilatçı Sayısı
    drawText(7, rap[52]?.toplamTeskilatciSayisi ?? "0", page4, 725, 470, bold, h1)  



    //Sakarya
    //Sakarya Üniversite Başkanı
    drawText(4, rap[53]?.kunye?.baskan ?? "", page4, 48.5, 488, font, h4)
    //Sakarya Üniversite Başkanı
    drawText(5, rap[53]?.kunye?.telefon ?? "", page4, 46.5, 495, font, h4)

    // Sakarya - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[53]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 495, bold, h4)
    //Sakarya - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[53]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 495, bold, h4)
    //Sakarya - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[53]?.kunye?.fakulteSayisi ?? "0", page4, 244, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 495, bold, h4)
    //Sakarya Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[53]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 495, bold, h4)
    //Sakarya MYO Sayı-Bşk - Kom
    drawText(5, rap[53]?.kunye?.myoSayisi ?? "0", page4, 401, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 495, bold, h4)
    //Sakarya Bölüm Sayı-Bşk - Kom
    drawText(5, rap[53]?.kunye?.bolumSayisi ?? "0", page4, 481, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 495, bold, h4)
    //Sakarya Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 495, bold, h4)
    //Sakarya Birebir İlgilenen-İlgilenilen
    drawText(5, rap[53]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 495, bold, h4)
    drawText(5, rap[53]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 495, bold, h4)
    //4.sayfa Sakarya - Bölge Başkanı
    drawText(4, rap[53]?.kunye?.bolgeBsk ?? "", page4, 46.5, 513, font, h4)
    //4.sayfa Sakarya - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[53]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 513, bold, h4)
    //4.sayfa Sakarya - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[53]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 513, bold, h4)
    //4.sayfa Sakarya - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 513, bold, h4)
    //4.sayfa Sakarya - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 513, bold, h4)
    //4.sayfa Sakarya - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 513, bold, h4)
    //4.sayfa Sakarya - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 513, bold, h4)
    //4.sayfa Sakarya - Özel Yurt  Sayi-Bşk
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 513, bold, h4)
    drawText(5, rap[53]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 513, bold, h4)
    //4.sayfa Sakarya - Toplam Öğr -Erkek Öğr
    drawText(5, rap[53]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 513, bold, h4)
    drawText(5, rap[53]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 513, bold, h4)
    // Sakarya - Teşkilatçı Sayısı
    drawText(7, rap[53]?.toplamTeskilatciSayisi ?? "0", page4, 725, 507, bold, h1)  

    //Samsun
    //Samsun Üniversite Başkanı
    drawText(4, rap[54]?.kunye?.baskan ?? "", page4, 48.5, 525, font, h4)
    //Samsun Üniversite Başkanı
    drawText(5, rap[54]?.kunye?.telefon ?? "", page4, 46.5, 533, font, h4)

    // Samsun - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[54]?.rapor?.icmal?.subeUniBsk ?? "0", page4, 98, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.subeUniKom ?? "0", page4, 123, 533, bold, h4)
    //Samsun - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[54]?.kunye?.temsilciUniSayisi ?? "0", page4, 151, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.temsilciUniBsk ?? "0", page4, 179, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.temsilciUniKom ?? "0", page4, 211, 533, bold, h4)
    //Samsun - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[54]?.kunye?.fakulteSayisi ?? "0", page4, 244, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.fakulteBsk ?? "0", page4, 266, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.fakulteKom ?? "0", page4, 296, 533, bold, h4)
    //Samsun Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[54]?.kunye?.yuksekokulSayisi ?? "0", page4, 324, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.yuksekokulBsk ?? "0", page4, 349, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.yuksekokulKom ?? "0", page4, 374, 533, bold, h4)
    //Samsun MYO Sayı-Bşk - Kom
    drawText(5, rap[54]?.kunye?.myoSayisi ?? "0", page4, 401, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.myoBsk ?? "0", page4, 429, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.myoKom ?? "0", page4, 455, 533, bold, h4)
    //Samsun Bölüm Sayı-Bşk - Kom
    drawText(5, rap[54]?.kunye?.bolumSayisi ?? "0", page4, 481, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.bolumBsk ?? "0", page4, 506, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.bolumKom ?? "0", page4, 531, 533, bold, h4)
    //Samsun Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page4, 559, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page4, 581, 533, bold, h4)
    //Samsun Birebir İlgilenen-İlgilenilen
    drawText(5, rap[54]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page4, 613, 533, bold, h4)
    drawText(5, rap[54]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page4, 656, 533, bold, h4)
    //4.sayfa Samsun - Bölge Başkanı
    drawText(4, rap[54]?.kunye?.bolgeBsk ?? "", page4, 46.5, 551, font, h4)
    //4.sayfa Samsun - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[54]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page4, 98, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page4, 123, 551, bold, h4)
    //4.sayfa Samsun - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[54]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page4, 151, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page4, 179, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page4, 211, 551, bold, h4)
    //4.sayfa Samsun - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page4, 244, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page4, 266, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page4, 296, 551, bold, h4)
    //4.sayfa Samsun - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page4, 324, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page4, 349, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page4, 374, 551, bold, h4)
    //4.sayfa Samsun - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page4, 401, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page4, 429, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page4, 454, 551, bold, h4)
    //4.sayfa Samsun - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page4, 481, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page4, 506, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page4, 531, 551, bold, h4)
    //4.sayfa Samsun - Özel Yurt  Sayi-Bşk
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page4, 559, 551, bold, h4)
    drawText(5, rap[54]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page4, 581, 551, bold, h4)
    //4.sayfa Samsun - Toplam Öğr -Erkek Öğr
    drawText(5, rap[54]?.kunye?.toplamOgrSayisi ?? "0", page4, 613, 551, bold, h4)
    drawText(5, rap[54]?.kunye?.erkekOgrSayisi ?? "0", page4, 656, 551, bold, h4)
    // Samsun - Teşkilatçı Sayısı
    drawText(7, rap[54]?.toplamTeskilatciSayisi ?? "0", page4, 725, 545, bold, h1)  



    //5.SAYFA

    //Siirt
    //Siirt Üniversite Başkanı
    drawText(4, rap[55]?.kunye?.baskan ?? "", page5, 48.5, 35, font, h5)
    //Siirt Üniversite Başkanı
    drawText(5, rap[55]?.kunye?.telefon ?? "", page5, 46.5, 42, font, h5)

    // Siirt - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[55]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 42, bold, h5)
    //Siirt - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[55]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 42, bold, h5)
    //Siirt - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[55]?.kunye?.fakulteSayisi ?? "0", page5, 244, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 42, bold, h5)
    //Siirt Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[55]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 42, bold, h5)
    //Siirt MYO Sayı-Bşk - Kom
    drawText(5, rap[55]?.kunye?.myoSayisi ?? "0", page5, 401, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 42, bold, h5)
    //Siirt Bölüm Sayı-Bşk - Kom
    drawText(5, rap[55]?.kunye?.bolumSayisi ?? "0", page5, 481, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 42, bold, h5)
    //Siirt Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 42, bold, h5)
    //Siirt Birebir İlgilenen-İlgilenilen
    drawText(5, rap[55]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 42, bold, h5)
    drawText(5, rap[55]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 42, bold, h5)
    //5.sayfa Siirt - Bölge Başkanı
    drawText(4, rap[55]?.kunye?.bolgeBsk ?? "", page5, 46.5, 60, font, h5)
    //5.sayfa Siirt - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[55]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 60, bold, h5)
    //5.sayfa Siirt - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[55]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 60, bold, h5)
    //5.sayfa Siirt - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 60, bold, h5)
    //5.sayfa Siirt - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 60, bold, h5)
    //5.sayfa Siirt - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 60, bold, h5)
    //5.sayfa Siirt - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 60, bold, h5)
    //5.sayfa Siirt - Özel Yurt  Sayi-Bşk
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 60, bold, h5)
    drawText(5, rap[55]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 60, bold, h5)
    //5.sayfa Siirt - Toplam Öğr -Erkek Öğr
    drawText(5, rap[55]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 60, bold, h5)
    drawText(5, rap[55]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 60, bold, h5)
    // Siirt - Teşkilatçı Sayısı
    drawText(7, rap[55]?.toplamTeskilatciSayisi ?? "0", page5, 725, 54, bold, h1)  


    //Sinop
    //Sinop Üniversite Başkanı
    drawText(4, rap[56]?.kunye?.baskan ?? "", page5, 48.5, 72, font, h5)
    //Sinop Üniversite Başkanı
    drawText(5, rap[56]?.kunye?.telefon ?? "", page5, 46.5, 79, font, h5)

    // Sinop - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[56]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 79, bold, h5)
    //Sinop - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[56]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 79, bold, h5)
    //Sinop - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[56]?.kunye?.fakulteSayisi ?? "0", page5, 244, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 79, bold, h5)
    //Sinop Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[56]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 79, bold, h5)
    //Sinop MYO Sayı-Bşk - Kom
    drawText(5, rap[56]?.kunye?.myoSayisi ?? "0", page5, 401, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 79, bold, h5)
    //Sinop Bölüm Sayı-Bşk - Kom
    drawText(5, rap[56]?.kunye?.bolumSayisi ?? "0", page5, 481, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 79, bold, h5)
    //Sinop Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 79, bold, h5)
    //Sinop Birebir İlgilenen-İlgilenilen
    drawText(5, rap[56]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 79, bold, h5)
    drawText(5, rap[56]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 79, bold, h5)
    //5.sayfa Sinop - Bölge Başkanı
    drawText(4, rap[56]?.kunye?.bolgeBsk ?? "", page5, 46.5, 98, font, h5)
    //5.sayfa Sinop - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[56]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 98, bold, h5)
    //5.sayfa Sinop - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[56]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 98, bold, h5)
    //5.sayfa Sinop - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 98, bold, h5)
    //5.sayfa Sinop - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 98, bold, h5)
    //5.sayfa Sinop - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 98, bold, h5)
    //5.sayfa Sinop - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 98, bold, h5)
    //5.sayfa Sinop - Özel Yurt  Sayi-Bşk
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 98, bold, h5)
    drawText(5, rap[56]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 98, bold, h5)
    //5.sayfa Sinop - Toplam Öğr -Erkek Öğr
    drawText(5, rap[56]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 98, bold, h5)
    drawText(5, rap[56]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 98, bold, h5)
    // Sinop - Teşkilatçı Sayısı
    drawText(7, rap[56]?.toplamTeskilatciSayisi ?? "0", page5, 725, 92, bold, h1)  

    //Sivas
    //Sivas Üniversite Başkanı
    drawText(4, rap[57]?.kunye?.baskan ?? "", page5, 48.5, 110, font, h5)
    //Sivas Üniversite Başkanı Tel
    drawText(5, rap[57]?.kunye?.telefon ?? "", page5, 46.5, 117, font, h5)

    // Sivas - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[57]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 117, bold, h5)
    //Sivas - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[57]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 117, bold, h5)
    //Sivas - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[57]?.kunye?.fakulteSayisi ?? "0", page5, 244, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 117, bold, h5)
    //Sivas Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[57]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 117, bold, h5)
    //Sivas MYO Sayı-Bşk - Kom
    drawText(5, rap[57]?.kunye?.myoSayisi ?? "0", page5, 401, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 117, bold, h5)
    //Sivas Bölüm Sayı-Bşk - Kom
    drawText(5, rap[57]?.kunye?.bolumSayisi ?? "0", page5, 481, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 117, bold, h5)
    //Sivas Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 117, bold, h5)
    //Sivas Birebir İlgilenen-İlgilenilen
    drawText(5, rap[57]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 117, bold, h5)
    drawText(5, rap[57]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 117, bold, h5)
    //5.sayfa Sivas - Bölge Başkanı
    drawText(4, rap[57]?.kunye?.bolgeBsk ?? "", page5, 46.5, 135, font, h5)
    //5.sayfa Sivas - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[57]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 135, bold, h5)
    //5.sayfa Sivas - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[57]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 135, bold, h5)
    //5.sayfa Sivas - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 135, bold, h5)
    //5.sayfa Sivas - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 135, bold, h5)
    //5.sayfa Sivas - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 135, bold, h5)
    //5.sayfa Sivas - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 135, bold, h5)
    //5.sayfa Sivas - Özel Yurt  Sayi-Bşk
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 135, bold, h5)
    drawText(5, rap[57]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 135, bold, h5)
    //5.sayfa Sivas - Toplam Öğr -Erkek Öğr
    drawText(5, rap[57]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 135, bold, h5)
    drawText(5, rap[57]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 135, bold, h5)
    // Sivas - Teşkilatçı Sayısı
    drawText(7, rap[57]?.toplamTeskilatciSayisi ?? "0", page5, 725, 129, bold, h1)  


    //Tekirdağ
    //Tekirdağ Üniversite Başkanı
    drawText(4, rap[58]?.kunye?.baskan ?? "", page5, 48.5, 147, font, h5)
    //Tekirdağ Üniversite Başkanı Tel
    drawText(5, rap[58]?.kunye?.telefon ?? "", page5, 46.5, 154, font, h5)

    // Tekirdağ - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[58]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 154, bold, h5)
    //Tekirdağ - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[58]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 154, bold, h5)
    //Tekirdağ - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[58]?.kunye?.fakulteSayisi ?? "0", page5, 244, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 154, bold, h5)
    //Tekirdağ Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[58]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 154, bold, h5)
    //Tekirdağ MYO Sayı-Bşk - Kom
    drawText(5, rap[58]?.kunye?.myoSayisi ?? "0", page5, 401, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 154, bold, h5)
    //Tekirdağ Bölüm Sayı-Bşk - Kom
    drawText(5, rap[58]?.kunye?.bolumSayisi ?? "0", page5, 481, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 154, bold, h5)
    //Tekirdağ Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 154, bold, h5)
    //Tekirdağ Birebir İlgilenen-İlgilenilen
    drawText(5, rap[58]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 154, bold, h5)
    drawText(5, rap[58]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 154, bold, h5)
    //5.sayfa Tekirdağ - Bölge Başkanı
    drawText(4, rap[58]?.kunye?.bolgeBsk ?? "", page5, 46.5, 173, font, h5)
    //5.sayfa Tekirdağ - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[58]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 173, bold, h5)
    //5.sayfa Tekirdağ - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[58]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 173, bold, h5)
    //5.sayfa Tekirdağ - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 173, bold, h5)
    //5.sayfa Tekirdağ - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 173, bold, h5)
    //5.sayfa Tekirdağ - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 173, bold, h5)
    //5.sayfa Tekirdağ - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 173, bold, h5)
    //5.sayfa Tekirdağ - Özel Yurt  Sayi-Bşk
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 173, bold, h5)
    drawText(5, rap[58]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 173, bold, h5)
    //5.sayfa Tekirdağ - Toplam Öğr -Erkek Öğr
    drawText(5, rap[58]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 173, bold, h5)
    drawText(5, rap[58]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 173, bold, h5)
    // Tekirdağ - Teşkilatçı Sayısı
    drawText(7, rap[58]?.toplamTeskilatciSayisi ?? "0", page5, 725, 167, bold, h1)  


    //Tokat
    //Tokat Üniversite Başkanı
    drawText(4, rap[59]?.kunye?.baskan ?? "", page5, 48.5, 185, font, h5)
    //Tokat Üniversite Başkanı Tel
    drawText(5, rap[59]?.kunye?.telefon ?? "", page5, 46.5, 192, font, h5)

    // Tokat - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[59]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 192, bold, h5)
    //Tokat - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[59]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 192, bold, h5)
    //Tokat - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[59]?.kunye?.fakulteSayisi ?? "0", page5, 244, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 192, bold, h5)
    //Tokat Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[59]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 192, bold, h5)
    //Tokat MYO Sayı-Bşk - Kom
    drawText(5, rap[59]?.kunye?.myoSayisi ?? "0", page5, 401, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 192, bold, h5)
    //Tokat Bölüm Sayı-Bşk - Kom
    drawText(5, rap[59]?.kunye?.bolumSayisi ?? "0", page5, 481, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 192, bold, h5)
    //Tokat Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 192, bold, h5)
    //Tokat Birebir İlgilenen-İlgilenilen
    drawText(5, rap[59]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 192, bold, h5)
    drawText(5, rap[59]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 192, bold, h5)
    //5.sayfa Tokat - Bölge Başkanı
    drawText(4, rap[59]?.kunye?.bolgeBsk ?? "", page5, 46.5, 210, font, h5)
    //5.sayfa Tokat - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[59]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 210, bold, h5)
    //5.sayfa Tokat - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[59]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 210, bold, h5)
    //5.sayfa Tokat - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 210, bold, h5)
    //5.sayfa Tokat - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 210, bold, h5)
    //5.sayfa Tokat - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 210, bold, h5)
    //5.sayfa Tokat - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 210, bold, h5)
    //5.sayfa Tokat - Özel Yurt  Sayi-Bşk
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 210, bold, h5)
    drawText(5, rap[59]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 210, bold, h5)
    //5.sayfa Tokat - Toplam Öğr -Erkek Öğr
    drawText(5, rap[59]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 210, bold, h5)
    drawText(5, rap[59]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 210, bold, h5)
    // Tokat - Teşkilatçı Sayısı
    drawText(7, rap[59]?.toplamTeskilatciSayisi ?? "0", page5, 725, 204, bold, h1)  


    //Trabzon
    //Trabzon Üniversite Başkanı
    drawText(4, rap[60]?.kunye?.baskan ?? "", page5, 48.5, 223, font, h5)
    //Trabzon Üniversite Başkanı Tel
    drawText(5, rap[60]?.kunye?.telefon ?? "", page5, 46.5, 229, font, h5)

    // Trabzon - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[60]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 229, bold, h5)
    //Trabzon - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[60]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 229, bold, h5)
    //Trabzon - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[60]?.kunye?.fakulteSayisi ?? "0", page5, 244, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 229, bold, h5)
    //Trabzon Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[60]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 229, bold, h5)
    //Trabzon MYO Sayı-Bşk - Kom
    drawText(5, rap[60]?.kunye?.myoSayisi ?? "0", page5, 401, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 229, bold, h5)
    //Trabzon Bölüm Sayı-Bşk - Kom
    drawText(5, rap[60]?.kunye?.bolumSayisi ?? "0", page5, 481, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 229, bold, h5)
    //Trabzon Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 229, bold, h5)
    //Trabzon Birebir İlgilenen-İlgilenilen
    drawText(5, rap[60]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 229, bold, h5)
    drawText(5, rap[60]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 229, bold, h5)
    //5.sayfa Trabzon - Bölge Başkanı
    drawText(4, rap[60]?.kunye?.bolgeBsk ?? "", page5, 46.5, 248, font, h5)
    //5.sayfa Trabzon - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[60]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 248, bold, h5)
    //5.sayfa Trabzon - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[60]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 248, bold, h5)
    //5.sayfa Trabzon - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 248, bold, h5)
    //5.sayfa Trabzon - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 248, bold, h5)
    //5.sayfa Trabzon - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 248, bold, h5)
    //5.sayfa Trabzon - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 248, bold, h5)
    //5.sayfa Trabzon - Özel Yurt  Sayi-Bşk
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 248, bold, h5)
    drawText(5, rap[60]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 248, bold, h5)
    //5.sayfa Trabzon - Toplam Öğr -Erkek Öğr
    drawText(5, rap[60]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 248, bold, h5)
    drawText(5, rap[60]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 248, bold, h5)
    // Trabzon - Teşkilatçı Sayısı
    drawText(7, rap[60]?.toplamTeskilatciSayisi ?? "0", page5, 725, 242, bold, h1)  


    //Tunceli
    //Tunceli Üniversite Başkanı
    drawText(4, rap[61]?.kunye?.baskan ?? "", page5, 48.5, 260, font, h5)
    //Tunceli Üniversite Başkanı Tel
    drawText(5, rap[61]?.kunye?.telefon ?? "", page5, 46.5, 267, font, h5)

    // Tunceli - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[61]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 267, bold, h5)
    //Tunceli - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[61]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 267, bold, h5)
    //Tunceli - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[61]?.kunye?.fakulteSayisi ?? "0", page5, 244, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 267, bold, h5)
    //Tunceli Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[61]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 267, bold, h5)
    //Tunceli MYO Sayı-Bşk - Kom
    drawText(5, rap[61]?.kunye?.myoSayisi ?? "0", page5, 401, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 267, bold, h5)
    //Tunceli Bölüm Sayı-Bşk - Kom
    drawText(5, rap[61]?.kunye?.bolumSayisi ?? "0", page5, 481, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 267, bold, h5)
    //Tunceli Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 267, bold, h5)
    //Tunceli Birebir İlgilenen-İlgilenilen
    drawText(5, rap[61]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 267, bold, h5)
    drawText(5, rap[61]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 267, bold, h5)
    //5.sayfa Tunceli - Bölge Başkanı
    drawText(4, rap[61]?.kunye?.bolgeBsk ?? "", page5, 46.5, 285, font, h5)
    //5.sayfa Tunceli - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[61]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 285, bold, h5)
    //5.sayfa Tunceli - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[61]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 285, bold, h5)
    //5.sayfa Tunceli - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 285, bold, h5)
    //5.sayfa Tunceli - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 285, bold, h5)
    //5.sayfa Tunceli - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 285, bold, h5)
    //5.sayfa Tunceli - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 285, bold, h5)
    //5.sayfa Tunceli - Özel Yurt  Sayi-Bşk
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 285, bold, h5)
    drawText(5, rap[61]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 285, bold, h5)
    //5.sayfa Tunceli - Toplam Öğr -Erkek Öğr
    drawText(5, rap[61]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 285, bold, h5)
    drawText(5, rap[61]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 285, bold, h5)
    // Tunceli - Teşkilatçı Sayısı
    drawText(7, rap[61]?.toplamTeskilatciSayisi ?? "0", page5, 725, 279, bold, h1)  

    //Şanlıurfa
    //Şanlıurfa Üniversite Başkanı
    drawText(4, rap[62]?.kunye?.baskan ?? "", page5, 48.5, 297, font, h5)
    //Şanlıurfa Üniversite Başkanı Tel
    drawText(5, rap[62]?.kunye?.telefon ?? "", page5, 46.5, 304, font, h5)

    // Şanlıurfa - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[62]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 304, bold, h5)
    //Şanlıurfa - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[62]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 304, bold, h5)
    //Şanlıurfa - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[62]?.kunye?.fakulteSayisi ?? "0", page5, 244, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 304, bold, h5)
    //Şanlıurfa Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[62]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 304, bold, h5)
    //Şanlıurfa MYO Sayı-Bşk - Kom
    drawText(5, rap[62]?.kunye?.myoSayisi ?? "0", page5, 401, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 304, bold, h5)
    //Şanlıurfa Bölüm Sayı-Bşk - Kom
    drawText(5, rap[62]?.kunye?.bolumSayisi ?? "0", page5, 481, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 304, bold, h5)
    //Şanlıurfa Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 304, bold, h5)
    //Şanlıurfa Birebir İlgilenen-İlgilenilen
    drawText(5, rap[62]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 304, bold, h5)
    drawText(5, rap[62]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 304, bold, h5)
    //5.sayfa Şanlıurfa - Bölge Başkanı
    drawText(4, rap[62]?.kunye?.bolgeBsk ?? "", page5, 46.5, 323, font, h5)
    //5.sayfa Şanlıurfa - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[62]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 323, bold, h5)
    //5.sayfa Şanlıurfa - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[62]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 323, bold, h5)
    //5.sayfa Şanlıurfa - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 323, bold, h5)
    //5.sayfa Şanlıurfa - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 323, bold, h5)
    //5.sayfa Şanlıurfa - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 323, bold, h5)
    //5.sayfa Şanlıurfa - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 323, bold, h5)
    //5.sayfa Şanlıurfa - Özel Yurt  Sayi-Bşk
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 323, bold, h5)
    drawText(5, rap[62]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 323, bold, h5)
    //5.sayfa Şanlıurfa - Toplam Öğr -Erkek Öğr
    drawText(5, rap[62]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 323, bold, h5)
    drawText(5, rap[62]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 323, bold, h5)
    // Şanlıurfa - Teşkilatçı Sayısı
    drawText(7, rap[62]?.toplamTeskilatciSayisi ?? "0", page5, 725, 317, bold, h1)  

    //Uşak
    //Uşak Üniversite Başkanı
    drawText(4, rap[63]?.kunye?.baskan ?? "", page5, 48.5, 335, font, h5)
    //Uşak Üniversite Başkanı Tel
    drawText(5, rap[63]?.kunye?.telefon ?? "", page5, 46.5, 342, font, h5)

    // Uşak - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[63]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 342, bold, h5)
    //Uşak - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[63]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 342, bold, h5)
    //Uşak - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[63]?.kunye?.fakulteSayisi ?? "0", page5, 244, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 342, bold, h5)
    //Uşak Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[63]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 342, bold, h5)
    //Uşak MYO Sayı-Bşk - Kom
    drawText(5, rap[63]?.kunye?.myoSayisi ?? "0", page5, 401, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 342, bold, h5)
    //Uşak Bölüm Sayı-Bşk - Kom
    drawText(5, rap[63]?.kunye?.bolumSayisi ?? "0", page5, 481, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 342, bold, h5)
    //Uşak Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 342, bold, h5)
    //Uşak Birebir İlgilenen-İlgilenilen
    drawText(5, rap[63]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 342, bold, h5)
    drawText(5, rap[63]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 342, bold, h5)
    //5.sayfa Uşak - Bölge Başkanı
    drawText(4, rap[63]?.kunye?.bolgeBsk ?? "", page5, 46.5, 360, font, h5)
    //5.sayfa Uşak - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[63]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 360, bold, h5)
    //5.sayfa Uşak - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[63]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 360, bold, h5)
    //5.sayfa Uşak - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 360, bold, h5)
    //5.sayfa Uşak - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 360, bold, h5)
    //5.sayfa Uşak - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 360, bold, h5)
    //5.sayfa Uşak - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 360, bold, h5)
    //5.sayfa Uşak - Özel Yurt  Sayi-Bşk
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 360, bold, h5)
    drawText(5, rap[63]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 360, bold, h5)
    //5.sayfa Uşak - Toplam Öğr -Erkek Öğr
    drawText(5, rap[63]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 360, bold, h5)
    drawText(5, rap[63]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 360, bold, h5)
    // Siirt - Teşkilatçı Sayısı
    drawText(7, rap[63]?.toplamTeskilatciSayisi ?? "0", page5, 725, 354, bold, h1)  


    //Van
    //Van Üniversite Başkanı
    drawText(4, rap[64]?.kunye?.baskan ?? "", page5, 48.5, 372, font, h5)
    //Van Üniversite Başkanı Tel
    drawText(5, rap[64]?.kunye?.telefon ?? "", page5, 46.5, 379, font, h5)

    // Van - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[64]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 379, bold, h5)
    //Van - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[64]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 379, bold, h5)
    //Van - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[64]?.kunye?.fakulteSayisi ?? "0", page5, 244, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 379, bold, h5)
    //Van Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[64]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 379, bold, h5)
    //Van MYO Sayı-Bşk - Kom
    drawText(5, rap[64]?.kunye?.myoSayisi ?? "0", page5, 401, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 379, bold, h5)
    //Van Bölüm Sayı-Bşk - Kom
    drawText(5, rap[64]?.kunye?.bolumSayisi ?? "0", page5, 481, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 379, bold, h5)
    //Van Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 379, bold, h5)
    //Van Birebir İlgilenen-İlgilenilen
    drawText(5, rap[64]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 379, bold, h5)
    drawText(5, rap[64]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 379, bold, h5)
    //5.sayfa Van - Bölge Başkanı
    drawText(4, rap[64]?.kunye?.bolgeBsk ?? "", page5, 46.5, 397, font, h5)
    //5.sayfa Van - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[64]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 397, bold, h5)
    //5.sayfa Van - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[64]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 397, bold, h5)
    //5.sayfa Van - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 397, bold, h5)
    //5.sayfa Van - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 397, bold, h5)
    //5.sayfa Van - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 397, bold, h5)
    //5.sayfa Van - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 397, bold, h5)
    //5.sayfa Van - Özel Yurt  Sayi-Bşk
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 397, bold, h5)
    drawText(5, rap[64]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 397, bold, h5)
    //5.sayfa Van - Toplam Öğr -Erkek Öğr
    drawText(5, rap[64]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 397, bold, h5)
    drawText(5, rap[64]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 397, bold, h5)
    // Van - Teşkilatçı Sayısı
    drawText(7, rap[64]?.toplamTeskilatciSayisi ?? "0", page5, 725, 391, bold, h1)  

    //Yozgat
    //Yozgat Üniversite Başkanı
    drawText(4, rap[65]?.kunye?.baskan ?? "", page5, 48.5, 410, font, h5)
    //Yozgat Üniversite Başkanı Tel
    drawText(5, rap[65]?.kunye?.telefon ?? "", page5, 46.5, 417, font, h5)

    // Yozgat - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[65]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 417, bold, h5)
    //Yozgat - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[65]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 417, bold, h5)
    //Yozgat - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[65]?.kunye?.fakulteSayisi ?? "0", page5, 244, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 417, bold, h5)
    //Yozgat Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[65]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 417, bold, h5)
    //Yozgat MYO Sayı-Bşk - Kom
    drawText(5, rap[65]?.kunye?.myoSayisi ?? "0", page5, 401, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 417, bold, h5)
    //Yozgat Bölüm Sayı-Bşk - Kom
    drawText(5, rap[65]?.kunye?.bolumSayisi ?? "0", page5, 481, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 417, bold, h5)
    //Yozgat Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 417, bold, h5)
    //Yozgat Birebir İlgilenen-İlgilenilen
    drawText(5, rap[65]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 417, bold, h5)
    drawText(5, rap[65]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 417, bold, h5)
    //5.sayfa Yozgat - Bölge Başkanı
    drawText(4, rap[65]?.kunye?.bolgeBsk ?? "", page5, 46.5, 435, font, h5)
    //5.sayfa Yozgat - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[65]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 435, bold, h5)
    //5.sayfa Yozgat - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[65]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 435, bold, h5)
    //5.sayfa Yozgat - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 435, bold, h5)
    //5.sayfa Yozgat - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 435, bold, h5)
    //5.sayfa Yozgat - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 435, bold, h5)
    //5.sayfa Yozgat - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 435, bold, h5)
    //5.sayfa Yozgat - Özel Yurt  Sayi-Bşk
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 435, bold, h5)
    drawText(5, rap[65]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 435, bold, h5)
    //5.sayfa Yozgat - Toplam Öğr -Erkek Öğr
    drawText(5, rap[65]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 435, bold, h5)
    drawText(5, rap[65]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 435, bold, h5)
    // Yozgat - Teşkilatçı Sayısı
    drawText(7, rap[65]?.toplamTeskilatciSayisi ?? "0", page5, 725, 429, bold, h1)  



    //Zonguldak
    //Zonguldak Üniversite Başkanı
    drawText(4, rap[66]?.kunye?.baskan ?? "", page5, 48.5, 448, font, h5)
    //Zonguldak Üniversite Başkanı Tel
    drawText(5, rap[66]?.kunye?.telefon ?? "", page5, 46.5, 455, font, h5)

    // Zonguldak - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[66]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 455, bold, h5)
    //Zonguldak - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[66]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 455, bold, h5)
    //Zonguldak - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[66]?.kunye?.fakulteSayisi ?? "0", page5, 244, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 455, bold, h5)
    //Zonguldak Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[66]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 455, bold, h5)
    //Zonguldak MYO Sayı-Bşk - Kom
    drawText(5, rap[66]?.kunye?.myoSayisi ?? "0", page5, 401, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 455, bold, h5)
    //Zonguldak Bölüm Sayı-Bşk - Kom
    drawText(5, rap[66]?.kunye?.bolumSayisi ?? "0", page5, 481, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 455, bold, h5)
    //Zonguldak Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 455, bold, h5)
    //Zonguldak Birebir İlgilenen-İlgilenilen
    drawText(5, rap[66]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 455, bold, h5)
    drawText(5, rap[66]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 455, bold, h5)
    //5.sayfa Zonguldak - Bölge Başkanı
    drawText(4, rap[66]?.kunye?.bolgeBsk ?? "", page5, 46.5, 473, font, h5)
    //5.sayfa Zonguldak - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[66]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 473, bold, h5)
    //5.sayfa Zonguldak - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[66]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 473, bold, h5)
    //5.sayfa Zonguldak - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 473, bold, h5)
    //5.sayfa Zonguldak - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 473, bold, h5)
    //5.sayfa Zonguldak - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 473, bold, h5)
    //5.sayfa Zonguldak - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 473, bold, h5)
    //5.sayfa Zonguldak - Özel Yurt  Sayi-Bşk
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 473, bold, h5)
    drawText(5, rap[66]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 473, bold, h5)
    //5.sayfa Zonguldak - Toplam Öğr -Erkek Öğr
    drawText(5, rap[66]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 473, bold, h5)
    drawText(5, rap[66]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 473, bold, h5)
    // Zonguldak - Teşkilatçı Sayısı
    drawText(7, rap[66]?.toplamTeskilatciSayisi ?? "0", page5, 725, 467, bold, h1)  


    //Aksaray
    //Aksaray Üniversite Başkanı
    drawText(4, rap[67]?.kunye?.baskan ?? "", page5, 48.5, 484, font, h5)
    //Aksaray Üniversite Başkanı Tel
    drawText(5, rap[67]?.kunye?.telefon ?? "", page5, 46.5, 492, font, h5)

    // Aksaray - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[67]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 492, bold, h5)
    //Aksaray - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[67]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 492, bold, h5)
    //Aksaray - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[67]?.kunye?.fakulteSayisi ?? "0", page5, 244, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 492, bold, h5)
    //Aksaray Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[67]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 492, bold, h5)
    //Aksaray MYO Sayı-Bşk - Kom
    drawText(5, rap[67]?.kunye?.myoSayisi ?? "0", page5, 401, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 492, bold, h5)
    //Aksaray Bölüm Sayı-Bşk - Kom
    drawText(5, rap[67]?.kunye?.bolumSayisi ?? "0", page5, 481, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 492, bold, h5)
    //Aksaray Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 492, bold, h5)
    //Aksaray Birebir İlgilenen-İlgilenilen
    drawText(5, rap[67]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 492, bold, h5)
    drawText(5, rap[67]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 492, bold, h5)
    //5.sayfa Aksaray - Bölge Başkanı
    drawText(4, rap[67]?.kunye?.bolgeBsk ?? "", page5, 46.5, 509, font, h5)
    //5.sayfa Aksaray - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[67]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 509, bold, h5)
    //5.sayfa Aksaray - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[67]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 509, bold, h5)
    //5.sayfa Aksaray - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 509, bold, h5)
    //5.sayfa Aksaray - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 509, bold, h5)
    //5.sayfa Aksaray - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 509, bold, h5)
    //5.sayfa Aksaray - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 509, bold, h5)
    //5.sayfa Aksaray - Özel Yurt  Sayi-Bşk
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 509, bold, h5)
    drawText(5, rap[67]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 509, bold, h5)
    //5.sayfa Aksaray - Toplam Öğr -Erkek Öğr
    drawText(5, rap[67]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 509, bold, h5)
    drawText(5, rap[67]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 509, bold, h5)
    // Aksaray - Teşkilatçı Sayısı
    drawText(7, rap[67]?.toplamTeskilatciSayisi ?? "0", page5, 725, 503, bold, h1)  


    //Bayburt
    //Bayburt Üniversite Başkanı
    drawText(4, rap[68]?.kunye?.baskan ?? "", page5, 48.5, 522, font, h5)
    //Bayburt Üniversite Başkanı Tel
    drawText(5, rap[68]?.kunye?.telefon ?? "", page5, 46.5, 529, font, h5)

    // Bayburt - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[68]?.rapor?.icmal?.subeUniBsk ?? "0", page5, 98, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.subeUniKom ?? "0", page5, 123, 529, bold, h5)
    //Bayburt - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[68]?.kunye?.temsilciUniSayisi ?? "0", page5, 151, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.temsilciUniBsk ?? "0", page5, 179, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.temsilciUniKom ?? "0", page5, 211, 529, bold, h5)
    //Bayburt - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[68]?.kunye?.fakulteSayisi ?? "0", page5, 244, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.fakulteBsk ?? "0", page5, 266, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.fakulteKom ?? "0", page5, 296, 529, bold, h5)
    //Bayburt Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[68]?.kunye?.yuksekokulSayisi ?? "0", page5, 324, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.yuksekokulBsk ?? "0", page5, 349, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.yuksekokulKom ?? "0", page5, 374, 529, bold, h5)
    //Bayburt MYO Sayı-Bşk - Kom
    drawText(5, rap[68]?.kunye?.myoSayisi ?? "0", page5, 401, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.myoBsk ?? "0", page5, 429, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.myoKom ?? "0", page5, 455, 529, bold, h5)
    //Bayburt Bölüm Sayı-Bşk - Kom
    drawText(5, rap[68]?.kunye?.bolumSayisi ?? "0", page5, 481, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.bolumBsk ?? "0", page5, 506, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.bolumKom ?? "0", page5, 531, 529, bold, h5)
    //Bayburt Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page5, 559, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page5, 581, 529, bold, h5)
    //Bayburt Birebir İlgilenen-İlgilenilen
    drawText(5, rap[68]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page5, 613, 529, bold, h5)
    drawText(5, rap[68]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page5, 656, 529, bold, h5)
    //5.sayfa Bayburt - Bölge Başkanı
    drawText(4, rap[68]?.kunye?.bolgeBsk ?? "", page5, 46.5, 547, font, h5)
    //5.sayfa Bayburt - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[68]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page5, 98, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page5, 123, 547, bold, h5)
    //5.sayfa Bayburt - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[68]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page5, 151, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page5, 179, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page5, 211, 547, bold, h5)
    //5.sayfa Bayburt - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page5, 244, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page5, 266, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page5, 296, 547, bold, h5)
    //5.sayfa Bayburt - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page5, 324, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page5, 349, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page5, 374, 547, bold, h5)
    //5.sayfa Bayburt - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page5, 401, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page5, 429, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page5, 454, 547, bold, h5)
    //5.sayfa Bayburt - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page5, 481, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page5, 506, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page5, 531, 547, bold, h5)
    //5.sayfa Bayburt - Özel Yurt  Sayi-Bşk
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page5, 559, 547, bold, h5)
    drawText(5, rap[68]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page5, 581, 547, bold, h5)
    //5.sayfa Bayburt - Toplam Öğr -Erkek Öğr
    drawText(5, rap[68]?.kunye?.toplamOgrSayisi ?? "0", page5, 613, 547, bold, h5)
    drawText(5, rap[68]?.kunye?.erkekOgrSayisi ?? "0", page5, 656, 547, bold, h5)
    // Bayburt - Teşkilatçı Sayısı
    drawText(7, rap[68]?.toplamTeskilatciSayisi ?? "0", page5, 725, 541, bold, h1)  


    //6.SAYFA

    //Karaman
    //Karaman Üniversite Başkanı
    drawText(4, rap[69]?.kunye?.baskan ?? "", page6, 48.5, 35, font, h6)
    //Karaman Üniversite Başkanı Tel
    drawText(5, rap[69]?.kunye?.telefon ?? "", page6, 46.5, 41, font, h6)

    // Karaman - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[69]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 41, bold, h6)
    //Karaman - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[69]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 41, bold, h6)
    //Karaman - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[69]?.kunye?.fakulteSayisi ?? "0", page6, 244, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 41, bold, h6)
    //Karaman Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[69]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 41, bold, h6)
    //Karaman MYO Sayı-Bşk - Kom
    drawText(5, rap[69]?.kunye?.myoSayisi ?? "0", page6, 401, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 41, bold, h6)
    //Karaman Bölüm Sayı-Bşk - Kom
    drawText(5, rap[69]?.kunye?.bolumSayisi ?? "0", page6, 481, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 41, bold, h6)
    //Karaman Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 41, bold, h6)
    //Karaman Birebir İlgilenen-İlgilenilen
    drawText(5, rap[69]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 41, bold, h6)
    drawText(5, rap[69]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 41, bold, h6)
    //6.sayfa Karaman - Bölge Başkanı
    drawText(4, rap[69]?.kunye?.bolgeBsk ?? "", page6, 46.5, 59, font, h6)
    //6.sayfa Karaman - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[69]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 59, bold, h6)
    //6.sayfa Karaman - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[69]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 59, bold, h6)
    //6.sayfa Karaman - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 59, bold, h6)
    //6.sayfa Karaman - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 59, bold, h6)
    //6.sayfa Karaman - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 59, bold, h6)
    //6.sayfa Karaman - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 59, bold, h6)
    //6.sayfa Karaman - Özel Yurt  Sayi-Bşk
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 59, bold, h6)
    drawText(5, rap[69]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 59, bold, h6)
    //6.sayfa Karaman - Toplam Öğr -Erkek Öğr
    drawText(5, rap[69]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 59, bold, h6)
    drawText(5, rap[69]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 59, bold, h6)
    // Karaman - Teşkilatçı Sayısı
    drawText(7, rap[69]?.toplamTeskilatciSayisi ?? "0", page6, 725, 53, bold, h1)  

    //Kırıkkale
    //Kırıkkale Üniversite Başkanı
    drawText(4, rap[70]?.kunye?.baskan ?? "", page6, 48.5, 72, font, h6)
    //Kırıkkale Üniversite Başkanı Tel
    drawText(5, rap[70]?.kunye?.telefon ?? "", page6, 46.5, 78, font, h6)

    // Kırıkkale - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[70]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 78, bold, h6)
    //Kırıkkale - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[70]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 78, bold, h6)
    //Kırıkkale - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[70]?.kunye?.fakulteSayisi ?? "0", page6, 244, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 78, bold, h6)
    //Kırıkkale Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[70]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 78, bold, h6)
    //Kırıkkale MYO Sayı-Bşk - Kom
    drawText(5, rap[70]?.kunye?.myoSayisi ?? "0", page6, 401, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 78, bold, h6)
    //Kırıkkale Bölüm Sayı-Bşk - Kom
    drawText(5, rap[70]?.kunye?.bolumSayisi ?? "0", page6, 481, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 78, bold, h6)
    //Kırıkkale Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 78, bold, h6)
    //Kırıkkale Birebir İlgilenen-İlgilenilen
    drawText(5, rap[70]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 78, bold, h6)
    drawText(5, rap[70]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 78, bold, h6)
    //6.sayfa Kırıkkale - Bölge Başkanı
    drawText(4, rap[70]?.kunye?.bolgeBsk ?? "", page6, 46.5, 97, font, h6)
    //6.sayfa Kırıkkale - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[70]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 97, bold, h6)
    //6.sayfa Kırıkkale - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[70]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 97, bold, h6)
    //6.sayfa Kırıkkale - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 97, bold, h6)
    //6.sayfa Kırıkkale - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 97, bold, h6)
    //6.sayfa Kırıkkale - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 97, bold, h6)
    //6.sayfa Kırıkkale - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 97, bold, h6)
    //6.sayfa Kırıkkale - Özel Yurt  Sayi-Bşk
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 97, bold, h6)
    drawText(5, rap[70]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 97, bold, h6)
    //6.sayfa Kırıkkale - Toplam Öğr -Erkek Öğr
    drawText(5, rap[70]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 97, bold, h6)
    drawText(5, rap[70]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 97, bold, h6)
    // Kırıkkale - Teşkilatçı Sayısı
    drawText(7, rap[70]?.toplamTeskilatciSayisi ?? "0", page6, 725, 91, bold, h1)  



    //Batman
    //Batman Üniversite Başkanı
    drawText(4, rap[71]?.kunye?.baskan ?? "", page6, 48.5, 110, font, h6)
    //Batman Üniversite Başkanı Tel
    drawText(5, rap[71]?.kunye?.telefon ?? "", page6, 46.5, 116, font, h6)

    // Batman - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[71]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 116, bold, h6)
    //Batman - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[71]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 116, bold, h6)
    //Batman - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[71]?.kunye?.fakulteSayisi ?? "0", page6, 244, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 116, bold, h6)
    //Batman Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[71]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 116, bold, h6)
    //Batman MYO Sayı-Bşk - Kom
    drawText(5, rap[71]?.kunye?.myoSayisi ?? "0", page6, 401, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 116, bold, h6)
    //Batman Bölüm Sayı-Bşk - Kom
    drawText(5, rap[71]?.kunye?.bolumSayisi ?? "0", page6, 481, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 116, bold, h6)
    //Batman Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 116, bold, h6)
    //Batman Birebir İlgilenen-İlgilenilen
    drawText(5, rap[71]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 116, bold, h6)
    drawText(5, rap[71]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 116, bold, h6)
    //6.sayfa Batman - Bölge Başkanı
    drawText(4, rap[71]?.kunye?.bolgeBsk ?? "", page6, 46.5, 134, font, h6)
    //6.sayfa Batman - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[71]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 134, bold, h6)
    //6.sayfa Batman - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[71]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 134, bold, h6)
    //6.sayfa Batman - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 134, bold, h6)
    //6.sayfa Batman - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 134, bold, h6)
    //6.sayfa Batman - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 134, bold, h6)
    //6.sayfa Batman - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 134, bold, h6)
    //6.sayfa Batman - Özel Yurt  Sayi-Bşk
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 134, bold, h6)
    drawText(5, rap[71]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 134, bold, h6)
    //6.sayfa Batman - Toplam Öğr -Erkek Öğr
    drawText(5, rap[71]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 134, bold, h6)
    drawText(5, rap[71]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 134, bold, h6)
    // Batman - Teşkilatçı Sayısı
    drawText(7, rap[71]?.toplamTeskilatciSayisi ?? "0", page6, 725, 128, bold, h1)  


    //Şırnak
    //Şırnak Üniversite Başkanı
    drawText(4, rap[72]?.kunye?.baskan ?? "", page6, 48.5, 147, font, h6)
    //Şırnak Üniversite Başkanı Tel
    drawText(5, rap[72]?.kunye?.telefon ?? "", page6, 46.5, 153, font, h6)

    // Şırnak - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[72]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 153, bold, h6)
    //Şırnak - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[72]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 153, bold, h6)
    //Şırnak - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[72]?.kunye?.fakulteSayisi ?? "0", page6, 244, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 153, bold, h6)
    //Şırnak Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[72]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 153, bold, h6)
    //Şırnak MYO Sayı-Bşk - Kom
    drawText(5, rap[72]?.kunye?.myoSayisi ?? "0", page6, 401, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 153, bold, h6)
    //Şırnak Bölüm Sayı-Bşk - Kom
    drawText(5, rap[72]?.kunye?.bolumSayisi ?? "0", page6, 481, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 153, bold, h6)
    //Şırnak Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 153, bold, h6)
    //Şırnak Birebir İlgilenen-İlgilenilen
    drawText(5, rap[72]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 153, bold, h6)
    drawText(5, rap[72]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 153, bold, h6)
    //6.sayfa Şırnak - Bölge Başkanı
    drawText(4, rap[72]?.kunye?.bolgeBsk ?? "", page6, 46.5, 172, font, h6)
    //6.sayfa Şırnak - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[72]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 172, bold, h6)
    //6.sayfa Şırnak - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[72]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 172, bold, h6)
    //6.sayfa Şırnak - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 172, bold, h6)
    //6.sayfa Şırnak - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 172, bold, h6)
    //6.sayfa Şırnak - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 172, bold, h6)
    //6.sayfa Şırnak - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 172, bold, h6)
    //6.sayfa Şırnak - Özel Yurt  Sayi-Bşk
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 172, bold, h6)
    drawText(5, rap[72]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 172, bold, h6)
    //6.sayfa Şırnak - Toplam Öğr -Erkek Öğr
    drawText(5, rap[72]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 172, bold, h6)
    drawText(5, rap[72]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 172, bold, h6)
    // Şırnak - Teşkilatçı Sayısı
    drawText(7, rap[72]?.toplamTeskilatciSayisi ?? "0", page6, 725, 166, bold, h1)  



    //Bartın
    //Bartın Üniversite Başkanı
    drawText(4, rap[73]?.kunye?.baskan ?? "", page6, 48.5, 185, font, h6)
    //Bartın Üniversite Başkanı Tel
    drawText(5, rap[73]?.kunye?.telefon ?? "", page6, 46.5, 191, font, h6)

    // Bartın - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[73]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 191, bold, h6)
    //Bartın - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[73]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 191, bold, h6)
    //Bartın - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[73]?.kunye?.fakulteSayisi ?? "0", page6, 244, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 191, bold, h6)
    //Bartın Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[73]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 191, bold, h6)
    //Bartın MYO Sayı-Bşk - Kom
    drawText(5, rap[73]?.kunye?.myoSayisi ?? "0", page6, 401, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 191, bold, h6)
    //Bartın Bölüm Sayı-Bşk - Kom
    drawText(5, rap[73]?.kunye?.bolumSayisi ?? "0", page6, 481, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 191, bold, h6)
    //Bartın Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 191, bold, h6)
    //Bartın Birebir İlgilenen-İlgilenilen
    drawText(5, rap[73]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 191, bold, h6)
    drawText(5, rap[73]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 191, bold, h6)
    //6.sayfa Bartın - Bölge Başkanı
    drawText(4, rap[73]?.kunye?.bolgeBsk ?? "", page6, 46.5, 209, font, h6)
    //6.sayfa Bartın - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[73]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 209, bold, h6)
    //6.sayfa Bartın - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[73]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 209, bold, h6)
    //6.sayfa Bartın - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 209, bold, h6)
    //6.sayfa Bartın - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 209, bold, h6)
    //6.sayfa Bartın - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 209, bold, h6)
    //6.sayfa Bartın - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 209, bold, h6)
    //6.sayfa Bartın - Özel Yurt  Sayi-Bşk
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 209, bold, h6)
    drawText(5, rap[73]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 209, bold, h6)
    //6.sayfa Bartın - Toplam Öğr -Erkek Öğr
    drawText(5, rap[73]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 209, bold, h6)
    drawText(5, rap[73]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 209, bold, h6)
    // Bartın - Teşkilatçı Sayısı
    drawText(7, rap[73]?.toplamTeskilatciSayisi ?? "0", page6, 725, 203, bold, h1)  


    //Ardahan
    //Ardahan Üniversite Başkanı
    drawText(4, rap[74]?.kunye?.baskan ?? "", page6, 48.5, 222, font, h6)
    //Ardahan Üniversite Başkanı Tel
    drawText(5, rap[74]?.kunye?.telefon ?? "", page6, 46.5, 228, font, h6)

    // Ardahan - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[74]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 228, bold, h6)
    //Ardahan - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[74]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 228, bold, h6)
    //Ardahan - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[74]?.kunye?.fakulteSayisi ?? "0", page6, 244, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 228, bold, h6)
    //Ardahan Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[74]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 228, bold, h6)
    //Ardahan MYO Sayı-Bşk - Kom
    drawText(5, rap[74]?.kunye?.myoSayisi ?? "0", page6, 401, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 228, bold, h6)
    //Ardahan Bölüm Sayı-Bşk - Kom
    drawText(5, rap[74]?.kunye?.bolumSayisi ?? "0", page6, 481, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 228, bold, h6)
    //Ardahan Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 228, bold, h6)
    //Ardahan Birebir İlgilenen-İlgilenilen
    drawText(5, rap[74]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 228, bold, h6)
    drawText(5, rap[74]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 228, bold, h6)
    //6.sayfa Ardahan - Bölge Başkanı
    drawText(4, rap[74]?.kunye?.bolgeBsk ?? "", page6, 46.5, 247, font, h6)
    //6.sayfa Ardahan - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[74]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 247, bold, h6)
    //6.sayfa Ardahan - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[74]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 247, bold, h6)
    //6.sayfa Ardahan - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 247, bold, h6)
    //6.sayfa Ardahan - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 247, bold, h6)
    //6.sayfa Ardahan - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 247, bold, h6)
    //6.sayfa Ardahan - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 247, bold, h6)
    //6.sayfa Ardahan - Özel Yurt  Sayi-Bşk
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 247, bold, h6)
    drawText(5, rap[74]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 247, bold, h6)
    //6.sayfa Ardahan - Toplam Öğr -Erkek Öğr
    drawText(5, rap[74]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 247, bold, h6)
    drawText(5, rap[74]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 247, bold, h6)
    // Ardahan - Teşkilatçı Sayısı
    drawText(7, rap[74]?.toplamTeskilatciSayisi ?? "0", page6, 725, 241, bold, h1)  


    //Iğdır
    //Iğdır Üniversite Başkanı
    drawText(4, rap[75]?.kunye?.baskan ?? "", page6, 48.5, 259, font, h6)
    //Iğdır Üniversite Başkanı Tel
    drawText(5, rap[75]?.kunye?.telefon ?? "", page6, 46.5, 266, font, h6)

    // Iğdır - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[75]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 266, bold, h6)
    //Iğdır - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[75]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 266, bold, h6)
    //Iğdır - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[75]?.kunye?.fakulteSayisi ?? "0", page6, 244, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 266, bold, h6)
    //Iğdır Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[75]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 266, bold, h6)
    //Iğdır MYO Sayı-Bşk - Kom
    drawText(5, rap[75]?.kunye?.myoSayisi ?? "0", page6, 401, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 266, bold, h6)
    //Iğdır Bölüm Sayı-Bşk - Kom
    drawText(5, rap[75]?.kunye?.bolumSayisi ?? "0", page6, 481, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 266, bold, h6)
    //Iğdır Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 266, bold, h6)
    //Iğdır Birebir İlgilenen-İlgilenilen
    drawText(5, rap[75]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 266, bold, h6)
    drawText(5, rap[75]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 266, bold, h6)
    //6.sayfa Iğdır - Bölge Başkanı
    drawText(4, rap[75]?.kunye?.bolgeBsk ?? "", page6, 46.5, 284, font, h6)
    //6.sayfa Iğdır - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[75]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 284, bold, h6)
    //6.sayfa Iğdır - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[75]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 284, bold, h6)
    //6.sayfa Iğdır - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 284, bold, h6)
    //6.sayfa Iğdır - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 284, bold, h6)
    //6.sayfa Iğdır - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 284, bold, h6)
    //6.sayfa Iğdır - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 284, bold, h6)
    //6.sayfa Iğdır - Özel Yurt  Sayi-Bşk
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 284, bold, h6)
    drawText(5, rap[75]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 284, bold, h6)
    //6.sayfa Iğdır - Toplam Öğr -Erkek Öğr
    drawText(5, rap[75]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 284, bold, h6)
    drawText(5, rap[75]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 284, bold, h6)
    // Iğdır - Teşkilatçı Sayısı
    drawText(7, rap[75]?.toplamTeskilatciSayisi ?? "0", page6, 725, 278, bold, h1)  


    //Yalova
    //Yalova Üniversite Başkanı
    drawText(4, rap[76]?.kunye?.baskan ?? "", page6, 48.5, 296, font, h6)
    //Yalova Üniversite Başkanı Tel
    drawText(5, rap[76]?.kunye?.telefon ?? "", page6, 46.5, 303, font, h6)

    // Yalova - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[76]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 303, bold, h6)
    //Yalova - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[76]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 303, bold, h6)
    //Yalova - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[76]?.kunye?.fakulteSayisi ?? "0", page6, 244, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 303, bold, h6)
    //Yalova Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[76]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 303, bold, h6)
    //Yalova MYO Sayı-Bşk - Kom
    drawText(5, rap[76]?.kunye?.myoSayisi ?? "0", page6, 401, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 303, bold, h6)
    //Yalova Bölüm Sayı-Bşk - Kom
    drawText(5, rap[76]?.kunye?.bolumSayisi ?? "0", page6, 481, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 303, bold, h6)
    //Yalova Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 303, bold, h6)
    //Yalova Birebir İlgilenen-İlgilenilen
    drawText(5, rap[76]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 303, bold, h6)
    drawText(5, rap[76]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 303, bold, h6)
    //6.sayfa Yalova - Bölge Başkanı
    drawText(4, rap[76]?.kunye?.bolgeBsk ?? "", page6, 46.5, 321, font, h6)
    //6.sayfa Yalova - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[76]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 321, bold, h6)
    //6.sayfa Yalova - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[76]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 321, bold, h6)
    //6.sayfa Yalova - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 321, bold, h6)
    //6.sayfa Yalova - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 321, bold, h6)
    //6.sayfa Yalova - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 321, bold, h6)
    //6.sayfa Yalova - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 321, bold, h6)
    //6.sayfa Yalova - Özel Yurt  Sayi-Bşk
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 321, bold, h6)
    drawText(5, rap[76]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 321, bold, h6)
    //6.sayfa Yalova - Toplam Öğr -Erkek Öğr
    drawText(5, rap[76]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 321, bold, h6)
    drawText(5, rap[76]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 321, bold, h6)
    // Yalova - Teşkilatçı Sayısı
    drawText(7, rap[76]?.toplamTeskilatciSayisi ?? "0", page6, 725, 315, bold, h1)  


    //Karabük
    //Karabük Üniversite Başkanı
    drawText(4, rap[77]?.kunye?.baskan ?? "", page6, 48.5, 334, font, h6)
    //Karabük Üniversite Başkanı Tel
    drawText(5, rap[77]?.kunye?.telefon ?? "", page6, 46.5, 341, font, h6)

    // Karabük - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[77]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 341, bold, h6)
    //Karabük - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[77]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 341, bold, h6)
    //Karabük - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[77]?.kunye?.fakulteSayisi ?? "0", page6, 244, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 341, bold, h6)
    //Karabük Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[77]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 341, bold, h6)
    //Karabük MYO Sayı-Bşk - Kom
    drawText(5, rap[77]?.kunye?.myoSayisi ?? "0", page6, 401, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 341, bold, h6)
    //Karabük Bölüm Sayı-Bşk - Kom
    drawText(5, rap[77]?.kunye?.bolumSayisi ?? "0", page6, 481, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 341, bold, h6)
    //Karabük Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 341, bold, h6)
    //Karabük Birebir İlgilenen-İlgilenilen
    drawText(5, rap[77]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 341, bold, h6)
    drawText(5, rap[77]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 341, bold, h6)
    //6.sayfa Karabük - Bölge Başkanı
    drawText(4, rap[77]?.kunye?.bolgeBsk ?? "", page6, 46.5, 359, font, h6)
    //6.sayfa Karabük - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[77]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 359, bold, h6)
    //6.sayfa Karabük - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[77]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 359, bold, h6)
    //6.sayfa Karabük - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 359, bold, h6)
    //6.sayfa Karabük - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 359, bold, h6)
    //6.sayfa Karabük - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 359, bold, h6)
    //6.sayfa Karabük - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 359, bold, h6)
    //6.sayfa Karabük - Özel Yurt  Sayi-Bşk
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 359, bold, h6)
    drawText(5, rap[77]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 359, bold, h6)
    //6.sayfa Karabük - Toplam Öğr -Erkek Öğr
    drawText(5, rap[77]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 359, bold, h6)
    drawText(5, rap[77]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 359, bold, h6)
    // Karabük - Teşkilatçı Sayısı
    drawText(7, rap[77]?.toplamTeskilatciSayisi ?? "0", page6, 725, 353, bold, h1)  


    //Kilis
    //Kilis Üniversite Başkanı
    drawText(4, rap[78]?.kunye?.baskan ?? "", page6, 48.5, 372, font, h6)
    //Kilis Üniversite Başkanı Tel
    drawText(5, rap[78]?.kunye?.telefon ?? "", page6, 46.5, 378, font, h6)

    // Kilis - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[78]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 378, bold, h6)
    //Kilis - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[78]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 378, bold, h6)
    //Kilis - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[78]?.kunye?.fakulteSayisi ?? "0", page6, 244, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 378, bold, h6)
    //Kilis Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[78]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 378, bold, h6)
    //Kilis MYO Sayı-Bşk - Kom
    drawText(5, rap[78]?.kunye?.myoSayisi ?? "0", page6, 401, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 378, bold, h6)
    //Kilis Bölüm Sayı-Bşk - Kom
    drawText(5, rap[78]?.kunye?.bolumSayisi ?? "0", page6, 481, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 378, bold, h6)
    //Kilis Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 378, bold, h6)
    //Kilis Birebir İlgilenen-İlgilenilen
    drawText(5, rap[78]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 378, bold, h6)
    drawText(5, rap[78]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 378, bold, h6)
    //6.sayfa Kilis - Bölge Başkanı
    drawText(4, rap[78]?.kunye?.bolgeBsk ?? "", page6, 46.5, 396, font, h6)
    //6.sayfa Kilis - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[78]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 396, bold, h6)
    //6.sayfa Kilis - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[78]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 396, bold, h6)
    //6.sayfa Kilis - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 396, bold, h6)
    //6.sayfa Kilis - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 396, bold, h6)
    //6.sayfa Kilis - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 396, bold, h6)
    //6.sayfa Kilis - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 396, bold, h6)
    //6.sayfa Kilis - Özel Yurt  Sayi-Bşk
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 396, bold, h6)
    drawText(5, rap[78]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 396, bold, h6)
    //6.sayfa Kilis - Toplam Öğr -Erkek Öğr
    drawText(5, rap[78]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 396, bold, h6)
    drawText(5, rap[78]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 396, bold, h6)
    // Kilis - Teşkilatçı Sayısı
    drawText(7, rap[78]?.toplamTeskilatciSayisi ?? "0", page6, 725, 390, bold, h1)  


    //Osmaniye
    //Osmaniye Üniversite Başkanı
    drawText(4, rap[79]?.kunye?.baskan ?? "", page6, 48.5, 409, font, h6)
    //Osmaniye Üniversite Başkanı Tel
    drawText(5, rap[79]?.kunye?.telefon ?? "", page6, 46.5, 416, font, h6)

    // Osmaniye - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[79]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 416, bold, h6)
    //Osmaniye - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[79]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 416, bold, h6)
    //Osmaniye - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[79]?.kunye?.fakulteSayisi ?? "0", page6, 244, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 416, bold, h6)
    //Osmaniye Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[79]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 416, bold, h6)
    //Osmaniye MYO Sayı-Bşk - Kom
    drawText(5, rap[79]?.kunye?.myoSayisi ?? "0", page6, 401, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 416, bold, h6)
    //Osmaniye Bölüm Sayı-Bşk - Kom
    drawText(5, rap[79]?.kunye?.bolumSayisi ?? "0", page6, 481, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 416, bold, h6)
    //Osmaniye Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 416, bold, h6)
    //Osmaniye Birebir İlgilenen-İlgilenilen
    drawText(5, rap[79]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 416, bold, h6)
    drawText(5, rap[79]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 416, bold, h6)
    //6.sayfa Osmaniye - Bölge Başkanı
    drawText(4, rap[79]?.kunye?.bolgeBsk ?? "", page6, 46.5, 434, font, h6)
    //6.sayfa Osmaniye - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[79]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 434, bold, h6)
    //6.sayfa Osmaniye - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[79]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 434, bold, h6)
    //6.sayfa Osmaniye - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 434, bold, h6)
    //6.sayfa Osmaniye - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 434, bold, h6)
    //6.sayfa Osmaniye - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 434, bold, h6)
    //6.sayfa Osmaniye - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 434, bold, h6)
    //6.sayfa Osmaniye - Özel Yurt  Sayi-Bşk
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 434, bold, h6)
    drawText(5, rap[79]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 434, bold, h6)
    //6.sayfa Osmaniye - Toplam Öğr -Erkek Öğr
    drawText(5, rap[79]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 434, bold, h6)
    drawText(5, rap[79]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 434, bold, h6)
    // Osmaniye - Teşkilatçı Sayısı
    drawText(7, rap[79]?.toplamTeskilatciSayisi ?? "0", page6, 725, 428, bold, h1)  


    //Düzce
    //Düzce Üniversite Başkanı
    drawText(4, rap[80]?.kunye?.baskan ?? "", page6, 48.5, 446, font, h6)
    //Düzce Üniversite Başkanı Tel
    drawText(5, rap[80]?.kunye?.telefon ?? "", page6, 46.5, 453, font, h6)

    // Düzce - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[80]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 453, bold, h6)
    //Düzce - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[80]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 453, bold, h6)
    //Düzce - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[80]?.kunye?.fakulteSayisi ?? "0", page6, 244, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 453, bold, h6)
    //Düzce Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[80]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 453, bold, h6)
    //Düzce MYO Sayı-Bşk - Kom
    drawText(5, rap[80]?.kunye?.myoSayisi ?? "0", page6, 401, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 453, bold, h6)
    //Düzce Bölüm Sayı-Bşk - Kom
    drawText(5, rap[80]?.kunye?.bolumSayisi ?? "0", page6, 481, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 453, bold, h6)
    //Düzce Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 453, bold, h6)
    //Düzce Birebir İlgilenen-İlgilenilen
    drawText(5, rap[80]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 453, bold, h6)
    drawText(5, rap[80]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 453, bold, h6)
    //6.sayfa Düzce - Bölge Başkanı
    drawText(4, rap[80]?.kunye?.bolgeBsk ?? "", page6, 46.5, 471, font, h6)
    //6.sayfa Düzce - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[80]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 471, bold, h6)
    //6.sayfa Düzce - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[80]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 471, bold, h6)
    //6.sayfa Düzce - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 471, bold, h6)
    //6.sayfa Düzce - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 471, bold, h6)
    //6.sayfa Düzce - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 471, bold, h6)
    //6.sayfa Düzce - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 471, bold, h6)
    //6.sayfa Düzce - Özel Yurt  Sayi-Bşk
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 471, bold, h6)
    drawText(5, rap[80]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 471, bold, h6)
    //6.sayfa Düzce - Toplam Öğr -Erkek Öğr
    drawText(5, rap[80]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 471, bold, h6)
    drawText(5, rap[80]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 471, bold, h6)
    // Düzce - Teşkilatçı Sayısı
    drawText(7, rap[80]?.toplamTeskilatciSayisi ?? "0", page6, 725, 465, bold, h1)  

    //Kıbrıs
    //Kıbrıs Üniversite Başkanı
    drawText(4, rap[81]?.kunye?.baskan ?? "", page6, 48.5, 484, font, h6)
    //Kıbrıs Üniversite Başkanı Tel
    drawText(5, rap[81]?.kunye?.telefon ?? "", page6, 46.5, 490, font, h6)

    // Kıbrıs - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[81]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 490, bold, h6)
    //Kıbrıs - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[81]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 490, bold, h6)
    //Kıbrıs - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[81]?.kunye?.fakulteSayisi ?? "0", page6, 244, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 490, bold, h6)
    //Kıbrıs Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[81]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 490, bold, h6)
    //Kıbrıs MYO Sayı-Bşk - Kom
    drawText(5, rap[81]?.kunye?.myoSayisi ?? "0", page6, 401, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 490, bold, h6)
    //Kıbrıs Bölüm Sayı-Bşk - Kom
    drawText(5, rap[81]?.kunye?.bolumSayisi ?? "0", page6, 481, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 490, bold, h6)
    //Kıbrıs Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 490, bold, h6)
    //Kıbrıs Birebir İlgilenen-İlgilenilen
    drawText(5, rap[81]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 490, bold, h6)
    drawText(5, rap[81]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 490, bold, h6)
    //6.sayfa Kıbrıs - Bölge Başkanı
    drawText(4, rap[81]?.kunye?.bolgeBsk ?? "", page6, 46.5, 508.5, font, h6)
    //6.sayfa Kıbrıs - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[81]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 508.5, bold, h6)
    //6.sayfa Kıbrıs - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[81]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 508.5, bold, h6)
    //6.sayfa Kıbrıs - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 508.5, bold, h6)
    //6.sayfa Kıbrıs - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 508.5, bold, h6)
    //6.sayfa Kıbrıs - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 508.5, bold, h6)
    //6.sayfa Kıbrıs - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 508.5, bold, h6)
    //6.sayfa Kıbrıs - Özel Yurt  Sayi-Bşk
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 508.5, bold, h6)
    drawText(5, rap[81]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 508.5, bold, h6)
    //6.sayfa Kıbrıs - Toplam Öğr -Erkek Öğr
    drawText(5, rap[81]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 508.5, bold, h6)
    drawText(5, rap[81]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 508.5, bold, h6)
    // Kıbrıs - Teşkilatçı Sayısı
    drawText(7, rap[81]?.toplamTeskilatciSayisi ?? "0", page6, 725, 502.5, bold, h1)  

    //Azerbaycan
    //Azerbaycan Üniversite Başkanı
    drawText(4, rap[82]?.kunye?.baskan ?? "", page6, 48.5, 521, font, h6)
    //Azerbaycan Üniversite Başkanı Tel
    drawText(5, rap[82]?.kunye?.telefon ?? "", page6, 46.5, 528, font, h6)

    // Azerbaycan - Şube Üni Bşk - Şube Üni Kom
    drawText(5, rap[82]?.rapor?.icmal?.subeUniBsk ?? "0", page6, 98, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.subeUniKom ?? "0", page6, 123, 528, bold, h6)
    //Azerbaycan - Temsilci Üni Sayı - Bşk - Kom
    drawText(5, rap[82]?.kunye?.temsilciUniSayisi ?? "0", page6, 151, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.temsilciUniBsk ?? "0", page6, 179, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.temsilciUniKom ?? "0", page6, 211, 528, bold, h6)
    //Azerbaycan - Fakülte Sayı - Bşk - Kom
    drawText(5, rap[82]?.kunye?.fakulteSayisi ?? "0", page6, 244, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.fakulteBsk ?? "0", page6, 266, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.fakulteKom ?? "0", page6, 296, 528, bold, h6)
    //Azerbaycan Yüksekokul Sayı-Bşk - Kom
    drawText(5, rap[82]?.kunye?.yuksekokulSayisi ?? "0", page6, 324, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.yuksekokulBsk ?? "0", page6, 349, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.yuksekokulKom ?? "0", page6, 374, 528, bold, h6)
    //Azerbaycan MYO Sayı-Bşk - Kom
    drawText(5, rap[82]?.kunye?.myoSayisi ?? "0", page6, 401, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.myoBsk ?? "0", page6, 429, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.myoKom ?? "0", page6, 455, 528, bold, h6)
    //Azerbaycan Bölüm Sayı-Bşk - Kom
    drawText(5, rap[82]?.kunye?.bolumSayisi ?? "0", page6, 481, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.bolumBsk ?? "0", page6, 506, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.bolumKom ?? "0", page6, 531, 528, bold, h6)
    //Azerbaycan Sınıf Temsilci Say-Temsilci
    drawText(5, "0", page6, 559, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.icmal?.sinifTemsilcisi ?? "0", page6, 581, 528, bold, h6)
    //Azerbaycan Birebir İlgilenen-İlgilenilen
    drawText(5, rap[82]?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? "0", page6, 613, 528, bold, h6)
    drawText(5, rap[82]?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? "0", page6, 656, 528, bold, h6)
    //6.sayfa Azerbaycan - Bölge Başkanı
    drawText(4, rap[82]?.kunye?.bolgeBsk ?? "", page6, 46.5, 546, font, h6)
    //6.sayfa Azerbaycan - Neşriyat Milli Gazete-Dergi
    drawText(5, rap[82]?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? "0", page6, 98, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.egitimRaporu?.nesriyatAGD ?? "0", page6, 123, 546, bold, h6)
    //6.sayfa Azerbaycan - Okuma Grubu Sayi-Tahlil Sayı-Katılım
    drawText(5, rap[82]?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? "0", page6, 151, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? "0", page6, 179, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? "0", page6, 211, 546, bold, h6)
    //6.sayfa Azerbaycan - Teşkilat Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? "0", page6, 244, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.teskilatEvKalan ?? "0", page6, 266, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? "0", page6, 296, 546, bold, h6)
    //6.sayfa Azerbaycan - Kardeş Ev Sayi-Kalan-Ziyaret
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.kardesEvSayisi ?? "0", page6, 324, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.kardesEvKalan ?? "0", page6, 349, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? "0", page6, 374, 546, bold, h6)
    //6.sayfa Azerbaycan - Teşkilat Yurdu Kalan-Teşkilat-Misafir
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? "0", page6, 401, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? "0", page6, 429, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? "0", page6, 454, 546, bold, h6)
    //6.sayfa Azerbaycan - KYK  Sayi-Bşk-Ziyaret
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.kykSayisi ?? "0", page6, 481, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.kykBaskan ?? "0", page6, 506, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.kykZiyaret ?? "0", page6, 531, 546, bold, h6)
    //6.sayfa Azerbaycan - Özel Yurt  Sayi-Bşk
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? "0", page6, 559, 546, bold, h6)
    drawText(5, rap[82]?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? "0", page6, 581, 546, bold, h6)
    //6.sayfa Azerbaycan - Toplam Öğr -Erkek Öğr
    drawText(5, rap[82]?.kunye?.toplamOgrSayisi ?? "0", page6, 613, 546, bold, h6)
    drawText(5, rap[82]?.kunye?.erkekOgrSayisi ?? "0", page6, 656, 546, bold, h6)
    // Karaman - Teşkilatçı Sayısı
    drawText(7, rap[82]?.toplamTeskilatciSayisi ?? "0", page6, 725, 540, bold, h1)  
  

    //Toplam Teşkilatçı
    drawText(7, toplamTeskilatHesapla(rap) ?? "0", page6, 725, 565, bold, h1)  





    pdfDoc.setTitle("Türkiye Z Raporu");
    const pdfDataUri = await pdfDoc.saveAsBase64({ dateUri: true });

    return pdfDataUri;
}

export { create4zRapor }