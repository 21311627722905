import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Footer from "./Footer";
function RequireAuth({ role,bolge }) {
  const location = useLocation();
  const user = localStorage.getItem("user")
  const roleUser = JSON.parse(user).izinler[0].tip;
  const roleBolge = JSON.parse(user).izinler[0].tip.substring(2,7);

  return (
    !localStorage.getItem("token") ? (
      <Navigate to="/giris" state={{ from: location }} replace />
    ) : role === roleUser || bolge===roleBolge ?  (
      <>
      <Outlet />
      <Footer />
      </>
    ) : (
      <Navigate to="/unauthorized" state={{from:location}} replace/>
    )

  )
}

export default RequireAuth;
