import { useState, useEffect } from "react";

function TeskilatRaporu({ setTeskilatRaporlari, teskilatRaporlari }) {
  const initialState = {
    subeUniToplantiKatilim: 0,
    subeUniToplantiIzinli: 0,
    subeUniToplantiIzinsiz: 0,
    temsilciToplantiYapan: 0,
    temsilciToplantiKatilmali: 0,
    temsilciToplantisiKatilan: 0,
    fakulteToplantiYapan: 0,
    fakulteToplantiKatilmali: 0,
    fakulteToplantiKatilan: 0,
    birebirGenclikYapan: 0,
    birebirGenclikMuhatap: 0,
    gundemYapan: 0,
    gundemUlasilan: 0,
    kantinNokta: 0,
    kantinKatilan: 0,
    kantinMisafir: 0,
    ilceYerleske: 0,
    ilceZiyaret: 0,
  };
  const [teskilatRapor, setTeskilatRapor] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeskilatRapor({ ...teskilatRaporlari, [name]: value });
  };

  useEffect(() => {
    setTeskilatRaporlari(teskilatRapor);
  }, [teskilatRapor]);

  return (
    <>
      {/* 0.Kolon Teşkilat Raporu */}
      <div className="flex-column w-30">
        <div className="flex-1 border h-full border-black">
          <h2 className="text-center text-bold px-2 leading-tight py-7 text-xl">
            Teşkilat <br /> Raporu
          </h2>
        </div>
      </div>

      <div className="flex">
        {/* 1.KOLON ŞUBE ÜNİ KATILIM TOPLANTISI */}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-lg px-0 form-control w-full md:w-40 h-20 border border-black"
              readyonly
              value="Şube Üni.Toplantısı"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Katılım"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="İzinli"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="İzinsiz"
            disabled
          />

          <div className="flex-1">
            {/* //KATILIM */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="subeUniToplantiKatilim"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.subeUniToplantiKatilim ??
                teskilatRapor?.subeUniToplantiKatilim
              }
              placeholder="Sayı Gir"
            />
            {/* //İZİNLİ */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="subeUniToplantiIzinli"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.subeUniToplantiIzinli ??
                teskilatRapor?.subeUniToplantiIzinli
              }
              placeholder="Sayı Gir"
            />
            {/* İZİNSİZ */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="subeUniToplantiIzinsiz"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.subeUniToplantiIzinsiz ??
                teskilatRapor?.subeUniToplantiIzinsiz
              }
              placeholder="Sayı Gir"
            />
          </div>
        </div>

        {/* 2.KENDİ TOPLANTISINI YAPAN */}
        <div className="flex-column w-full md:w-48">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-md px-0 form-control  w-48 h-20 border border-black"
              readyonly
              value="Kendi Toplantısını Yapan"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Temsilci"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Katılmalı"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Katılan"
            disabled
          />

          <div className="flex-1">
            {/* //Temsilci Üni */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="temsilciToplantiYapan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.temsilciToplantiYapan ??
                teskilatRapor?.temsilciToplantiYapan
              }
              placeholder="Sayı Gir"
            />
            {/* //Katılması Gereken */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="temsilciToplantiKatilmali"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.temsilciToplantiKatilmali ??
                teskilatRapor?.temsilciToplantiKatilmali
              }
              placeholder="Sayı Gir"
            />
            {/* Katılan */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="temsilciToplantisiKatilan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.temsilciToplantisiKatilan ??
                teskilatRapor?.temsilciToplantisiKatilan
              }
              placeholder="Sayı Gir"
            />
          </div>
        </div>
      </div>

      <div className="flex">
        {/* 3.KENDİ TOPLANTISINI YAPAN Fakülte*/}
        <div className="flex-column w-full md:w-48">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-md px-0 form-control  w-full md:w-48 h-20 border border-black"
              readyonly
              value="Kendi Toplantısını Yapan"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Fakülte"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Katılmalı"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Katılan"
            disabled
          />

          <div className="flex-1">
            {/* //Fakülte */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="fakulteToplantiYapan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.fakulteToplantiYapan ??
                teskilatRapor?.fakulteToplantiYapan
              }
              placeholder="Sayı Gir"
            />
            {/* //Katılması Gereken */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="fakulteToplantiKatilmali"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.fakulteToplantiKatilmali ??
                teskilatRapor?.fakulteToplantiKatilmali
              }
              placeholder="Sayı Gir"
            />
            {/* Katılan */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="fakulteToplantiKatilan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.fakulteToplantiKatilan ??
                teskilatRapor?.fakulteToplantiKatilan
              }
              placeholder="Sayı Gir"
            />
          </div>
        </div>

        {/* 4. KOLON Birebir Gençlik*/}
        <div className="flex-column w-full md:w-48">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-md px-0 form-control  w-48 h-20 border border-black"
              readyonly
              value="1 E 1 Gençlik Çalışması"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/2 border border-black"
            readyonly
            value="Yapan"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/2 border border-black"
            readyonly
            value="Muhatap"
            disabled
          />

          <div className="flex-1">
            {/* //Yapan */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 h-7 border border-black"
              name="birebirGenclikYapan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.birebirGenclikYapan ??
                teskilatRapor?.birebirGenclikYapan
              }
              placeholder="Sayı Gir"
            />
            {/* Muhatap */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 h-7 border border-black"
              name="birebirGenclikMuhatap"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.birebirGenclikMuhatap ??
                teskilatRapor?.birebirGenclikMuhatap
              }
              placeholder="Sayı Gir"
            />
          </div>
        </div>
      </div>

      <div className="flex">
        {/* 5. KOLON Haftalık Gündem*/}
        <div className="flex-column w-full md:w-48">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-md px-0 form-control w-full md:w-48 h-20 border border-black"
              readyonly
              value="Haftalık Gündem Çalışması"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/2 border border-black"
            readyonly
            value="Yapan"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/2 border border-black"
            readyonly
            value="Ulaşılan"
            disabled
          />

          <div className="flex-1">
            {/* //Yapan */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 h-7 border border-black"
              name="gundemYapan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.gundemYapan ?? teskilatRapor?.gundemYapan
              }
              placeholder="Sayı Gir"
            />
            {/* Ulaşılan */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/2 h-7 border border-black"
              name="gundemUlasilan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.gundemUlasilan ??
                teskilatRapor?.gundemUlasilan
              }
              placeholder="Sayı Gir"
            />
          </div>
        </div>

        {/* 7. KOLON Kantin Buluşması*/}
        <div className="flex-column w-full md:w-40">
          <div className="flex-1">
            <input
              type="text"
              className="text-center text-md px-0 form-control  w-full md:w-40 h-20 border border-black"
              readyonly
              value="Kantin Buluşması"
              disabled
            />
          </div>

          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Nokta"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Katılan"
            disabled
          />
          <input
            type="text"
            className="text-center text-bold py-0 form-control w-1/3 border border-black"
            readyonly
            value="Misafir"
            disabled
          />

          <div className="flex-1">
            {/* //Nokta */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="kantinNokta"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.kantinNokta ?? teskilatRapor?.kantinNokta
              }
              placeholder="Sayı Gir"
            />
            {/* Katılan Teşkilatçı */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="kantinKatilan"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.kantinKatilan ?? teskilatRapor?.kantinKatilan
              }
              placeholder="Sayı Gir"
            />
            {/* Katılan Misafir */}
            <input
              type="number"
              className="text-center  px-1 form-control w-1/3 h-7 border border-black"
              name="kantinMisafir"
              onChange={handleChange}
              min={0}
              value={
                teskilatRaporlari.kantinMisafir ?? teskilatRapor?.kantinMisafir
              }
              placeholder="Sayı Gir"
            />
          </div>
        </div>
      </div>

      {/* 8. KOLON İlçe Ziyareti*/}
      <div className="flex-column w-1/2 md:w-40">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-md px-0 form-control  w-full md:w-40 h-20 border border-black"
            readyonly
            value="İlçe Ziyareti"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/2 border border-black"
          readyonly
          value="Yerleşke"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/2 border border-black"
          readyonly
          value="Ziyaret"
          disabled
        />

        <div className="flex-1">
          {/* //Yerleşke Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/2 h-7 border border-black"
            name="ilceYerleske"
            onChange={handleChange}
            min={0}
            value={
              teskilatRaporlari.ilceYerleske ?? teskilatRapor?.ilceYerleske
            }
            placeholder="Sayı Gir"
          />
          {/* Ziyaret edilen İlçe */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/2 h-7 border border-black"
            name="ilceZiyaret"
            onChange={handleChange}
            min={0}
            value={teskilatRaporlari.ilceZiyaret ?? teskilatRapor?.ilceZiyaret}
            placeholder="Sayı Gir"
          />
        </div>
      </div>
    </>
  );
}

export default TeskilatRaporu;
