import React, { useEffect, useState } from 'react'
import TurkiyeGenelRaporGoruntule from './TurkiyeGenelRaporGoruntule';
import TurkiyeGenelRaporOncekiGoruntule from './TurkiyeGenelRaporOncekiGoruntule';
export default function TurkiyeGenel({ rapor }) {
    const [modal, setModal] = useState(false);
    const [modalOnce,setModalOnce]=useState(false);
    const [kunye, setKunye] = useState({});
    useEffect(() => {
        if (!rapor) { return }
        kunyeHesapla();
    }, [rapor])
    async function kunyeHesapla() {
        const ogr = rapor?.filter(element => { return element.kunye?.erkekOgrSayisi!==undefined})
        const erkekOgrSayisi = ogr?.reduce((acc, obj) => (acc + obj?.kunye['erkekOgrSayisi']), 0);
        const toplam= rapor?.filter(element => { return element.kunye.toplamOgrSayisi!==undefined})
        const toplamOgrSayisi = toplam?.reduce((acc, obj) => (acc + obj?.kunye['toplamOgrSayisi']), 0);

        const temsilci=rapor?.filter(element => { return element.kunye.temsilciUniSayisi!==undefined})
        const temsilciUniSayisi = temsilci.reduce((acc,obj)=> (acc+obj?.kunye['temsilciUniSayisi']),0)
        const fakulte=rapor?.filter(element => { return element.kunye.fakulteSayisi!==undefined})
        const fakulteSayisi = fakulte?.reduce((acc, obj) => (acc+obj?.kunye['fakulteSayisi']),0)

        const yuksekokul=rapor?.filter(element => { return element.kunye.yuksekokulSayisi!==undefined})
        const yuksekokulSayisi = yuksekokul?.reduce((acc, obj) =>(acc+obj?.kunye['yuksekokulSayisi']),0)

        const myo=rapor?.filter(element => { return element.kunye.myoSayisi!==undefined})
        const myoSayisi = myo?.reduce((acc, obj) => (acc+obj?.kunye['myoSayisi']),0)
       
        const bolum=rapor?.filter(element => { return element.kunye.bolumSayisi!==undefined})
        const bolumSayisi = bolum?.reduce((acc, obj) => (acc+obj?.kunye['bolumSayisi']),0)
        return setKunye({ erkekOgrSayisi, toplamOgrSayisi, temsilciUniSayisi, fakulteSayisi, yuksekokulSayisi, myoSayisi, bolumSayisi })
    }
    

    return (
        <>
            <tr class="hover:bg-blue-300  bg-slate-100">
                <td class="px-6 py-4 text-xl text-bold whitespace-no-wrap border-b border-gray-200" >
                    <div class="flex items-center">
                        Toplam
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <button onClick={() => setModal(!modal)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline" disabled={rapor.rapor === null ? true : false} >{rapor.rapor === null ? "---" : "Görüntüle"}</button>
                </td>

                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 ${rapor.rapor === null ? "bg-red-100" : "bg-green-100"} rounded`}>{/*rapor.rapor === null ? "Göndermedi" : "Gönderdi"*/}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs text-center font-semibold leading-3 text-green-800 ${rapor.rapor === null ? "bg-red-100" : "bg-green-100"} rounded`}></span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <button onClick={() => setModalOnce(!modalOnce)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline" disabled={rapor.rapor === null ? true : false} >{rapor.rapor === null ? "---" : "Görüntüle"}</button>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-lg font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{kunye.temsilciUniSayisi}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-lg font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{kunye.fakulteSayisi}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-lg font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{kunye?.yuksekokulSayisi}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-lg font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{kunye?.myoSayisi}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-lg font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{kunye?.bolumSayisi}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-center text-lg font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{kunye?.erkekOgrSayisi}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-lg text-center font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{kunye?.toplamOgrSayisi}</span>
                </td>
            </tr>

         <TurkiyeGenelRaporGoruntule rapor={rapor} kunye={kunye} modal={modal} setModal={setModal} /> 
        <TurkiyeGenelRaporOncekiGoruntule rapor={rapor} kunye={kunye} modal={modalOnce} setModal={setModalOnce} /> 

        </>
    )
}
