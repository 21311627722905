import React, { useState } from 'react'
import { yaziliTariheCevir } from "../utils/functions"
import BolgeRaporGoruntule from './BolgeRaporGoruntule';
import BolgeRaporGecenGoruntule from './BolgeRaporGecenGoruntule';
export default function BolgeItem({ rapor,hafta }) {
    const raporBasligi = yaziliTariheCevir(new Date(rapor?.rapor?.createdAt)) + " " + "Raporu"
    const [modal, setModal] = useState(false);
    const [modalOnce,setModalOnce]=useState(false);
    return (
        <>
            <tr class="hover:bg-blue-300">
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" >
                    <div class="flex items-center">
                        {rapor?.kunye?.sehir ?? rapor?.kunye?.isim}
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <button onClick={() => setModal(!modal)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline" disabled={rapor.rapor===null ? true : false} >{rapor.rapor === null ? "---------" : "Görüntüle"}</button>
                </td>

                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 ${rapor.rapor===null ? "bg-red-100" : "bg-green-100"} rounded`}>{rapor.rapor === null ? "Göndermedi" : "Gönderdi"}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex p-2 text-xs text-center font-semibold leading-3 text-green-800 ${rapor.rapor===null ? "bg-red-100" : "bg-green-100"} rounded`}>{rapor.rapor === null ? "---":yaziliTariheCevir(new Date(rapor?.rapor?.createdAt)) }</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <button onClick={() => setModalOnce(!modalOnce)} class={`font-medium text-blue-600 hover:underline ${rapor.gecenHaftaRapor ==undefined ? "dark:text-red-500" :"dark:text-blue-500"}`} disabled={rapor.gecenHaftaRapor===null || rapor.gecenHaftaRapor==undefined ? true : false} >{rapor.gecenHaftaRapor === null || rapor.gecenHaftaRapor==undefined ? "Göndermedi" : "Görüntüle"}</button>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{rapor?.kunye?.temsilciUniSayisi ?? 0}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{rapor?.kunye?.fakulteSayisi ?? 0} </span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{rapor?.kunye?.yuksekokulSayisi ?? 0}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{rapor?.kunye?.myoSayisi ?? 0}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{rapor?.kunye?.bolumSayisi ?? 0 }</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{rapor?.kunye?.erkekOgrSayisi ?? 0}</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                        class={`inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-light-100 rounded-full`}>{rapor?.kunye?.toplamOgrSayisi ?? 0}</span>
                </td>
            </tr>

           {modal ? <BolgeRaporGoruntule rapor={rapor} modal={modal} setModal={setModal} /> : null } 
           {modalOnce ? <BolgeRaporGecenGoruntule rapor={rapor} modal={modalOnce} setModal={setModalOnce} /> : null } 

        </>
    )
}
