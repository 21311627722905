import React, { useState, useEffect } from "react";

export default function EvlerYurtlar({ setEvlerVeYurtlar, evlerVeYurtlar }) {
  const initialState = {
    teskilatEvSayisi: 0,
    teskilatEvKalan: 0,
    teskilatEvZiyaret: 0,
    kardesEvSayisi: 0,
    kardesEvKalan: 0,
    kardesEvZiyaret: 0,
    teskilatYurtSayisi: 0,
    teskilatYurtKalan: 0,
    teskilatYurtTeskilat: 0,
    teskilatYurtMisafir: 0,
    kykSayisi: 0,
    kykBaskan: 0,
    kykZiyaret: 0,
    ozelYurtSayisi: 0,
    ozelYurtBaskan: 0,
    ozelYurtZiyaret: 0,
  };
  const [evlerYurtlar, setEvlerYurtlar] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvlerYurtlar({ ...evlerVeYurtlar, [name]: value });
  };

  useEffect(() => {
    setEvlerVeYurtlar(evlerYurtlar);
  }, [evlerYurtlar]);

  return (
    <>
      {" "}
      {/* 0.Kolon Evler Yurtlar Raporu */}
      <div className="flex-column w-full md:w-30">
        <div className="flex-1 border h-full border-black">
          <h2 className="text-center text-bold px-2 leading-tight py-7 text-xl">
            Evler Yurtlar <br /> Raporu
          </h2>
        </div>
      </div>
      {/* 1.KOLON Teşkilat evi */}
      <div className="flex-column w-full md:w-60">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-lg px-0 form-control w-full md:w-60 h-20 border border-black"
            readyonly
            value="Teşkilat Evi"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Ev Sayısı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Kalan"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Ziyaret"
          disabled
        />

        <div className="flex-1">
          {/* Teşkilatçı Ev Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="teskilatEvSayisi"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.teskilatEvSayisi ?? evlerYurtlar?.teskilatEvSayisi
            }
            placeholder="Sayı Gir"
          />
          {/* Kalan Kişi Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="teskilatEvKalan"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.teskilatEvKalan ?? evlerYurtlar?.teskilatEvKalan
            }
            placeholder="Sayı Gir"
          />
          {/* Ziyaret  */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="teskilatEvZiyaret"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.teskilatEvZiyaret ??
              evlerYurtlar?.teskilatEvZiyaret
            }
            placeholder="Sayı Gir"
          />
        </div>
      </div>
      {/* 2.KOLON Kardeş ev */}
      <div className="flex-column w-full md:w-48">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-md px-0 form-control  w-full md:w-48 h-20 border border-black"
            readyonly
            value="Kardeş Ev"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Ev Sayısı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Kalan"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Ziyaret"
          disabled
        />

        <div className="flex-1">
          {/* Kardeş Ev Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="kardesEvSayisi"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.kardesEvSayisi ?? evlerYurtlar?.kardesEvSayisi
            }
            placeholder="Sayı Gir"
          />
          {/* Kalan Kişi Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="kardesEvKalan"
            onChange={handleChange}
            min={0}
            value={evlerVeYurtlar.kardesEvKalan ?? evlerYurtlar?.kardesEvKalan}
            placeholder="Sayı Gir"
          />
          {/* Ziyaret  */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="kardesEvZiyaret"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.kardesEvZiyaret ?? evlerYurtlar?.kardesEvZiyaret
            }
            placeholder="Sayı Gir"
          />
        </div>
      </div>
      {/* 4.KOLON Yurtlar */}
      <div className="flex-column w-full md:w-48">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-md px-0 form-control  w-full md:w-48 h-20 border border-black"
            readyonly
            value="Teşkilat Yurdu"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="Yurt Sayısı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="Kalan"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="Teşkilatçı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/4 border border-black"
          readyonly
          value="Misafir"
          disabled
        />

        <div className="flex-1">
          {/* Yurt Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="teskilatYurtSayisi"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.teskilatYurtSayisi ??
              evlerYurtlar?.teskilatYurtSayisi
            }
            placeholder="Sayı Gir"
          />
          {/* Kalan Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="teskilatYurtKalan"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.teskilatYurtKalan ??
              evlerYurtlar?.teskilatYurtKalan
            }
            placeholder="Sayı Gir"
          />
          {/* Kalan Teşkilatçı Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="teskilatYurtTeskilat"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.teskilatYurtTeskilat ??
              evlerYurtlar?.teskilatYurtTeskilat
            }
            placeholder="Sayı Gir"
          />
          {/* Misafir Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/4 h-7 border border-black"
            name="teskilatYurtMisafir"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.teskilatYurtMisafir ??
              evlerYurtlar?.teskilatYurtMisafir
            }
            placeholder="Sayı Gir"
          />
        </div>
      </div>
      {/* 5.KOLON KYK */}
      <div className="flex-column w-full md:w-48">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-md px-0 form-control  w-full md:w-48 h-20 border border-black"
            readyonly
            value="KYK"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="KYK Sayısı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Başkan"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Ziyaret"
          disabled
        />

        <div className="flex-1">
          {/* KYK Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="kykSayisi"
            onChange={handleChange}
            min={0}
            value={evlerVeYurtlar.kykSayisi ?? evlerYurtlar?.kykSayisi}
            placeholder="Sayı Gir"
          />
          {/* KYK Başkan Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="kykBaskan"
            onChange={handleChange}
            min={0}
            value={evlerVeYurtlar.kykBaskan ?? evlerYurtlar?.kykBaskan}
            placeholder="Sayı Gir"
          />
          {/* Ziyaret  */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="kykZiyaret"
            onChange={handleChange}
            min={0}
            value={evlerVeYurtlar.kykZiyaret ?? evlerYurtlar?.kykZiyaret}
            placeholder="Sayı Gir"
          />
        </div>
      </div>
      {/* 6.KOLON Özel Yurt */}
      <div className="flex-column w-full md:w-48">
        <div className="flex-1">
          <input
            type="text"
            className="text-center text-md px-0 form-control  w-full md:w-48 h-20 border border-black"
            readyonly
            value="Özel Yurt"
            disabled
          />
        </div>

        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Yurt Sayısı"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Başkan"
          disabled
        />
        <input
          type="text"
          className="text-center text-bold py-0 form-control w-1/3 border border-black"
          readyonly
          value="Ziyaret"
          disabled
        />

        <div className="flex-1">
          {/* Özel Yurt Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="ozelYurtSayisi"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.ozelYurtSayisi ?? evlerYurtlar?.ozelYurtSayisi
            }
            placeholder="Sayı Gir"
          />
          {/* Özel Yurt Başkan Sayısı */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="ozelYurtBaskan"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.ozelYurtBaskan ?? evlerYurtlar?.ozelYurtBaskan
            }
            placeholder="Sayı Gir"
          />
          {/* Ziyaret  */}
          <input
            type="number"
            className="text-center  px-1 form-control w-1/3 h-7 border border-black"
            name="ozelYurtZiyaret"
            onChange={handleChange}
            min={0}
            value={
              evlerVeYurtlar.ozelYurtZiyaret ?? evlerYurtlar?.ozelYurtZiyaret
            }
            placeholder="Sayı Gir"
          />
        </div>
      </div>
    </>
  );
}
