import React from 'react'
import {toplamTeskilatciHesapla} from "../utils/functions";
function ZRaporuItem({rapor,index}) {
    return (
        <>
            {/* 0.KOLON Üniversite Başkanı */}
            <div className="flex-column ">

            <div className="flex-1 border px-2 h-[165.6px] w-32 border-black rounded">
          <h2 className="text-center text-bold leading-tight py-16 text-lg">
           {rapor?.kunye?.isim}
          </h2>
        </div>
                
            </div>
            <div className="flex-column w-80">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs  form-control w-full h-6 border border-black"
                        readyonly
                        value="Üniversite Başkanı"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Üni Bşk Adı */}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value={rapor?.kunye?.baskan ?? ""}
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Telefon */}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value={rapor?.kunye?.telefon ?? ""}
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Boşluk  */}
                    <input
                        type="text"
                        className="text-center  text-xs form-control w-full h-7  border border-black"
                        name="sehir"
                        value=""
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Bölge */}
                    <input
                        type="text"
                        className="text-center text-xs  form-control w-full h-7 border border-black"
                        name="sehir"
                        disabled
                        value={rapor?.kunye?.bolge.substring(0,1)+".Bölge" ?? ""}
                    />
                </div>
                <input
                    type="text"
                    className="text-center text-xs  form-control w-full h-7 border border-black"
                    name="sehir"
                    disabled
                    value={rapor?.kunye?.baskan ?? ""}
                />
            </div>

            <div className="flex-column w-46">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="Şube Üni. Kom."
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readyonly
                        value="Başkan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        name="temsilciUniSayisi"
                        value="Komisyon"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/*  Şube Uni Bşk */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.subeUniBsk ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.subeUniKom ?? 0}
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7  border border-black"
                        value="Neşriyat"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-2/3 h-7 border border-black"
                        readyonly
                        value="Milli Gazete"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        name="temsilciUniSayisi"
                        value="Dergi"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/*  Milli Gazete */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-2/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.egitimRaporu?.nesriyatMilliGazete ?? 0}

                    />
                    {/* Dergi */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.egitimRaporu?.nesriyatAGD ?? 0}

                    />
                </div>

            </div>
            {/* Temsilci Üniversite */}
            <div className="flex-column w-46">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="Temsilci Üniversite"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Temsilci Üni Sayısısı */}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Sayısı"
                        disabled
                        
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Başkan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Komisyon"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Temsilci Üni Bşk*/}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.kunye?.temsilciUniSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.temsilciUniBsk ?? 0}
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.temsilciUniKom ?? 0}
                    />
                </div>
                {/* Okuma Grubu */}
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value="Okuma Grubu"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Grup Sayısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Tahlil"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Tahlil Kat."
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/*  Okuma Grubu */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.egitimRaporu?.okumaGrubuSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.egitimRaporu?.okumaGrubuTahlilSayisi ?? 0}
                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.egitimRaporu?.okumaGrubuTahlilKatilim ?? 0}
                    />
                </div>

            </div>



            {/* Fakülte */}
            <div className="flex-column w-46">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="Fakülte"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Sayısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Başkan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Komisyon"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Fakülte Sayısısı*/}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.kunye?.fakulteSayisi ?? 0}
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.fakulteBsk ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.fakulteKom ?? 0}
                    />
                </div>
                {/* Teşkilat Evi */}
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value="Teşkilat Evi"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Ev Sayısısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Kalan Kişi"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Ziyaret Sayısısı"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Teşkilat Evi */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.teskilatEvSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.teskilatEvKalan ?? 0}
                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.teskilatEvZiyaret ?? 0}
                    />
                </div>

            </div>


            {/* Yüksekokul */}
            <div className="flex-column w-46">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="Yüksekokul"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Sayısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Başkan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Komisyon"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Yüksekokul*/}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.kunye?.yuksekokulSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.yuksekokulBsk ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.yukseokulKom ?? 0}
                    />
                </div>
                {/* Kardeş Ev */}
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value="Kardeş Ev"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Ev Sayısısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Kalan Kişi"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Ziyaret"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/*Kardeş Ev */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.kardesEvSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.kardesEvKalan ?? 0}
                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.kardesEvZiyaret ?? 0}
                    />
                </div>

            </div>


            {/* Meslek Yüksekokulu Üniversite */}
            <div className="flex-column w-46">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="MYO"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Sayısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Başkan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Komisyon"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* MYO*/}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.kunye?.myoSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.myoBsk ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.myoKom ?? 0}

                    />
                </div>
                {/* Teşkilat Yurdu */}
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value="Teşkilat Yurdu"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Kalan Sayısısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Teşkilatçı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Misafir"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Teşkilat Yurdu */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.teskilatYurtKalan ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.teskilatYurtTeskilat ?? 0}
                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.teskilatYurtMisafir ?? 0}
                    />
                </div>

            </div>

            {/* Bölüm */}
            <div className="flex-column w-46">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="Bölüm"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Sayısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Başkan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Komisyon"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    {/* Bölüm*/}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.kunye?.bolumSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.bolumBsk ?? 0}
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.bolumKom ?? 0}

                    />
                </div>
                {/* KYK */}
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value="KYK"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        readyonly
                        value="Yurt Sayısısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Başkan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/3 h-7 border border-black"
                        value="Ziyaret"
                        disabled
                    />
                </div>
                <div className="flex-1">
                  {/* KYK */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.kykSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.kykBaskan ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/3 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.kykZiyaret ?? 0}
                    />
                </div>

            </div>

            {/* Sınıf Temsilcisi */}
            <div className="flex-column w-46">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="Sınıf Temsilcisi"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readyonly
                        value="Sayısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        value="Temsilci"
                        disabled
                    />
                  
                </div>
                <div className="flex-1">
                    {/* Sınıf Temsilcisi*/}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.sinifTemsilcisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.icmal?.sinifTemsilcisiYrd ?? 0}

                    />
                   
                </div>
                {/* Özel Yurt */}
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value="Özel Yurt"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readyonly
                        value="Yurt Sayısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        value="Başkan"
                        disabled
                    />

                </div>
                <div className="flex-1">
                    {/* Özel Yurt */}
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.ozelYurtSayisi ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-sm form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.evlerYurtlar?.ozelYurtBaskan ?? 0}

                    />
                </div>

            </div>

            {/* Birebir */}
            <div className="flex-column">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-6 border border-black"
                        readyonly
                        value="Birebir"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readyonly
                        value="Yapan"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        value="Muhatap"
                        disabled
                    />

                </div>
                <div className="flex-1">
                    {/* Birebir*/}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.teskilatRaporu?.birebirGenclikYapan ?? 0}

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.rapor?.teskilatRaporu?.birebirGenclikMuhatap ?? 0}
                    />

                </div>
                {/* Künye */}
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-full h-7 border border-black"
                        readyonly
                        value="Künye"
                        disabled
                    />
                </div>
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readyonly
                        value="Top.Öğr.Sayısısı"
                        disabled
                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        value="Erk.Öğr.Sayısısı"
                        disabled
                    />

                </div>
                <div className="flex-1">
                    {/* Künye */}
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readonly
                        value={rapor?.kunye?.toplamOgrSayisi ?? 0}
                        disabled

                    />
                    <input
                        type="text"
                        className="text-center text-xs form-control w-1/2 h-7 border border-black"
                        readonly
                        disabled
                        value={rapor?.kunye?.erkekOgrSayisi ?? 0}

                    />
                   
                </div>

            </div>

            {/* Toplam Teşkilatçı Sayısısı */}
            <div className="flex-column w-80 h-[165.6px]">
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-xs form-control w-28 h-12   border border-black"
                        readyonly
                        value="Toplam Teşkilatçı Sayısısı"
                        disabled
                    />
                </div>
               
               
               
                <div className="flex-1">
                    <input
                        type="text"
                        className="text-center text-sm form-control w-full h-[117.6px] border border-black"
                        readonly
                        disabled
                        value={toplamTeskilatciHesapla(rapor?.rapor?.icmal,rapor?.rapor?.evlerYurtlar?.kykBaskan,rapor?.rapor?.evlerYurtlar?.ozelYurtBaskan)}
                    />
                   
                </div>

            </div>
        </>
    )
}

export default ZRaporuItem
