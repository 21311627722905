export default function footer() {
    return (
        <div className="container mx-auto">
            <footer className="py-6 px-16 border-t border-gray-200 font-light flex flex-col lg:flex-row justify-between items-center">
                <p className="text-gray-700 mb-6 lg:mb-0">
                    Copyright &copy; {new Date().getFullYear()}{' '}
                    <a
                        href="/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-light-blue-500 hover:text-light-blue-700"
                    >
                        AGD Üniversite
                    </a>
                </p>

               
                <div className="flex items-center gap-4 lg:text-xl md:text-md xs:text-xs sm:text-sm">
                <p className="text-gray-700 lg:text-lg sm:text-sm  ">
                   Sosyal Medya Hesaplarımız : 
                </p>
                    <a
                        className="text-gray-700 hover:text-blue-300 font-medium block lg:text-lg sm:text-sm"
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/AGDUNi"
                    >
                        <i class="fa-brands fa-twitter"></i>
                    </a>

                    <a
                        className="text-gray-700 hover:text-red-400 font-medium lg:text-lg sm:text-sm"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/agduniversite/"
                    >
                        <i class="fa-brands fa-instagram"></i>
                    </a>

                    <a
                        className="text-gray-700 hover:text-blue-600 font-medium block lg:text-lg sm:text-sm"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/AGDUNi/"
                    >
                        <i class="fa-brands fa-facebook-f"></i>
                    </a>

                    <a
                        className="text-gray-700 hover:text-red-600 font-medium block lg:text-lg sm:text-sm"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.youtube.com/c/AGDÜNİVERSİTE"
                    >
                        <i class="fa-brands fa-youtube"></i>
                    </a>
                </div>
            </footer>
        </div>
    );
}
