import React from 'react'
import Header from '../components/Header'
import BirimBolge from "../components/BirimBolge"
export default function Bilgi() {
  return (
    <>
    <Header/>
    <BirimBolge/>


    </>
  )
}
