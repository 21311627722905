import React, { useEffect,useState } from "react";
import siniflardan from "../siniflardan.png";
import agd from "../agd_uni_yan.png";
import s1 from "../4s1.png";
import date from "date-and-time";

export default function RaporBaslik({title,sonRaporTarihi}) {

  function zamanHesapla(){
    const gun =new Date().getDate()
    const ay =new Date().getMonth()+1
    const yil=new Date().getFullYear()
    return `${gun}.${ay}.${yil}`
  }
  const [time,setTime]=useState();
  useEffect(()=> {
    setTime(zamanHesapla())

  },[])

  return (
    <>
      <div className="container mx-auto ">
        <nav class="flex items-center border border-black justify-between align-middle flex-wrap py-5 my-5 bg-inherit p-6 md:mx-auto">
          <img src={agd} width="216" height="54" />
          <h2 className="text-3xl text-center text-dark">
            {title?.toUpperCase()} ŞUBE ÜNİVERSİTE KOMİSYONU RAPORU
          </h2>
          <img src={siniflardan} width="80" height="54" />
          <img src={s1} width="120" height="54" />
         
        </nav>
        <div className="container mx-auto px-4 text-xl flex justify-between">
          <div>
            <strong>Tarih</strong> : {time}
          </div>
          <div>
            <strong>İl</strong> : {title}
          </div>
        </div>
        <div className="container mx-auto px-4 text-xl">
        <strong>Son Gönderilen Rapor :</strong> {sonRaporTarihi ===false ? "Henüz rapor girilmemiş." : date.format(new Date(sonRaporTarihi),"DD-MM-YYYY HH:mm:ss")}
        </div>
      </div>
    </>
  );
}
