import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import KullaniciService from "../services/KullaniciService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Footer from './Footer';
export default function SifreDegistir() {
    let navigate = useNavigate();
    const [newPass, setNewPass] = useState("");
    const [newPassAgain, setNewPassAgain] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const token = localStorage.getItem("token");
    let kullaniciService = new KullaniciService();
    function handleSubmit(e) {
        e.preventDefault();

        if (newPass === newPassAgain) {

            kullaniciService.sifreDegistir(token, newPass)
                .then(res => {
                    setIsSubmit(true);
                    localStorage.removeItem("token");
                    navigate("/giris", { replace: true })
                })
                .catch(err => console.log(err));

        }

    }

    return (<>
        {isSubmit ? (
            <ToastContainer limit={1}>
                {toast.success("Başarılı bir şekilde mekanınızı oluşturdunuz", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: { backgroundColor: "#7f52e1", color: "#fff" },
                })}
            </ToastContainer>
        ) : null}
        <Header />
        <div className="w-72 container container-md mx-auto" >
            <h2 className="text-3xl border-b border-gray-300 mb-4">Şifre Değiştirme</h2>
            <form className="flex flex-column items-center" onSubmit={handleSubmit}>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                        Yeni Şifreniz
                    </label>
                    <input onChange={e => {
                        setNewPass(e.target.value)

                    }} type="password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="sifre" placeholder="Yeni Şifrenizi giriniz." />
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                        Yeni Şifre Tekrar
                    </label>
                    <input onChange={e => {
                        setNewPassAgain(e.target.value)

                    }} type="password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="sifre_tekrar" placeholder="Yeni şifrenizi tekrar giriniz." />
                    <button type="submit" className={`bg-gray-200 w-full py-2 ${newPass !== newPassAgain ? "pointer-events-none text-red-600" : "text-green-700"} mt-4 `}>Şifreyi Değiştir</button>

                </div>



                {/* <div class="flex space-x-2 justify-center"> */}
                {/* <div>
    <button type="button" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:outline-none focus:ring-0 transition duration-150 ease-in-out pointer-events-none opacity-60" disabled>Primary</button>
    <button type="button" class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:outline-none focus:ring-0 transition duration-150 ease-in-out pointer-events-none opacity-60" disabled>Secondary</button>
  </div> */}
                {/* </div> */}
            </form>
           
        </div>
        <Footer />
    </>
    )
}
