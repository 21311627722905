import { PDFDocument } from "pdf-lib";
import fontkit from '@pdf-lib/fontkit';
import { sayiliYilaCevir, stringCevir, yaziliTariheCevir, toplamTeskilatciHesapla, uretimHesapla } from "./functions";

async function createPdf(tarih, title, rapor, a, gecenHafta, toplamTeskilatci) {
  const url = "/assets/files/4z_compres.pdf";
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const urlFont = '/assets/fonts/Poppins-Regular.ttf';
  const urlFontBold = '/assets/fonts/Poppins-Bold.ttf';
  const regularFontBytes = await fetch(urlFont).then(res => res.arrayBuffer());
  const boldFontBytes = await fetch(urlFontBold).then(res => res.arrayBuffer());
  pdfDoc.registerFontkit(fontkit);
  const font = await pdfDoc.embedFont(regularFontBytes);
  const bold = await pdfDoc.embedFont(boldFontBytes);
  const page = pdfDoc.getPages()[0];
  const { width, height } = page.getSize();
  const uretim = uretimHesapla(rapor?.gecenHaftaTeskilatciSayisi, rapor?.teskilatciSayisi)?.toString();
  // console.log(rapor)
  // width: 595.32 height: 841.92
  //Tarih
  let prefSize = 8;
  // let infoToWrite =  sayiliYilaCevir(new Date()).toString();
  let infoToWrite = sayiliYilaCevir(new Date(tarih))
  let fieldWidth = 317 - 50;
  let lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  let leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 45,
    y: height - 91,
    size: prefSize,
    font: bold,
  })
  //Başlık
  prefSize = 15;
  infoToWrite = title?.toLocaleUpperCase() ?? ""
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite.toLocaleUpperCase(), {
    x: 248 - lastWidth,
    y: height - 52,
    size: prefSize,
    font: bold,
  })

  //Şehir 
  // 77/185
  prefSize = 9;
  infoToWrite = title?.toLocaleUpperCase() ?? ""
  fieldWidth = 187 - 17;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite.toLocaleUpperCase(), {
    x: 90 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })
  //Temsilci Uni Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.kunye_id?.temsilciUniSayisi) ?? stringCevir(rapor?.kunye?.temsilciUniSayisi) ?? "0"
  fieldWidth = 317 - 185;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);

  page.drawText(infoToWrite, {
    x: 185 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })


  //Fakülte Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.kunye_id?.fakulteSayisi) ?? stringCevir(rapor?.kunye?.fakulteSayisi) ?? "0"
  fieldWidth = 453 - 317;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);

  page.drawText(infoToWrite, {
    x: 280 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })


  //Yüksekokul Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.kunye_id?.yuksekokulSayisi) ?? stringCevir(rapor?.kunye?.yuksekokulSayisi) ?? "0"
  fieldWidth = 557 - 453;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);

  page.drawText(infoToWrite, {
    x: 365 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })


  //Meslekyüksekokul Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.kunye_id?.myoSayisi) ?? stringCevir(rapor?.kunye?.myoSayisi) ?? "0"
  fieldWidth = 724 - 557;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);

  page.drawText(infoToWrite, {
    x: 467 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })

  //Bölüm Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.kunye_id?.bolumSayisi) ?? stringCevir(rapor?.kunye?.bolumSayisi) ?? "0"
  fieldWidth = 862 - 724;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);

  page.drawText(infoToWrite, {
    x: 577 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })


  //Erkek Öğrenci Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.kunye_id?.erkekOgrSayisi) ?? stringCevir(rapor?.kunye?.erkekOgrSayisi) ?? "0"
  fieldWidth = 1011 - 862;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  page.drawText(infoToWrite, {
    x: 685 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })


  //Toplam Öğrenci Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.kunye_id?.toplamOgrSayisi) ?? stringCevir(rapor?.kunye?.toplamOgrSayisi) ?? "0"
  fieldWidth = 1100 - 1010;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);

  page.drawText(infoToWrite, {
    x: 770 - lastWidth,
    y: height - 140,
    size: prefSize,
    font: bold,
  })

  //2.KOLON Şube Üni Bşk.
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.subeUniBsk) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 45 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Şube Üni Kom.
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.subeUniKom) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 115 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Temsilci Üni BŞK.
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.temsilciUniBsk) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 178 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Temsilci Üni Kom.
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.temsilciUniKom) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 245 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })


  //  2.KOLON Fakülte Başkanı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.fakulteBsk) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 315 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Fakülte Kom
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.fakulteKom) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 375 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Yüksekokul Başkanı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.yuksekokulBsk) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 418 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Yüksekokul Kom
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.yuksekokulKom) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 455 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Meslek Yüksekokul Bşk
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.myoBsk) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 490 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Meslek Yüksekokul Kom
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.myoKom) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 522 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Bölüm Başkanı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.bolumBsk) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 555 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  2.KOLON Bölüm Kom.
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.bolumKom) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 585 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })


  //  2.KOLON Sınıf Temsilcisi
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.icmal?.sinifTemsilcisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 640 + leftMargin,
    y: height - 206.5,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Şube Uni Toplantısı Katılım
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.subeUniToplantiKatilim) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 35 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Şube Uni Toplantısı İzinli
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.subeUniToplantiIzinli) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 68 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Şube Uni Toplantısı İzinsiz
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.subeUniToplantiIzinsiz) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 100 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Temsilci Uni Toplantısını Yapan
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.temsilciToplantiYapan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 130 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Temsilci Uni Toplantısına Katılması Gereken
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.temsilciToplantiKatilmali) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 162 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Temsilci Uni Toplantısı Katılan
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.temsilciToplantisiKatilan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 192 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Fakulte Toplantısı Yapan sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.fakulteToplantisiYapan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 225 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Fakulte Toplantısına Katılması Gereken
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.fakulteToplantisiKatilmali) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 258 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Fakulte Toplantısına Katılan
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.fakulteToplantiKatilan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 290 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Birebir Genç Yapan
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.birebirGenclikYapan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 330 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Birebir Genç Muhatap
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.birebirGenclikMuhatap) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 370 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Haftalık Gündem Çalışması Yapan
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.gundemYapan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 415 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Haftalık Gündem Çalışması ulaşılan
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.gundemUlasilan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 475 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Kantin Bulunması Nokta
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.kantinNokta) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 525 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Kantin Buluşması Katılan Teşkilatçı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.kantinKatilan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 555 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON Kantin Buluşması Katılan Misafir
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.kantinMisafir) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 585 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  3.KOLON İlce Yerleşke Sayısı
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.ilceYerleske) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 625 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })
  //  3.KOLON İlçe Ziyaret
  prefSize = 9;
  infoToWrite = stringCevir(rapor?.teskilatRaporu?.ilceZiyaret) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 660 + leftMargin,
    y: height - 289,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Okuma Grubu
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.okumaGrubuSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 35 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Okuma Grubu Toplam Katılımcı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.okumaGrubuToplamKatilimci) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 85 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Okuma Grubu Tahlil Sayısı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.okumaGrubuTahlilSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 132 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Okuma Grubu Tahlil Katılım
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.okumaGrubuTahlilKatilim) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 177 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })


  //  4.KOLON Sabah Namazı Kaç Nokta
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.sabahNamaziNokta) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 245 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Sabah Namazı Teşkilatçı 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.sabahNamaziTeskilatci) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 315 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Sabah Namazı Misafir
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.sabahNamaziMisafir) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 375 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Sohbet Teşkilatçı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.sohbetTeskilatci) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 435 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Sohbet Misafir
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.sohbetMisafir) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 510 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  //  4.KOLON Neşriyat Milli Gazete
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.nesriyatMilliGazete) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 560 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  // 4.KOLON Neşriyat AGD
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.egitimRaporu?.nesriyatAGD) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 590 + leftMargin,
    y: height - 350,
    size: prefSize,
    font: bold,
  })

  // 5.KOLON Teşkilat Evi Sayısı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.teskilatEvSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 50 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 5.KOLON Teşkilat Evi Kalan 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.teskilatEvKalan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 115 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 5.KOLON Teşkilat Evi Ziyaret 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.teskilatEvZiyaret) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 178 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Kardeş Evi Sayısı 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.kardesEvSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 240 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Kardeş Evi Kalan Sayısı 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.kardesEvKalan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 315 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Kardeş Evi Ziyaret Sayısı 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.kardesEvZiyaret) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 370 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Teşkilat Yurdu Sayısı 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.teskilatYurtSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 420 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Teşkilat Kalan Sayısı 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.teskilatYurtKalan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 455 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Teşkilat Yurdu Teşkilatçı Sayısı 
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.teskilatYurtTeskilat) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 490 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Teşkilat Yurdu Misafir Sayısı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.teskilatYurtMisafir) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 525 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })
console.log(rapor)
  // 6.KOLON KYK Sayısı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.kykSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 555 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON KYK Başkanı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.kykBaskan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 588 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON KYK Ziyaret
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.kykZiyaret) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 620 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Özel Yurt Sayısı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.ozelYurtSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 655 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Özel Yurt Başkanı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.ozelYurtBaskan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 690 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 6.KOLON Özel Yurt Ziyaret
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.evlerYurtlar?.ozelYurtZiyaret) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 725 + leftMargin,
    y: height - 411,
    size: prefSize,
    font: bold,
  })

  // 7.KOLON Muhabbet Faslı Sayısı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.ortakRapor?.muhabbetFasliSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 50 + leftMargin,
    y: height - 479,
    size: prefSize,
    font: bold,
  })

  // 7.KOLON Muhabbet Faslı Katılım
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.ortakRapor?.muhabbetFasliKatilim) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 115 + leftMargin,
    y: height - 479,
    size: prefSize,
    font: bold,
  })

  // 7.KOLON Muhabbet Faslı Misafir
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.ortakRapor?.muhabbetFasliMisafir) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 180 + leftMargin,
    y: height - 479,
    size: prefSize,
    font: bold,
  })

  // 7.KOLON Aidatçı
  prefSize = 8;
  infoToWrite = stringCevir(rapor?.ortakRapor?.aidatciSayisi) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 245 + leftMargin,
    y: height - 479,
    size: prefSize,
    font: bold,
  })
let uretimTek=parseInt(toplamTeskilatciHesapla(rapor?.icmal,rapor?.evlerYurtlar?.kykBaskan,rapor?.evlerYurtlar?.ozelYurtBaskan))-parseInt(gecenHafta)
  // 8.KOLON Üretim
  prefSize = 9;
  infoToWrite = uretim ?? uretimTek?.toString() ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 375 + leftMargin,
    y: height - 471,
    size: prefSize,
    font: bold,
  })
  // 8.KOLON Toplam
  prefSize = 9;
  infoToWrite = toplamTeskilatciHesapla(rapor?.icmal,rapor?.evlerYurtlar?.kykBaskan,rapor?.evlerYurtlar?.ozelYurtBaskan) ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 550 + leftMargin,
    y: height - 471,
    size: prefSize,
    font: bold,
  })

  // 8.KOLON Geçen hafta
  prefSize = 9;
  infoToWrite =  gecenHafta?.toString() ?? rapor?.gecenHaftaTeskilatciSayisi?.toString() ?? "0"
  fieldWidth = 100;
  lastWidth = font.widthOfTextAtSize(infoToWrite, prefSize);
  leftMargin = (fieldWidth - lastWidth) / 2;
  page.drawText(infoToWrite, {
    x: 725 + leftMargin,
    y: height - 471,
    size: prefSize,
    font: bold,
  })

  pdfDoc.setTitle(title ? title : "");
  const pdfDataUri = await pdfDoc.saveAsBase64({ dateUri: true });

  return pdfDataUri;
}

export { createPdf };
