import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BirimBolgeService from "../services/BirimBolgeService";

export default function BirimBolgeTablo({ color }) {
  const [komisyon, setKomisyon] = useState([]);
  let birimBolgeService = new BirimBolgeService();
  const token = localStorage.getItem("token");
  function fetchBirimBolge() {
    return birimBolgeService.birimBolgeGetir(token)
      .then((birimBolge) => { setKomisyon(birimBolge) })
      .catch(err => console.error(err))

  }

  useEffect(() => { fetchBirimBolge() }, [])


  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Genel Merkez Üniversite Komisyonu İletişim Bilgileri
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>

              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-lg xs:text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  İsim
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-lg xs:text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Görev
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-lg xs:text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Telefon
                </th>



              </tr>
            </thead>
            <tbody>
              {komisyon.map((kom,index) => (
                <>
                  <tr key={index} className="hover:bg-gray-200">
                    <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">

                      <span
                        className={
                          "ml-3 font-bold " +
                          +(color === "light" ? "text-blueGray-600" : "text-white")
                        }
                      >
                        {kom.isim}
                      </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {kom.gorev}
                    </td>
                    {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <i className="fas fa-circle text-orange-500 mr-2"></i> pending
                </td> */}
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <a href={`tel:${kom.telefon.replace(/\s+/g,'')}`}>{kom.telefon}</a>
                    </td>
                  </tr>
                </>
              ))}



            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

BirimBolgeTablo.defaultProps = {
  color: "light",
};

BirimBolgeTablo.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
