import React,{useState} from 'react'
import {yaziliTariheCevir,toplamTeskilatciHesapla} from "../utils/functions"
import RaporGoruntule from './RaporGoruntule';
export default function RaporItem({rapor}) {
    const raporBasligi=(rapor?.kunye_id?.isim +" - "+yaziliTariheCevir(new Date(rapor.createdAt)) + " "+"Raporu")
    const toplamTeskilatci = toplamTeskilatciHesapla(rapor.icmal,rapor.evlerYurtlar.kykBaskan,rapor.evlerYurtlar.ozelYurtBaskan);

    const [modal,setModal]=useState(false);
    return (
        <> 
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {raporBasligi  }
                </th>
                <td class="py-4 px-6">
                    <button onClick={() => setModal(!modal)} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Görüntüle</button>
                </td>
                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {toplamTeskilatci}
                </th>
               
            </tr>
           
             {modal ?<RaporGoruntule rapor={rapor} modal={modal} setModal={setModal}/> :null} 

        </>
    )
}
