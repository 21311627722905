import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Rapor from "./pages/Rapor";
import RequireAuth from "./components/RequireAuth";
import Missing from "./components/Missing";
import RaporGoruntule from "./components/RaporGoruntule";
import Raporlar from "./components/Raporlar"
import Anasayfa from "./pages/Anasayfa";
import Footer from "./components/Footer"
import Bolge from "./components/Bolge"
import Turkiye from "./components/Turkiye";
import Header from "./components/Header";
import Unauthorized from "./components/Unauthorized";
import TurkiyeZRaporu from "./components/TurkiyeZRaporu";
import Bilgi from "./pages/Bilgi";
import IlRaporlari from "./pages/IlRaporlari";
import SifreDegistir from "./components/SifreDegistir";

function App() {

  return (
    <>
      <Routes>
        <Route index path="/giris" element={<Login />} />
        <Route path="/" element={<Anasayfa />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/sifre" element={<SifreDegistir/>}/>
        <Route element={<RequireAuth bolge={"bolge"} role={"turkiye"} />}>
          <Route index path="/il-raporlari/:page" element={<IlRaporlari />} />
        </Route>

        <Route element={<RequireAuth bolge={"bolge"} />}>
          <Route path="/bolge" element={<Bolge />} />
        </Route>

        <Route element={<RequireAuth role={"turkiye"} />}>
          <Route path="/turkiye" element={<Turkiye />} />
          <Route path="/turkiye-z-raporu" element={<TurkiyeZRaporu />} />
        </Route>

        <Route element={<RequireAuth role={"DE"} />}>
          <Route path="/rapor" element={<Rapor />} />
          <Route path="/raporlar/:page" element={<Raporlar />} />
          <Route path="/rapor/:id" element={<RaporGoruntule />} />
          <Route path="/bilgi" element={<Bilgi />} />
        </Route>

        <Route path="*" element={<Missing />} />
      </Routes>

    </>
  );
}

export default App;
